/* Dependencies ============================================================= */
import Vue from 'vue';

import { createLogger } from '@/uikit/util/logger';
const logger = createLogger('CategoriesModule', 'green');

/* State===================================================================== */
const state = () => ({
  initialized: false,
  categoriesMap: {},
  categoriesIds: [],
  level1Ids: [],
  level2Ids: [],
  level3Ids: [],
});

/* Getters ================================================================== */

const getters = {
  initialized: (state) => {
    return state.initialized;
  },

  find: (state) => (kind) => {
    return state.categoriesMap[kind];
  },

  list_all: (state, getters) => {
    return getters.list(state.categoriesIds);
  },

  list:
    (state, getters) =>
    (ids = []) => {
      const listIds = ids ? ids : state.leve1Ids;
      return listIds.map((kind) => getters.find(kind));
    },

  list_by_level:
    (state, getters) =>
    (level = 1) => {
      const listIds = state[`level${level}Ids`];
      return getters.list(listIds);
    },

  search_by_title: (state, getters) => (query) => {
    if (!query || !query.length) return [];
    return getters.list_all.filter((category) => {
      const match = category.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      return match;
    });
  },
};

/* Mutations ================================================================ */

const mutations = {
  ADD_CATEGORY: (state, { category, parent, level }) => {
    // logger.log('ADD_CATEGORY :>> ', level, category.class_name, parent);
    category.level = level;
    category.parent = parent ? parent : null;

    Vue.set(state.categoriesMap, category.class_name, category);
    state.categoriesIds.push(category.class_name);
    state[`level${level}Ids`].push(category.class_name);
  },

  SET_INITIALIZED: (state, flag) => {
    state.initialized = flag;
    if (flag) logger.log('Инициализирован. Добавлено категорий:', state.categoriesIds.length);
  },

  RESET: (state) => {
    state.categoriesMap = {};
    state.categoriesIds = [];
    state.level1Ids = [];
    state.level2Ids = [];
    state.level3Ids = [];
  },
};

/* Actions ================================================================== */
const actions = {
  initialize: async ({ commit, dispatch }, categories) => {
    commit('RESET');
    dispatch('addCategories', { categories });
    commit('SET_INITIALIZED', true);
    return true;
  },

  addCategories: ({ commit, dispatch }, { categories, parent = null, level = 1 }) => {
    // logger.log('add_categories :>> ', categories, level);
    categories.forEach((category) => {
      commit('ADD_CATEGORY', { category, parent, level });
      if (category.subkinds)
        dispatch('addCategories', {
          categories: category.subkinds,
          parent: category,
          level: level + 1,
        });
    });
  },
};

/* Plugins ================================================================== */

// export const plugin = (store) => {};

/* Exports ================================================================== */

export default {
  state,
  getters,
  actions,
  mutations,
};
