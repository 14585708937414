export const schema = {
  baseUrl: process.env.VUE_APP_API_HOST + '/api/v2',
  api: {
    login: {
      request: {
        method: 'post',
        url: '/auth/login/mobile.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    check_phone_or_email: {
      request: {
        method: 'post',
        url: '/auth/check.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    refresh_token: {
      request: {
        method: 'post',
        url: '/auth/jwt/refresh.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    magic_pass: {
      request: {
        method: 'get',
        url: '/auth/login/magicpass.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    sbol_authorize: {
      request: {
        method: 'get',
        url: '/oauth/sbol/authorize.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    sbbol_authorize: {
      request: {
        method: 'get',
        url: '/oauth/sbbol/authorize.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    esia_authorize: {
      request: {
        method: 'get',
        url: '/oauth/esia/authorize.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    registration: {
      request: {
        method: 'post',
        url: '/auth/registrations/mobile.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    send_code: {
      request: {
        method: 'post',
        url: '/auth/sms/send_code.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    verify_code: {
      request: {
        method: 'post',
        url: '/auth/sms/verify_code.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    request_password_reset: {
      request: {
        method: 'post',
        url: '/auth/passwords/reset.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    password_update: {
      request: {
        method: 'post',
        url: '/auth/passwords.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    create_status_pro: {
      request: {
        method: 'patch',
        url: '/profile/user/personal_data.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    find_company_by_name_inn: {
      request: {
        method: 'get',
        url: '/suggestions/companies.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    check_company: {
      request: {
        method: 'post',
        url: '/auth/registrations/company.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    get_user: {
      request: {
        method: 'get',
        url: '/profile/user.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },
    redirect_uri_to_data_room: {
      request: {
        method: 'get',
        url: '/auth/login/redirect_uri_to_data_room.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },
  },
};
