export const schema = {
  baseUrl: process.env.VUE_APP_API_HOST + '/api/v2',
  api: {
    // Получить список сервисов
    get_services: {
      request: {
        method: 'get',
        url: '/services.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    new_letter_of_credit: {
      request: {
        method: 'get',
        url: '/services/letter_of_credits/(:id)/new.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    get_letter_of_credit: {
      request: {
        method: 'get',
        url: '/services/letter_of_credits/(:id).json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    letter_of_credit_application_create: {
      request: {
        method: 'post',
        url: '/services/letter_of_credits.json',
        responseType: 'json',
      },
      response: {
        plain: true,
        fields: ['asset_id', 'kind', 'name', 'phone', 'inn', 'accept_personal_data', 'comment'],
      },
    },

    new_credit: {
      request: {
        method: 'get',
        url: '/service_applications/credit/(:asset_id).json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    credit_application_create: {
      request: {
        method: 'post',
        url: '/service_applications/credit.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    new_credit_drpa: {
      request: {
        method: 'get',
        url: '/service_applications/credit_drpa/(:asset_id).json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    credit_drpa_application_create: {
      request: {
        method: 'post',
        url: '/service_applications/credit_drpa.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    asset_efrsb_lots: {
      request: {
        method: 'get',
        url: '/assets/(:id)/efrsb_lots.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },
  },
};
