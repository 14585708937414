<template lang="pug">
.file-info-item(:class="classes" :style="styles")
  component.file-info-item__icon(v-bind="tag")
    ui-icon(size=32 :name="icon")
  component.file-info-item__content(v-bind="tag")
    .file-info-item__name.text-truncate(:title="name") {{ name }}
    .file-info-item__meta
      span.file-info-item__ext(v-if="ext") {{ ext }}&nbsp;/&nbsp;
      span.file-info-item__size {{ size | fileSize }}
  .file-info-item__actions(v-if="$slots.actions")
    slot(name="actions")
</template>

<script>
  import UiIcon from '../UiIcon/UiIcon.vue';
  import { measurable } from '@/uikit/mixins/measurable.js';

  export default {
    name: 'file-info-item',
    components: { UiIcon },
    mixins: [measurable],

    props: {
      href: { type: String, default: undefined },
      name: { type: String, default: '' },
      size: { type: Number, default: undefined },
      ext: { type: String, default: undefined },
      outlined: { type: Boolean, default: false },
      elevateOnHover: { type: Boolean, default: false },
    },

    computed: {
      classes() {
        return {
          'file-info-item--elevate-on-hover': this.elevateOnHover,
          'file-info-item--outlined': this.outlined,
        };
      },

      styles() {
        return {
          ...this.measurableStyles,
        };
      },

      icon() {
        let ext = 'default';
        if (['doc', 'pdf', 'jpeg', 'jpg', 'zip', 'rar'].includes(this.ext)) ext = this.ext;
        return `adm-file-${ext}-32`;
      },

      tag() {
        return this.href ? { is: 'a', href: this.href } : { is: 'div' };
      },
    },
  };
</script>

<style lang="scss">
  .file-info-item {
    display: flex;
    align-items: flex-start;
    width: 100%;
    text-decoration: none;

    &.file-info-item--outlined {
      padding: 8px;
      border-radius: 8px;

      .theme--dark & {
        border: 1px solid c('white8', 'dark');
      }

      .theme--light & {
        border: 1px solid c('black10', 'light');
      }
    }

    &.file-info-item--elevate-on-hover {
      .theme--dark &:hover {
        box-shadow: shadow('middle', 'dark');
      }

      .theme--light &:hover {
        box-shadow: shadow('1', 'light');
      }
    }

    .file-info-item__icon {
      width: 32px;
      height: 32px;
      margin-right: 4px;
      text-decoration: none;

      .theme--dark & {
        color: c('white48', 'dark');
      }

      .theme--light & {
        color: c('black80', 'light');
      }
    }

    .file-info-item__content {
      flex-grow: 1;
      min-width: 0;
      text-decoration: none;
    }

    .file-info-item__actions {
      flex-grow: 0;
      margin-left: 16px;
    }

    .file-info-item__name {
      @include font-size(14, 16);
      margin-bottom: 2px;

      .theme--dark & {
        color: c('text--bright', 'dark');
      }

      .theme--light & {
        color: c('text--dark', 'light');
      }
    }

    .file-info-item__meta {
      @include font-size(11, 14);

      .theme--dark & {
        color: c('text--dimmed', 'dark');
        font-size: 11px;
        line-height: 14px;
      }

      .theme--light & {
        color: c('text--dimmed', 'light');
        font-size: 11px;
        line-height: 14px;
      }
    }

    .file-info-item__ext {
      display: inline-block;
      margin-top: 2px;
      text-transform: uppercase;
    }
  }
</style>
