import { BaseApi, ApiSchema, AxiosProvider, nestedParamsSerializer } from '@/libs/api';
import { schema } from './schema';

const apiSchema = new ApiSchema(schema);
const axiosProvider = new AxiosProvider({
  baseUrl: apiSchema.getBaseUrl(),
  paramsSerializer: nestedParamsSerializer,
});

class WizardService extends BaseApi {
  async isDraftExist() {
    const data = await this.fetch('existed_draft');
    return data;
  }

  async resetStatus(reset) {
    const data = await this.fetch('draft', { draft: { reset: reset } });
    return data;
  }
}

const service = new WizardService(apiSchema, axiosProvider);

export { WizardService };
export { service };
export default service;
