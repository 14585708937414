import { BaseApi, ApiSchema, AxiosProvider, nestedParamsSerializer } from '@/libs/api';
import { schema } from './schema';

const apiSchema = new ApiSchema(schema);
const axiosProvider = new AxiosProvider({
  baseUrl: apiSchema.getBaseUrl(),
  paramsSerializer: nestedParamsSerializer,
});

class AuthService extends BaseApi {
  async checkPhoneOrEmail(phoneOrEmail) {
    const {
      data: { phone_or_email, method },
    } = await this.fetch('check_phone_or_email', { phone_or_email: phoneOrEmail });
    return { phoneOrEmail: phone_or_email.value, method };
  }

  async authenticateBySms(phone, code, redirectToDataRoom) {
    const {
      data: { token, refresh_token, user, redirect_uri },
    } = await this.fetch('login', {
      login: { verify_type: 'sms', phone: phone, code, redirect_to_data_room: redirectToDataRoom },
    });
    return { token, refreshToken: refresh_token, user, redirect_uri };
  }

  async authenticateByPassword(email, password, redirectToDataRoom) {
    const {
      data: { token, refresh_token, user, redirect_uri },
    } = await this.fetch('login', {
      login: { verify_type: 'password', email, password, redirect_to_data_room: redirectToDataRoom },
    });
    return { token, refreshToken: refresh_token, user, redirect_uri };
  }

  async refreshToken(refreshToken) {
    const {
      data: { token, refresh_token },
    } = await this.fetch('refresh_token', { refresh_token: refreshToken });
    return { token, refreshToken: refresh_token };
  }

  async magicPass() {
    const { data } = await this.fetch('magic_pass');
    return data?.magicpass;
  }

  async oauthAuthorize(method, state, code, callbackUrl, utms) {
    const apiMethods = {
      sbol: 'sbol_authorize',
      sbbol: 'sbbol_authorize',
      esia: 'esia_authorize',
    };
    const {
      data: { token, refresh_token, user },
    } = await this.fetch(apiMethods[method], {
      state: state,
      code: code,
      callback_url: callbackUrl,
      utms: utms,
    });
    return { token, refreshToken: refresh_token, user };
  }

  async registration(registration) {
    const {
      data: { token, refresh_token, user, redirect_uri },
    } = await this.fetch('registration', { registration });
    return { token, refreshToken: refresh_token, user, redirect_uri };
  }

  async requestCode(phone, existence) {
    const { ok } = await this.fetch('send_code', { phone, check_existence: existence });
    return ok;
  }

  async verifyCode(phone, code) {
    const data = await this.fetch('verify_code', { phone, code });
    return data;
  }

  async requestPasswordReset(email) {
    const { ok } = await this.fetch('request_password_reset', { email });
    return ok;
  }

  async updatePassword(password, password_confirmation, reset_token) {
    const { data } = await this.fetch('request_password_reset', {
      password,
      password_confirmation,
      reset_token,
    });
    return { token: data.token, refreshToken: data.refresh_token };
  }

  async createStatusPro(user_info) {
    const { ok } = await this.fetch('create_status_pro', { user: user_info });
    return ok;
  }

  async findCompanyByNameInn(query) {
    const data = await this.fetch('find_company_by_name_inn', { query: query });
    return data;
  }

  async checkCompany(company) {
    const { ok } = await this.fetch('check_company', { company: company });
    return ok;
  }

  async getUserInfo() {
    const { data } = await this.fetch('get_user');
    return data.user;
  }

  async getUriToDataRoom() {
    const { data } = await this.fetch('redirect_uri_to_data_room');
    return data;
  }
}

const service = new AuthService(apiSchema, axiosProvider);

export { AuthService };
export { service };
export default service;
