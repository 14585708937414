<template lang="pug">
v-chip.ui-chip(
  :class="classes"
  v-on="$listeners"
  v-bind="$attrs"
  close-icon="$close"
)
  slot
</template>

<script>
  import './UiChip.scss';

  import { sizeable } from '../../mixins/sizeable';

  export default {
    name: 'ui-chip',
    mixins: [sizeable],
    inheritAttrs: false,

    computed: {
      classes() {
        return {
          ...this.sizeableClasses,
        };
      },
    },
  };
</script>
