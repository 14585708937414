/* Dependencies ============================================================= */
import Vue from 'vue';

import { SearchFilter } from '@/services/assets/search-filter.js';

import { createLogger } from '@/uikit/util/logger';
const logger = createLogger('Catalog', 'green');

export const MAIN_FILTERS = [
  'sort',
  'price',
  'rent_price',
  'sale_type',
  'seller_type',
  'sellers',
  'bankruptcy',
  'execution_procedure',
  'pledge_status',
];
export const HIDDEN_FILTERS = ['borrowers', 'available_deals', 'new_records'];

/* State ==================================================================== */
const state = () => ({
  initialized: false, // индикатор первичной готовности
  preInitialized: false, // преинициализации (для передачи готовых данных с главной)
  placementPurpose: null,
  addresses: [],
  sort: null,
  query: null,
  bbox: null,
  kind: null,
  per: 9,
  page: 1,
  total: null,
  filtersMap: {}, // хранилище фильтров `{'filter_name1': {...}, 'filter_name2': {...}, ...}`
  filtersIds: [], // последовательный список фильтров [ filter_name1, filter_name2, ...]
  valuesMap: {}, // хранилище значений фильтров
  assetsIds: [],
  assetsMap: [],
});

/* Getters ================================================================== */

const getters = {
  assets: (state, getters) => {
    return state.assetsIds.map((id) => getters.find(id));
  },

  addresses: (state) => {
    return state.addresses;
  },

  count: (state) => {
    return state.assetsIds.length;
  },

  total: (state) => {
    return state.total;
  },

  find: (state) => (id) => {
    return state.assetsMap[id];
  },

  category_filters_list: (state, getters) => {
    const filterIds = state.filtersIds.filter(
      (filter_name) => ![...MAIN_FILTERS, ...HIDDEN_FILTERS].includes(filter_name)
    );
    return filterIds.map((filter_name) => getters.find_filter(filter_name));
  },

  filters_list: (state, getters) => (filterIds) => {
    var filters = filterIds.map((filter_name) => getters.find_filter(filter_name));
    return filters.filter((f) => f !== undefined);
  },

  all_filters_list: (state, getters) => {
    return state.filtersIds.map((filter_name) => getters.find_filter(filter_name));
  },

  find_filter: (state) => (filter_name) => {
    return state.filtersMap[filter_name];
  },

  filter_value: (state) => (filter_name) => {
    return state.valuesMap[filter_name];
  },

  has_filters_values: (state, getters) => {
    return state.addresses.length > 0 || getters['filters_params'].length > 0;
  },

  human_values: (state, getters) => {
    let humanValues = {};
    if (state.addresses && state.addresses.length) {
      humanValues['addresses'] = state.addresses.join(', ');
    }

    Object.keys(state.valuesMap).forEach((filterName) => {
      humanValues[filterName] = getters.filter_human_value(filterName);
    });

    return humanValues;
  },

  filter_human_value: (state, getters) => (filterName) => {
    const filter = getters.find_filter(filterName);
    const value = state.valuesMap[filterName];
    return SearchFilter.getHumanValue(filter, value);
  },

  filters_params: (state, getters) => {
    let filters = [];

    state.filtersIds.forEach((filterName) => {
      const filterData = getters.find_filter(filterName);
      const value = getters.filter_value(filterName);

      const sf = SearchFilter.build(filterData);
      sf.setValue(value);
      if (!sf.isEmpty()) filters.push(sf.toCriteria());
    });
    return filters;
  },

  search_params: (state, getters) => {
    const params = {};

    if (getters.filters_params.length > 0) params.filters = getters.filters_params;
    if (state.kind) params.kind = state.kind;
    if (state.addresses && state.addresses.length) params.addresses = state.addresses;
    if (state.query) params.query = state.query;
    if (state.page) params.page = state.page;
    if (state.per) params.per = state.per;
    if (state.sort) params.sort = state.sort;

    // HACK: искуственно добавленный search_params.purpose
    if (state.placementPurpose) params.purpose = state.placementPurpose;

    return params;
  },

  map_search_params: (state, getters) => {
    const params = { ...getters.search_params };
    if (state.bbox) params.bbox = state.mapBbox;
    return params;
  },
};

/* Mutations ================================================================ */

const mutations = {
  INIT_FILTERS: (state) => {
    state.filtersMap = {};
    state.filtersIds = [];
  },

  ADD_FILTER: (state, filter) => {
    Vue.set(state.filtersMap, filter.name, filter);
    state.filtersIds.push(filter.name);
  },

  SET_FILTER_VALUE: (state, { filter_name, value }) => {
    Vue.set(state.valuesMap, filter_name, value);
  },

  RESET_FILTER_VALUE: (state, filter_name) => {
    Vue.delete(state.valuesMap, filter_name);
  },

  RESET_FILTERS_VALUES: (state) => {
    state.valuesMap = {};
    state.addresses = [];
    state.query = '';
  },

  SET_PARAM: (state, { param_name, value }) => {
    state[param_name] = value;
  },

  SET_QUERY: (state, query) => {
    state.query = query;
  },

  SET_BBOX: (state, bbox) => {
    state.bbox = bbox;
  },

  SET_ADDRESSES: (state, addresses) => {
    state.addresses = addresses;
  },

  ADD_ADDRESS: (state, address) => {
    if (state.addresses.includes(address)) return;
    state.addresses.push(address);
  },

  REMOVE_ADDRESS: (state, address) => {
    let addresses = [...state.addresses];
    const index = addresses.indexOf(address);
    if (index > -1) {
      addresses.splice(index, 1);
    }
    state.addresses = addresses;
  },

  SET_KIND: (state, kind) => {
    state.kind = kind;
  },

  SET_PAGE: (state, page) => {
    state.page = page;
  },

  SET_PER_PAGE: (state, per) => {
    state.per = per;
  },

  SET_SORT: (state, sort) => {
    state.sort = sort;
  },

  SET_TOTAL: (state, total) => {
    state.total = total;
  },

  SET_PURPOSE: (state, purpose) => {
    state.placementPurpose = purpose;
  },

  SET_MAP_PRECISION: (state, precision) => {
    state.mapPrecision = precision;
  },

  SET_MAP_ZOOM: (state, zoom) => {
    state.mapZoom = zoom;
  },

  SET_MAP_BBOX: (state, bbox) => {
    state.mapBbox = bbox;
  },

  SET_INITIALIZED: (state, flag) => {
    state.initialized = flag;
    logger.log('Инициализирован', flag);
  },

  SET_PREINITIALIZED: (state, flag) => {
    state.preInitialized = flag;
    logger.log('ПРЕ-Инициализирован', flag);
  },

  CLEAR_ASSETS: (state) => {
    state.assetsMap = {};
    state.assetsIds = [];
  },

  ADD_ASSETS: (state, assets) => {
    assets.forEach((asset) => {
      Vue.set(state.assetsMap, asset.short_id, asset);
      state.assetsIds.push(asset.short_id);
    });
  },

  ADD_ASSET: (state, asset) => {
    Vue.set(state.assetsMap, asset.short_id, asset);
    state.assetsIds.push(asset.short_id);
  },

  REMOVE_ASSET: (state, asset) => {
    Vue.delete(state.assetsMap, asset.short_id);
    state.assetsIds = state.assetsIds.filter((id) => id !== asset.short_id);
  },

  UPDATE_ASSET: (state, asset) => {
    Vue.set(state.assetsMap, asset.short_id, asset);
  },
};

/* Actions ================================================================== */

const actions = {
  initialize: async (
    { commit, dispatch },
    { placementPurpose, addresses, kind, page, per, query, filters, filtersValues }
  ) => {
    return new Promise((resolve) => {
      commit('SET_INITIALIZED', false);

      commit('SET_PURPOSE', placementPurpose);
      commit('SET_KIND', kind);
      if (page) commit('SET_PAGE', page);
      if (per) commit('SET_PER_PAGE', per);
      if (query) commit('SET_QUERY', query);
      if (addresses) commit('SET_ADDRESSES', addresses);

      if (filters) dispatch('set_filters', filters);
      if (filtersValues) dispatch('set_filters_values', filtersValues);

      commit('SET_INITIALIZED', true);
      resolve(true);
    });
  },

  setPreinitialized: ({ commit }, flag) => {
    commit('SET_PREINITIALIZED', flag);
  },

  set_filters: ({ commit }, filters) => {
    if (!filters) return;
    commit('INIT_FILTERS');

    // вытащим sort отдельно и инициализируем его начальным значением
    const sortFilter = filters.find((f) => f.name == 'sort');
    commit('ADD_FILTER', sortFilter);
    commit('SET_SORT', sortFilter.options[0].key);

    filters = filters.filter((f) => f.name != 'sort');
    filters.forEach((filter) => {
      commit('ADD_FILTER', filter);
    });
  },

  set_filter_value: ({ commit, getters }, { filter_name, value }) => {
    const filter = getters.find_filter(filter_name);
    let filterValue = value;

    if (filter.type === 'range') {
      filterValue = { min: value[0], max: value[1] };
    }

    commit('SET_FILTER_VALUE', { filter_name, value: filterValue });
  },

  reset_filter_value: ({ commit }, filter_name) => {
    commit('RESET_FILTER_VALUE', filter_name);
  },

  reset_filters_values: ({ commit }) => {
    commit('RESET_FILTERS_VALUES');
  },

  set_filters_values: ({ commit }, filters) => {
    filters.forEach((filter) => {
      let value = null;

      // checkbox или inline-checkbox
      if (Object.prototype.hasOwnProperty.call(filter, 'values')) {
        value = filter.values;
      }

      // radio или inline-radio
      if (Object.prototype.hasOwnProperty.call(filter, 'value')) {
        value = filter.value;
      }

      // остается range, должны быть min/max
      if (Object.prototype.hasOwnProperty.call(filter, 'min')) {
        value = { min: filter.min, max: filter.max };
      }

      commit('SET_FILTER_VALUE', { filter_name: filter.field, value: value });
    });
  },

  set_purpose: ({ commit }, purpose) => {
    commit('SET_PURPOSE', purpose);
  },

  set_query: ({ commit }, query) => {
    commit('SET_QUERY', query);
  },

  set_addresses: ({ commit }, addresses) => {
    commit('SET_ADDRESSES', addresses);
  },

  add_address: ({ commit }, address) => {
    commit('ADD_ADDRESS', address);
  },

  remove_address: ({ commit }, address) => {
    commit('REMOVE_ADDRESS', address);
  },

  set_kind: ({ commit }, kind) => {
    commit('SET_KIND', kind);
  },

  set_sort: ({ commit }, sort) => {
    commit('SET_SORT', sort);
  },

  set_page: ({ commit }, page) => {
    commit('SET_PAGE', page);
  },

  set_per_page: ({ commit }, per) => {
    commit('SET_PER_PAGE', per);
  },

  set_total: ({ commit }, total) => {
    commit('SET_TOTAL', total);
  },

  set_bbox: ({ commit }, bbox) => {
    commit('SET_BBOX', bbox);
  },

  next_page: ({ state, commit }) => {
    let page = state.page + 1;
    commit('SET_PAGE', page);
  },

  reset: ({ commit }, mapReset = false) => {
    commit('SET_PAGE', 1);
    // commit('SET_BBOX', null);

    // if (mapReset) {
    //   commit('SET_MAP_PRECISION', 3);
    //   commit('SET_MAP_ZOOM', null);
    //   commit('SET_MAP_BBOX', null);
    // }
  },

  restore_search_params: ({ commit, dispatch }, searchParams) => {
    logger.log('Восстанавливаем параметры поиска', searchParams);

    const purpose = searchParams.placementPurpose || searchParams.purpose;
    commit('SET_PURPOSE', purpose);
    commit('SET_KIND', searchParams.kind);

    if (searchParams.page) commit('SET_PAGE', searchParams.page);
    if (searchParams.per) commit('SET_PER_PAGE', searchParams.per);
    if (searchParams.query) commit('SET_QUERY', searchParams.query);
    if (searchParams.addresses) commit('SET_ADDRESSES', searchParams.addresses);

    if (searchParams.filters) dispatch('set_filters_values', searchParams.filters);
  },

  addAssets: ({ commit }, { assets, total }) => {
    commit('ADD_ASSETS', assets);
    commit('SET_TOTAL', total);
    logger.log('Добавлено (add)', assets.length);
  },

  setAssets: ({ commit }, { assets, total }) => {
    commit('CLEAR_ASSETS');
    commit('ADD_ASSETS', assets);
    commit('SET_TOTAL', total);
    logger.log('Добавлено (set)', assets.length);
  },

  removeAsset: ({ commit }, assetId) => {
    commit('REMOVE_ASSET', assetId);
  },
};

/* Exports ================================================================== */

export default {
  state,
  getters,
  actions,
  mutations,
};
