<script>
import { convertToUnit } from '@/uikit/util';
  export default {
    name: 'search-bar-item',
    functional: true,

    props: {
      disabled: { type: Boolean, default: false },
      grow: { type: Boolean, default: false },
      shrink: { type: Boolean, default: false },
      width: { type: [String, Number], default: '' },
    },

    render(h, ctx) {
      const classes = ['search-bar-item', ctx.data.staticClass, ctx.data.class];
      if (ctx.props.grow) classes.push('search-bar-item--grow');
      if (ctx.props.shrink) classes.push('search-bar-item--shrink');

      const styles = {};
      if (ctx.props.width) styles.width = convertToUnit(ctx.props.width);

      return h(
        'div',
        {
          ...ctx.data,
          class: classes,
          style: styles,
          on: {
            click: (event) => {
              const emitClick = ctx.listeners.click;
              if (ctx.props.disabled === false && emitClick) {
                emitClick(event);
              }
            },
          },
        },
        ctx.children
      );
    },
  };
</script>
