import { isObject } from 'lodash.isobject';
import { isFunction } from 'lodash.isfunction';

/**
 * Определяет если данный `promise` является Promise.
 *
 * @param {*} promise
 *
 * @returns {Boolean}
 */
const isPromise = function (promise) {
  return !!promise && isFunction(promise.then);
};

/**
 * Определяет если данный `value` является строкой в ISO8601 формате.
 *
 * @param {*} value
 *
 * @returns {Boolean}
 */
const isDateISOString = function (value) {
  const iso8601 = new RegExp(/^\d{4}-\d{2}-\d{2}/);
  return value && iso8601.test(value);
};

/**
 * Определяет если данный `value` строкой в ISO8601 формате.
 *
 * @param {*} value
 *
 * @returns {Boolean}
 */
const isDate = function (value) {
  return value && Object.prototype.toString.call(value) === '[object Date]' && !isNaN(value);
};

/**
 * Определяет если данный `value` является валидным таймстемпом
 *
 * @param {*} value
 *
 * @returns {Boolean}
 */
const isTimestamp = function (value) {
  return new Date(value).getTime() > 0;
};

export { isTimestamp, isDate, isDateISOString, isObject, isFunction, isPromise };
