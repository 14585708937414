// Микшин для поддержки detachable комонентов `v-select`, `v-menu` и пр.
// чинит отображение внутри компонентов, которые не являются прямыми детьми v-app
// Например, внутри UiSidePanel с портальным рендерингом в #app-modal-container
// attachableId навешивается на элемент-контейнер `:id="attachableId"`
// В detachable компоненте указываем `:attach="attachableIdSelector"`
// Элемент-контейнер должен иметь `position: relative`
// Возможно, также придется внести правки в max-width `.v-menu__content`

export const attachableSelf = function (prefix = '') {
  return {
    props: {
      attachSelf: { type: Boolean, default: false },
    },

    data() {
      return {
        attachableId: null,
      };
    },

    created() {
      this.attachableId = prefix + '-' + Math.random().toString(36).substr(2, 9);
    },

    computed: {
      attachableIdSelector() {
        return `#${this.attachableId}`;
      },
    },
  };
};
