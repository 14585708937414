<template lang="pug">
v-btn-toggle.ui-btn-toggle(
  v-on="$listeners"
  v-bind="$attrs"
  :style="styles"
  @change="emitValue"
)
  slot
</template>

<script>
  import { measurable } from '@/uikit/mixins/measurable';
  import './UiBtnToggle.scss';

  export default {
    name: 'ui-btn-toggle',
    mixins: [measurable],
    inheritAttrs: false,

    computed: {
      styles() {
        return this.measurableStyles;
      },
    },

    methods: {
      emitValue(value) {
        this.$emit('input', value);
      },
    },
  };
</script>
