<template lang="pug">
.ui-date-picker(:id="attachableId" :style="styles")
  v-text-field.pt-0.mt-0(
    ref="dateField"
    :value="dateFormatted"
    :label="label"
    :placeholder="placeholder"
    autocomplete="off"
    v-maska="constants.fields.date.maska"
    :persistent-hint="persistentHint"
    :error-count="1"
    :hide-details="hideDetails"
    :persistent-placeholder="persistentPlaceholder"
    :rules="validationRules"
    :validate-on-blur="validateOnBlur"
    @click.stop="edit"
    @blur="editing = false"
    @change="parseDate"
  )
    template(#append)
      v-menu(
        v-model="showCalendar"
        left
        :attach="attachSelf ? attachableIdSelector : false"
        :close-on-content-click="false"
      )
        template(v-slot:activator="{ on, attrs }")
          v-btn(
            v-on="on"
            v-bind="attrs"
            icon
            plain
            width=24
            height=24
          )
            ui-icon(name="calendar")
        v-date-picker(
          v-model="pickerValue"
          no-title
          :first-day-of-week="1"
          @click:date="onDateSelect"
        )
</template>

<script>
  import './UiDatePicker.scss';

  import { attachableSelf } from '../../mixins/attachable-self';
  import { measurable } from '../../mixins/measurable';

  import { isDate, isDateISOString, isTimestamp } from '../../util/type-check';
  import { createLogger } from '../../util/logger';
  import { isValidDate } from '../../util/valid-date';
  import Constants from '../../util/constants';
  import { maska } from 'maska';
  import { UiIcon } from '../UiIcon';

  const logger = createLogger('UiDatePicker', 'orange');

  export default {
    name: 'ui-date-picker',
    components: { UiIcon },
    directives: {
      maska,
    },
    mixins: [attachableSelf('ui-date-picker'), measurable],

    props: {
      value: { type: [Date, String, Number], default: '' },
      label: { type: String, default: undefined },
      placeholder: { type: String, default: 'дд.мм.гггг' },
      hideDetails: { type: [String, Boolean], default: 'auto' },
      persistentHint: { type: Boolean, default: false },
      persistentPlaceholder: { type: Boolean, default: false },
      closeOnDateSelect: { type: Boolean, default: false },
      pickerOnClick: { type: Boolean, default: false },
      allowEmpty: { type: Boolean, default: false },
      rules: { type: Array, default: () => [] },
      validateOnBlur: { type: Boolean, default: false },
    },

    data() {
      return {
        rootId: '',
        editing: false,
        showCalendar: false,
        pickerValue: null,
        constants: Constants,
      };
    },

    computed: {
      classes() {
        return {
          'ui-date-picker--attach-self': this.attachSelf,
        };
      },

      styles() {
        return {
          ...this.measurableStyles,
        };
      },

      dateModel: {
        set(value) {
          this.$emit('input', value);
        },

        get() {
          return this.value;
        },
      },

      validationRules() {
        let rules = this.rules;
        if (!this.allowEmpty) {
          rules.push(this.validateNonEmpty);
          rules.push(this.validateDateInput);
        }
        return rules;
      },

      localISODate() {
        return this.convertToISO(this.dateModel);
      },

      dateFormatted() {
        if (this.editing || !this.localISODate) return '';

        const dateObj = this.getMskDate(this.localISODate);

        const date = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${date}.${month}.${year}`;
      },
    },

    watch: {
      pickerValue(newValue) {
        this.dateModel = newValue;
      },
    },

    created() {
      this.rootId = `datepicker-${Math.floor(Math.random() * 1000).toString()}`;
      this.$emit('input', this.localISODate);
      if (this.localISODate) this.pickerValue = this.localISODate;
    },

    methods: {
      validateNonEmpty(value) {
        return !!value || 'Должно быть заполнено';
      },

      validateDateInput(value) {
        let valid = false;
        const dateRegex = /^(\d{2})\.(\d{2})\.(\d{4})$/;
        const match = dateRegex.exec(value);
        if (match !== null) {
          valid = isValidDate(match[1], match[2], match[3]);
        }
        return valid || `Неверный формат даты ${this.constants.fields.date.placeholder}`;
      },

      edit() {
        if (this.pickerOnClick) this.showCalendar = true;
        // this.editing = true;
      },

      convertToISO(value) {
        if (!value) return '';

        if (isDate(value)) {
          logger.log('Конвертация пропс из Date', value);
          return value.toISOString();
        }

        if (isDateISOString(value)) {
          logger.log('Конвертация пропс из DateISOString', value);
          return value;
        }

        if (isTimestamp(value)) {
          logger.log('Конвертация пропс из Timestamp', value);
          return new Date(value).toISOString();
        }
      },

      parseDate(value) {
        if (!value) return;
        if (!this.$refs.dateField.validate() || this.$refs.dateField.hasError) return;

        let [date, month, year] = value.split('.');
        logger.log('Парсим дату:', year, month, date);
        date = date.padStart(2, '0');
        month = month.padStart(2, '0');
        const parsedDate = `${year}-${month}-${date}`;
        this.pickerValue = parsedDate;
      },

      getMskDate(value) {
        const date = new Date(value);
        return new Date(date - new Date().getTimezoneOffset() * 60000);
      },

      onDateSelect() {
        if (this.closeOnDateSelect) this.showCalendar = false;
      },
    },
  };
</script>
