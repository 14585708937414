<template lang="pug">
v-bottom-sheet(v-model="isActive" content-class="open-desktop-confirmation-dialog")
  .open-desktop-confirmation-dialog__content
    .t-h2-mobile.mb-2 {{ title }}
    .t-middle.c-text
      slot
        | {{ text }}
  .open-desktop-confirmation-dialog__footer-actions
    .open-desktop-confirmation-dialog__btn-cancel
      ui-btn.px-4(
        large
        secondary
        width="100%"
        @click="cancel"
      ) Отмена
    .open-desktop-confirmation-dialog__btn-confirm
      slot(name="confirm")
        ui-btn(
          large
          primary
          width="100%"
          @click="confirm"
        ) Перейти
</template>

<script>
  import { UiBtn } from '@/uikit/';
  import { service as authAPI } from '@/services/auth';
  import { getSessionAuth } from '@/libs/user-token';
  import queryString from 'query-string';

  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('OpenDesktopDialog', 'blue');

  const DESKTOP_URL = new URL(process.env.VUE_APP_API_HOST.replace('api.', ''));

  const optionsDefault = {
    title: 'Переход на версию для ПК',
    text: undefined,
    path: '/',
    query: {},
  };

  export default {
    components: { UiBtn },

    data() {
      return {
        title: undefined,
        text: undefined,
        path: undefined,
        query: undefined,
        isActive: false,
        resolve: null,
        reject: null,
      };
    },

    methods: {
      open(options) {
        this.initialize(options);
        this.isActive = true;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      initialize(options) {
        options = Object.assign({}, optionsDefault, options);
        this.title = options.title;
        this.text = options.text;
        this.query = options.query;

        let path = options.path;
        if (path.slice(-1) === '/') path = path.replace(/.$/, '');
        // TODO: ИСПРАВИТЬ вызов при создании нового актива
        if (path === '/new-asset') path = '';
        this.path = path;
      },

      confirm() {
        this.redirectToDesktop();
        this.resolve(true);
        this.isActive = false;
      },

      cancel() {
        this.resolve(false);
        this.isActive = false;
      },

      async redirectToDesktop() {
        // получаем пропуск
        try {
          const { token } = getSessionAuth();
          if (token) {
            const magicHash = await authAPI.magicPass();
            if (magicHash) this.query.magicpass = magicHash;
          }
        } catch (error) {
          logger.error(error);
        }

        // форсим десктоп
        document.cookie = `mobile=0;domain=${DESKTOP_URL.hostname};path=/`;
        document.cookie = `mobile=0;domain=${DESKTOP_URL.hostname};path=${this.path}`;

        const url = this.getUrl(this.path, this.query);
        logger.log('Редиректим на десктоп', url);
        window.location = url;
      },

      getUrl(path, query) {
        const url = new URL(path, DESKTOP_URL);
        const q = queryString.stringify(query);
        return url + (q ? `?${q}` : '');
      },
    },
  };
</script>

<style lang="scss">
  .open-desktop-confirmation-dialog {
    background-color: c('white', 'light');

    &__content {
      text-align: center;
      padding: 32px 24px;
    }

    &__footer {
    }

    &__footer-actions {
      display: flex;
      align-items: center;
      padding: 0 24px 32px 24px;
    }

    &__btn-cancel {
      flex-basis: 50%;
      margin-right: 16px;
    }

    &__btn-confirm {
      flex-basis: 50%;
    }
  }
</style>
