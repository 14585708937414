<template lang="pug">
v-dialog(
  :class="classes"
  v-on="$listeners"
  v-bind="$attrs"
  fullscreen
  hide-overlay
  persistent
  transition="dialog-bottom-transition"
  :content-class="`ui-dialog ${contentClass}`"
)
  .ui-dialog__header(v-if="$slots.header")
    slot(name="header")

  .ui-dialog__content
    slot

  .ui-dialog__footer(v-if="$slots.footer")
    slot(name="footer")
</template>

<script>
  import UiBtn from './UiBtn';
  import UiIcon from './UiIcon';

  export default {
    components: { UiBtn, UiIcon },
    inheritAttrs: false,

    props: {
      contentClass: { type: String, default: '' },
    },

    computed: {
      classes() {
        return {};
      },
    },
  };
</script>

<style lang="scss">
  .ui-dialog {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    border-radius: 0;
    background-color: c('white');
  }

  .ui-dialog__header {
    height: 48px;
    flex: 0 1 48px;
  }

  .ui-dialog__content {
    overflow-y: auto;
    min-height: 0;
    flex-grow: 1;
  }

  .ui-dialog__footer {
    flex-shrink: 1;
    padding: 8px 16px 20px 16px;
    background-color: c('white');
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.06);
  }
</style>
