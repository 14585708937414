<template lang="pug">
.asset-id.ui-clickable(:class="classes" @click.stop="!noCopy ? copy() : false")
  span.asset-id__id(ref="assetId")
    slot
</template>

<script>
  import { themeable } from '@/uikit/mixins/themeable';

  import { createLogger } from '@/uikit/util/logger';
  import { copyTextToClipboard } from '@/uikit/util/copy-clipboard';

  const logger = createLogger('AssetId', 'orange');

  export default {
    name: 'asset-id',
    mixins: [themeable],

    props: {
      big: { type: Boolean, default: false },
      outlined: { type: Boolean, default: false },
      noCopy: { type: Boolean, default: false },
    },

    data() {
      return {
        snackbarShowing: false,
      };
    },

    computed: {
      classes() {
        return {
          'asset-id--big': this.big,
          'asset-id--outlined': this.outlined,
          ...this.themeClasses,
        };
      },
    },

    methods: {
      async copy() {
        try {
          const text = this.$refs.assetId.innerHTML;
          await copyTextToClipboard(text);
          this.$emit('notify:success', 'ID актива скопирован в буфер обмена', 2000);
          logger.log('ID актива скопирован в буфер обмена');
        } catch (error) {
          this.$emit('notify:error', 'Ошибка копирования в буфер обмена', 2000);
          logger.error('Ошибка копирования в буфер обмена');
        }
      },
    },
  };
</script>

<style lang="scss">
  .asset-id {
    // @include text-style('button-small', 'light');
    @include font-size(13, 16);
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    height: 20px;
    padding: 0 6px;
    border: 1px solid #e8e8e8;
  }

  .theme--light {
    &.asset-id,
    & .asset-id {
      border-radius: 4px;
      background: white;
      color: c('gray80', 'light');
    }

    &.asset-id--big,
    & .asset-id--big {
      @include font-size(14, 14);
      height: 24px;
      border-color: #cacccf;
      background-color: #fff;
    }
  }

  .theme--dark {
    &.asset-id,
    & .asset-id {
      border-radius: 4px;
      border-color: rgba(255, 255, 255, 0.32);
      background: none;
      color: rgba(255, 255, 255, 0.6);
    }

    &.asset-id--big,
    & .asset-id--big {
      @include font-size(14, 14);
      height: 24px;
    }
  }
</style>
