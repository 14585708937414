<template lang="pug">
.asset-address
  slot
</template>

<script>
  export default {
    name: 'asset-address',
  };
</script>

<style lang="scss">
  .asset-address {
    @include text-style('caption-small', 'light');
    position: relative;
    padding-left: 20px;
    color: c('gray80', 'light');

    &::before {
      @include svg-bg-mask-recolored('map-pointer', c('gray60', 'light'), light);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
    }
  }
</style>
