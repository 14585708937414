import Vue from 'vue';
import VueRouter from 'vue-router';
import PageHome from '@/views/PageHome.vue';
import { SimpleApi as API } from '@/libs/api/lib/simpleApi';
const PageCatalog = () => import('@/views/PageCatalog.vue');
const PageAsset = () => import('@/views/PageAsset.vue');
const PageSignIn = () => import('@/views/PageSignIn.vue');
const PageSignUp = () => import('@/views/PageSignUp.vue');
const PageSignUpIndividual = () => import('@/views/PageSignUpIndividual.vue');
const PageSignUpLegal = () => import('@/views/PageSignUpLegal.vue');
const PageAuthPasswordReset = () => import('@/views/PageAuthPasswordReset.vue');
const PageCreatePro = () => import('@/views/PageCreatePro');
const PageProfile = () => import('@/views/PageProfile.vue');
const PageFaq = () => import('@/views/PageFaq.vue');
const PageFaqSection = () => import('@/views/PageFaqSection.vue');
const PageAbout = () => import('@/views/PageAbout.vue');
const PageBlog = () => import('@/views/PageBlog.vue');
const PageArticle = () => import('@/views/PageArticle.vue');
const PageSplash = () => import('@/views/PageSplash.vue');
const PageOauthAuthorize = () => import('@/views/PageOauthAuthorize.vue');
const PageInteractions = () => import('@/views/PageInteractions.vue');
const PageInteractionDialog = () => import('@/views/PageInteractionDialog.vue');
const PageNotifications = () => import('@/views/PageNotifications.vue');
const PageFavorites = () => import('@/views/PageFavorites.vue');
const PageMyTrades = () => import('@/views/PageMyTrades.vue');
const PageServices = () => import('@/views/PageServices.vue');
const PageService = () => import('@/views/PageService.vue');
const PageNotFound = () => import('@/views/PageNotFound.vue');
const PageError = () => import('@/views/PageError.vue');
const PageDataroom = () => import('@/views/PageDataroom.vue');
const PageNoMobile = () => import('@/views/PageNoMobile.vue');
const PageSbbolAuth = () => import('@/views/PageSbbolAuth.vue');
// import TestIconsPage from '@/views/TestIconsPage.vue';
const PageServicesApplicationCredit = () => import('@/views/PageServicesApplicationCredit.vue');
const PageServicesApplicationCreditDrpa = () => import('@/views/PageServicesApplicationCreditDrpa.vue');

import { getSessionAuth } from '@/libs/user-token';

import store from '@/store';
import { PathPlugin, path } from './path';
import { createLogger } from '@/uikit/util/logger';

const logger = createLogger('Router', 'purple');

Vue.use(VueRouter);
Vue.use(PathPlugin);

const routes = [
  {
    path: '/',
    name: 'pageHome',
    component: PageHome,
  },
  {
    path: '/objects/favorites',
    name: 'pageFavorites',
    component: PageFavorites,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/services',
    name: 'pageServices',
    component: PageServices,
  },
  {
    path: '/services/:serviceName',
    name: 'pageService',
    component: PageService,
    props: true,
  },

  /* -------------------------------------------------------------------------- */
  /* заявка кредит                                                              */
  /* -------------------------------------------------------------------------- */
  {
    path: '/service_applications/credit/:assetId/new/:stepName?',
    component: PageServicesApplicationCredit,
    props: true,
    meta: {
      layout: 'LayoutFull',
      requiresAuth: true,
    },
  },
  /* заявка кредит =========================================================== */

  /* -------------------------------------------------------------------------- */
  /* заявка кредит ДРПА                                                         */
  /* -------------------------------------------------------------------------- */
  {
    path: '/service_applications/credit_drpa/:assetId/new/:stepName?',
    component: PageServicesApplicationCreditDrpa,
    props: true,
    meta: {
      layout: 'LayoutFull',
      requiresAuth: true,
    },
  },
  /* заявка кредит =========================================================== */

  {
    path: '/objects/my_trades',
    name: 'pageAuctions',
    component: PageMyTrades,
    meta: {
      requiresAuth: true,
      layoutProps: { noFooter: true },
    },
  },
  {
    path: '/objects/catalog/:assetPurpose/:assetKind?',
    name: 'pageCatalog',
    component: PageCatalog,
    props: true,
  },
  {
    path: '/objects/:assetId',
    name: 'pageAsset',
    component: PageAsset,
    props: true,
  },
  {
    path: '/new-asset',
    name: 'pageNewAsset',
    component: PageNoMobile,
    meta: {
      layoutProps: { noFooter: true },
    },
  },
  {
    path: '/feeds/autoload',
    name: 'pageFaqAutoload',
    component: PageNoMobile,
    meta: {
      layoutProps: { noFooter: true },
    },
  },
  {
    path: '/auth/sign_in',
    name: 'pageSignIn',
    component: PageSignIn,
    meta: {
      layout: 'LayoutFull',
    },
  },
  {
    path: '/oauth/:provider/callback',
    name: 'pageOauthAuthorize',
    component: PageOauthAuthorize,
    props: true,
    meta: {
      layout: 'LayoutFull',
    },
  },
  {
    path: '/auth/sign_up',
    name: 'pageSignUp',
    component: PageSignUp,
    meta: {
      layout: 'LayoutFull',
    },
  },
  {
    path: '/auth/sign_up/individual',
    name: 'pageSignUpIndividual',
    component: PageSignUpIndividual,
    meta: {
      layout: 'LayoutFull',
    },
  },
  {
    path: '/auth/sign_up/legal',
    name: 'pageSignUpLegal',
    component: PageSignUpLegal,
    meta: {
      layout: 'LayoutFull',
    },
  },
  {
    path: '/auth/passwords/reset',
    name: 'pageAuthPassword',
    component: PageAuthPasswordReset,
    meta: {
      layout: 'LayoutFull',
    },
  },
  {
    path: '/create_pro',
    name: 'pageCreatePro',
    component: PageCreatePro,
    meta: {
      layout: 'LayoutFull',
      requiresAuth: true,
    },
  },
  {
    path: '/faq',
    name: 'pageFaq',
    component: PageFaq,
  },
  {
    path: '/faq/:faqSectionId',
    name: 'pageFaqSection',
    component: PageFaqSection,
    props: true,
  },
  {
    path: '/about',
    name: 'pageAbout',
    component: PageAbout,
  },
  {
    path: '/blog',
    name: 'pageBlog',
    component: PageBlog,
  },
  {
    path: '/article/:articleId',
    name: 'pageArticle',
    component: PageArticle,
    props: true,
  },
  {
    path: '/interactions',
    name: 'pageInteractions',
    component: PageInteractions,
    meta: {
      layout: 'LayoutFull',
      requiresAuth: true,
    },
  },
  {
    path: '/interactions/:id',
    name: 'pageInteractionDialog',
    component: PageInteractionDialog,
    props: true,
    meta: {
      layout: 'LayoutFull',
      requiresAuth: true,
    },
  },
  {
    path: '/i/notifications',
    name: 'pageNotifications',
    component: PageNotifications,
    meta: {
      layout: 'LayoutFull',
      requiresAuth: true,
    },
  },
  {
    path: '/profile/',
    name: 'pageProfile',
    component: PageProfile,
    meta: {
      layoutProps: { noFooter: true },
      requiresAuth: true,
    },
  },
  {
    path: '/i/splash',
    component: PageSplash,
    meta: {
      layout: 'LayoutFull',
    },
  },
  {
    path: '/enter/sbbol',
    component: PageSbbolAuth,
    meta: {
      layout: 'LayoutFull',
    },
  },
  {
    path: '/i/404',
    component: PageNotFound,
    meta: {
      layoutProps: { noFooter: true },
    },
  },
  {
    path: '/i/500',
    component: PageError,
    meta: {
      layoutProps: { noFooter: true },
    },
  },
  {
    path: '/i/not-supported',
    component: PageNoMobile,
    meta: {
      layoutProps: { noFooter: true },
    },
  },
  {
    path: '*',
    redirect: '/i/splash',
  },
  {
    path: '/auth/sign_in_data_room',
    component: PageDataroom,
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  logger.log('Push переход:', location);
  return originalPush.call(this, location).catch((err) => {
    logger.error(err);
  });
};

const router = new VueRouter({
  routes,
  mode: 'history',

  scrollBehavior(/*to, from, savedPosition*/) {
    return { x: 0, y: 0 };
  },
});

// Псевдо SSR === Вставляем мета-теги с бекенда
async function getMetaTags(route) {
  let data, response, path;
  let query = '';

  if (route.params.assetPurpose) {
    query = `?placement_purpose=${route.params.assetPurpose}`;
  }

  switch (route.name) {
    case 'pageCatalog':
      path = route.params.assetKind
        ? `api/v2/meta/kind/${route.params.assetKind}.json${query}`
        : `api/v2/meta/page/${route.params.assetPurpose}.json`;
      break;
    case 'pageAsset':
      path = `api/v2/meta/asset/${route.params.assetId}.json${query}`;
      break;
    case 'pageAbout':
      path = 'api/v2/meta/page/pages-about.json';
      break;
    case 'pageFaq':
      path = 'api/v2/meta/page/pages-faq.json';
      break;
    case 'pageBlog':
      path = 'api/v2/meta/page/pages-blog.json';
      break;
    case 'pageFaqSection':
      path = `api/v2/meta/page/faq-${route.params.faqSectionId}.json`;
      break;
    case 'pageFaqAutoload':
      path = 'api/v2/meta/page/feeds-autoload.json';
      break;
    case 'pageArticle':
      path = `api/v2/meta/article/${route.params.articleId}.json`;
      break;
    default:
      path = 'api/v2/meta/page/default.json';
      break;
  }

  try {
    data = await API.get(path);
  } catch (error) {
    console.log('error in meta', error);
  }

  // почему бы и нет
  if (!data && !data.data && !data.data.data) {
    return;
  }
  response = data.data.data;

  document.title = response.title;
  // Description
  let metaDescription = document.getElementById('meta-description');

  if (!metaDescription) {
    metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.id = 'meta-description';
    metaDescription.content = response.description;
    document.head.appendChild(metaDescription);
  } else {
    metaDescription.content = response.description;
  }

  // keywords
  if (response.keywords) {
    let metaKeywords = document.getElementById('meta-keywords');

    if (!metaKeywords) {
      metaKeywords = document.createElement('meta');
      metaKeywords.name = 'keywords';
      metaKeywords.id = 'meta-keywords';
      metaKeywords.content = response.keywords;
      document.head.appendChild(metaKeywords);
    } else {
      metaKeywords.content = response.keywords;
    }
  }
}

router.beforeEach(async (to, from, next) => {
  if (
    to.matched.some((path) => {
      return path.meta && path.meta.requiresAuth === true;
    })
  ) {
    logger.log('Путь требует авторизации...');

    const currentUser = await store.getters['currentUser'];
    const { user } = await getSessionAuth();

    if (currentUser || user) {
      logger.log('Найден авторизованный пользователь');
      next();
    } else {
      logger.log('Пользователь не найден. Переходим на страницу логина');
      next(path.signIn());
    }
  } else {
    next();
    getMetaTags(to);
  }
});

export default router;
