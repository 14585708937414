import { getConfig } from './config';

import { logger, style } from '@/uikit/util/logger';

const counters = {};
class BaseApi {
  constructor(schema, provider) {
    this.schema = schema;
    this.provider = provider;
  }

  // throws ApiException
  async fetch(apiMethodId, payload = {}, urlParams = {}, config = null) {
    config = config || getConfig();

    const counter = this.getCounter(apiMethodId);
    const api_method = this.schema.getApiMethod(apiMethodId);

    const request = this.schema.prepareRequest(api_method, payload, urlParams);
    logger.log(
      style.green(`API: >>> ${apiMethodId} #${counter}`),
      request.method.toUpperCase(),
      request.url,
      request.data || urlParams
    );

    request.onUploadProgress = config.onUploadProgress;
    request.headers = Object.assign(request.headers || {}, config.headers || {});

    const response = await this.provider.fetch(request, config);

    const parsedResponse = this.schema.getResponse(api_method, response);
    logger.log(style.gray(`API: ${apiMethodId} #${counter}`), parsedResponse.data);

    return parsedResponse;
  }

  getCounter(id) {
    if (!counters[id]) {
      counters[id] = 1;
    } else {
      counters[id]++;
    }
    return counters[id];
  }
}

export { BaseApi };
export default BaseApi;
