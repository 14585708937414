export const schema = {
  baseUrl: process.env.VUE_APP_API_HOST + '/api/v4',
  api: {
    existed_draft: {
      request: {
        method: 'get',
        url: '/assets/wizard/existed_draft',
        responseType: 'json',
      },
      response: {
        noParse: true,
      },
    },

    draft: {
      request: {
        method: 'post',
        url: '/assets/wizard/draft',
        responseType: 'json',
      },
      response: {
        noParse: true,
      },
    },
  },
};
