<template lang="pug">
.ui-badge(:class="classes" :style="styleObject")
  template(v-if="$slots.prepend || dot || smallDot")
    .ui-badge__prepend-slot
      slot(name="prepend")
        .ui-badge__icon.icon.icon-adm-dot-16(v-if="dot")
        .ui-badge__icon.icon.icon-adm-dot(v-if="smallDot")
  .ui-badge__content
    slot
</template>

<script>
  import './UiBadge.scss';

  import { themeable } from '../../mixins/themeable';
  import { sizeable } from '../../mixins/sizeable';

  export default {
    name: 'ui-badge',
    mixins: [themeable, sizeable],

    props: {
      outline: { type: Boolean, default: false },
      text: { type: Boolean, default: false },
      plain: { type: Boolean, default: false },
      top: { type: Boolean, default: false },
      bottom: { type: Boolean, default: false },
      noShadow: { type: Boolean, default: false },
      rounded: { type: Boolean, default: false },
      padless: { type: Boolean, default: false },
      dot: { type: Boolean, default: false },
      smallDot: { type: Boolean, default: false },
      inline: { type: Boolean, default: false },

      // green, blue, orange, red, grey, dark
      color: { type: String, default: '' },
    },

    computed: {
      classes() {
        return {
          'ui-badge--outlined': this.outline,
          'ui-badge--text': this.text,
          'ui-badge--plain': this.plain,
          'ui-badge--top': this.top,
          'ui-badge--bottom': this.bottom,
          'ui-badge--shadow': !this.noShadow,
          'ui-badge--rounded': this.rounded,
          'ui-badge--inline': this.inline,
          ...this.colorClasses,
          ...this.themeClasses,
          ...this.sizeableClasses,
        };
      },

      colorClasses() {
        if (!['green', 'blue', 'orange', 'red', 'grey', 'dark', 'white'].includes(this.color))
          return {};
        return { [`ui-badge--${this.color}`]: true };
      },

      styleObject() {
        return {
          ['backgroundColor:']: this.colorClass ? false : this.color,
        };
      },
    },
  };
</script>
