<template lang="pug">
span.ui-loader(:class="{ 'ui-loader--big': big, 'ui-loader--inline': inline }" v-show="loading")
  span.ui-loader__icon.a-spin(:class="icon")
  span.ui-loader__text(v-if="text") {{ text }}
</template>

<script>
  import './UiLoader.scss';

  import { themeable } from '../../mixins/themeable';

  export default {
    name: 'ui-loader',
    mixins: [themeable],

    props: {
      loading: { type: Boolean, default: true },
      preloader: { type: Boolean, default: false },
      inline: { type: Boolean, default: false },
      big: { type: Boolean, default: false },
      text: { type: String, default: null },
    },

    computed: {
      icon() {
        return this.preloader ? 'ui-loader__icon--preloader' : 'ui-loader__icon--spinner';
      },
    },
  };
</script>
<style lang="scss">
  @keyframes a-spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(359deg);
    }
  }

  .ui-loader__icon {
    @include animate(a-spin, infinite);
  }
</style>
