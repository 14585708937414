export function formatFileSize(size) {
  if (!size || size == 0) return '';

  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['Б', 'Кб', 'Мб', 'Гб', 'Тб'][i];
}

function install(Vue) {
  Vue.filter('fileSize', formatFileSize);
}

export default install;
