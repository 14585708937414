import { default as pluralizeFilter, pluralize } from '@vuejs-community/vue-filter-pluralize';

export function formatLotsCount(count) {
  if (typeof count !== 'number' && typeof count !== 'bigint') return '';
  return `${count} ${pluralize(count, 'ru', ['лот', 'лота', 'лотов'])}`;
}

export function formatAssetsCount(count) {
  if (!count) return '';
  return `${count} ${pluralize(count, 'ru', ['актив', 'актива', 'активов'])}`;
}

export function formatTradesCount(count) {
  if (!count) return '';
  return `${count} ${pluralize(count, 'ru', ['аукцион', 'аукциона', 'аукционов'])}`;
}

export function formatApplicationsCount(count) {
  if (!count) return '';
  return `${count} ${pluralize(count, 'ru', ['ставка', 'ставки', 'ставок'])}`;
}

export function formatNumbers(value, words) {
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
}

function install(Vue) {
  Vue.use(pluralizeFilter);
  Vue.filter('lotsCount', formatLotsCount);
  Vue.filter('assetCount', formatAssetsCount);
  Vue.filter('tradesCount', formatTradesCount);
  Vue.filter('applicationsCount', formatApplicationsCount);
  Vue.filter('formatNumbers', formatNumbers);
}

export default install;
