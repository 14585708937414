<template lang="pug">
.cookies-alert(v-if="isOpen" content-class="cookies-alert" width="100%")
  span.t-small Мы используем cookie (файлы с данными о прошлых посещениях сайта) и&nbsp;
  a.t-small.c-gray20.mx-1(
    href=`${process.env.VUE_APP_API_HOST}/documents/recommendation_system.pdf`
    target="_blank"
  ) рекомендательные технологии
  span.t-small &nbsp;с&nbsp;целью персонализации сервисов. Оставаясь на сайте, вы соглашаетесь на их применение.

  ui-btn.cookies-alert__close(large outlined @click="close") ОК
</template>

<script>
  import { UiBtn } from '@/uikit';

  export default {
    name: 'cookies-alert',
    components: { UiBtn },

    data() {
      return {
        isOpen: false,
      };
    },

    mounted() {
      if (!this.$cookies.get('cookies_agreement')) {
        this.isOpen = true;
      }
    },

    methods: {
      close() {
        this.isOpen = false;
        this.$cookies.set('cookies_agreement', 1, 31536000);
      },
    },
  };
</script>

<style lang="scss">
  .cookies-alert {
    position: fixed;
    z-index: 1000;
    right: 16px;
    left: 16px;
    bottom: 16px;
    padding: 20px 16px;
    border-radius: 12px;
    background-color: c('gray100', 'light');
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.16);
    color: c('gray20', 'light');

    & > .cookies-alert__close {
      border-color: c('primary40', 'light');
      margin-top: 16px;
      width: 100%;
      .v-btn__content {
        color: white;
        font-size: 15px;
      }
    }
  }
</style>
