const filterCapitalize = function (value) {
  if (!value && value !== 0) return '';
  return value[0] ? `${value[0].toUpperCase()}${value.slice(1)}` : '';
};

function install(Vue) {
  Vue.filter('capitalize', filterCapitalize);
}

export default install;
