import CookiesAlert from '@/components/CookiesAlert.vue';

function createElement() {
  const el = Object.assign(document.createElement('div'));
  document.body.appendChild(el);
  return el;
}

export default {
  name: 'Диалог "Предупреждение об использовании cookies"',

  install(Vue, { store, vuetify, router }) {
    const el = createElement();
    const Component = Vue.extend(CookiesAlert);
    new Component({
      store,
      vuetify,
      router,
    }).$mount(el);
  },
};
