<template lang="pug">
.asset-photo(:style="heightStyle" @click="$emit('click')")
  ui-swipe(v-model="currentSlide" :disabled="photos.length == 1")
    .asset-photo__main(v-for="(photo, i) in photos" :key="'photo' + i" :style="heightStyle")
      ui-badge.asset-photo__main__badge(v-if="photos.length > 1" color="transparent" no-shadow) {{ currentSlide + 1 + "/" + photos.length }}
      img(:src="photo" :style="heightStyle")
</template>

<script>
  import assetDefaultImage from '@/assets/default-asset-img.png';
  import { UiBadge, UiIcon, UiSwipe } from '@/uikit';
  import { convertToUnit } from '@/uikit/util';

  export default {
    components: { UiIcon, UiSwipe, UiBadge },

    props: {
      value: { type: Number, default: 0 },
      photos: { type: Array, default: () => [] },
      height: { type: [String, Number], default: 180 },
    },

    computed: {
      currentSlide: {
        set(value) {
          this.$emit('input', value);
        },

        get() {
          return this.value;
        },
      },

      heightStyle() {
        return {
          height: convertToUnit(this.height),
        };
      },

      photoMain() {
        return this.this.photos.length > 0 ? this.photos[0] : assetDefaultImage;
      },
    },
  };
</script>

<style lang="scss">
  .asset-photo {
    position: relative;

    .asset-photo__main {
      width: 100%;
      background-color: c('light', 'light');

      img {
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
      }

      &__badge {
        @include font-size(13, 16);
        position: absolute;
        bottom: 18px;
        right: 18px;
      }
    }
  }
</style>
