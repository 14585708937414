<template lang="pug">
v-navigation-drawer(
  v-model="isOpen"
  absolute
  right
  temporary
  :width="width"
)
  .ui-side-panel(:class="classes")
    .ui-side-panel__header(v-if="$slots.header")
      slot(name="header")
    slot
    .ui-side-panel__footer(v-if="$slots.footer || $slots.actions")
      slot(name="footer")
        .ui-side-panel__actions
          slot(name="actions")
</template>

<script>
  import './UiSidePanel.scss';
  import { themeable } from '../../mixins/themeable';

  export default {
    name: 'ui-side-panel',
    mixins: [themeable],

    props: {
      value: { type: Boolean, default: false },
      width: { type: [String, Number], default: 520 },
    },

    computed: {
      classes() {
        return {
          ...this.themeClasses,
        };
      },

      isOpen: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
</script>
