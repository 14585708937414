import ApiException from './apiException';
class ApiSchema {
  constructor(schema) {
    this.schema = schema;
  }

  getBaseUrl() {
    return this.schema.baseUrl;
  }

  prepareRequest(api_method, payload, url_params) {
    try {
      const request = {};

      let url = api_method.request.url;
      url = url.replace(/\(:([a-z_]+)\)?/g, (_, param) => {
        return url_params[param];
      });

      request.url = url;
      request.method = api_method.request.method;
      request.responseType = api_method.request.responseType;

      if (api_method.request.headers) {
        request.headers = api_method.request.headers;
      }

      if (request.method === 'get') {
        request.params = payload;
      } else {
        request.data = payload;
      }

      return request;
    } catch (error) {
      throw new ApiException('Ошибка формирования запроса', error);
    }
  }

  getResponse(api_method, response) {
    let parsedResponse = {};
    const ok = response.status === 'error' ? false : true;

    // Возвращаем значение под новые апишки
    if (api_method.response.noParse) {
      return response;
    }

    if (api_method.response.plain) {
      parsedResponse = {
        ok,
        status: response.status,
        data: response.data,
        code: response.code,
        errors: response.errors,
        message: response.message,
      };

      if (!ok) {
        throw new ApiException('Ответ с ошибкой в статусе', parsedResponse);
      }

      return parsedResponse;
    }

    const fields = api_method.response.fields || [];
    const values = {};
    const errorsObject = {};
    const errors = [];

    fields.forEach((field) => {
      if (response.data[field]) {
        values[field] = response.data[field].value;

        if (response.data[field].error) {
          errorsObject[field] = response.data[field].error;
          errors.push({ field, error: response.data[field].error });
        }
      }
    });

    parsedResponse = { fields, values, errors, errorsObject, ok };

    if (api_method.response.redirect) {
      const redirect = response.data.redirect_uri || null;
      Object.assign(parsedResponse, { redirect });
    }

    if (!ok) {
      throw new ApiException('Нарушена валидация', parsedResponse);
    }

    return parsedResponse;
  }

  getApiMethod(id) {
    const api_method = this.schema.api[id];
    if (!api_method) {
      throw new ApiException(`Метод '${id}' отсутствует в схеме API`);
    }
    return api_method;
  }
}

export { ApiSchema };
export default ApiSchema;
