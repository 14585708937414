export const sizeable = {
  props: {
    large: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    xLarge: { type: Boolean, default: false },
    xSmall: { type: Boolean, default: false },
  },

  computed: {
    medium() {
      return Boolean(!this.xSmall && !this.small && !this.large && !this.xLarge);
    },

    sizeableClasses() {
      return {
        'ui-size--x-small': this.xSmall,
        'ui-size--small': this.small,
        'ui-size--default': this.medium,
        'ui-size--large': this.large,
        'ui-size--x-large': this.xLarge,
      };
    },
  },
};
