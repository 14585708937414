import { BaseApi, ApiSchema, AxiosProvider, nestedParamsSerializer } from '@/libs/api';
import { schema } from './schema';

const apiSchema = new ApiSchema(schema);
const axiosProvider = new AxiosProvider({
  baseUrl: apiSchema.getBaseUrl(),
  paramsSerializer: nestedParamsSerializer,
});

class AssetsService extends BaseApi {
  async getFeaturedAssets() {
    const {
      data: { business, personal, recommended },
    } = await this.fetch('featured_assets');
    return { business, personal, recommended };
  }

  async getCategories(purpose = 'buy') {
    const { data: categories } = await this.fetch('categories_tree', {
      placement_purpose: purpose,
    });
    return categories;
  }

  async getAsset(id) {
    const { data: asset } = await this.fetch('asset_by_id', null, { id });
    return asset;
  }

  async getPreviousTrades(assetId) {
    const { data } = await this.fetch('previous_trades', null, {
      id: assetId,
    });
    return data;
  }

  async getAssetBiddings(id) {
    const { data: biddings } = await this.fetch('asset_biddings_by_id', null, { id });
    return biddings;
  }

  async findAssets(searchData, purpose) {
    const {
      data: { assets, total },
    } = await this.fetch('catalog_search', {
      search: searchData,
      placement_purpose: purpose,
    });

    return { assets, total };
  }

  async getAssetsCount(searchData, purpose) {
    const { data } = await this.fetch('catalog_search_count', {
      search: searchData,
      placement_purpose: purpose,
    });
    return data.count;
  }

  async getAssetsTotalCount(purpose) {
    const { data } = await this.fetch('catalog_search_count', {
      placement_purpose: purpose,
    });
    return data.count;
  }

  async getFilters(kind, purpose) {
    const { data: filters } = await this.fetch('catalog_filters_by_kind', {
      kind: kind,
      placement_purpose: purpose,
    });

    return filters;
  }

  async getFavorites(page) {
    const {
      data: { assets, total },
    } = await this.fetch('asset_favorites', { search: { page } });
    return { assets, total };
  }

  async toggleFavorite(assetId) {
    const { data } = await this.fetch('asset_favorite_toggle', null, { id: assetId });
    return data.favorite;
  }

  async getSearchPresetById(searchId) {
    const { data } = await this.fetch('search_preset_by_id', null, { id: searchId });
    return data;
  }

  async getSearchPresetByShareId(shareId) {
    const { data } = await this.fetch('search_preset_by_share_id', null, { id: shareId });
    return data;
  }

  async getSearchPresets(page) {
    const {
      data: { presets, total },
    } = await this.fetch('search_presets_search', { search: { page } });
    return { presets, total };
  }

  async saveSearchPreset(params) {
    const { data } = await this.fetch('search_preset_create', {
      search_preset: {
        body: JSON.stringify(params),
      },
    });
    return data.uuid;
  }

  async shareSearchPreset(params) {
    const { data } = await this.fetch('search_preset_share', {
      search_preset: {
        body: JSON.stringify(params),
      },
    });
    return data.share_link;
  }

  async deleteSearchPreset(presetId) {
    const { ok } = await this.fetch('search_preset_delete', null, { id: presetId });
    return ok;
  }

  async getLocations(query) {
    const { data } = await this.fetch('suggestions_locations', { query: query });
    return data;
  }
}

const service = new AssetsService(apiSchema, axiosProvider);

export { AssetsService };
export { service };
export default service;
