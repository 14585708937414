import axios from 'axios';
import ApiException from './apiException';

// import ApiResponse from './apiResponse';
// TODO: AxiosApiResponse не используется
// class AxiosApiResponse extends ApiResponse {
//   constructor() {}
// }

import qs from 'qs';
const nestedParamsSerializer = (params) => {
  const paramsSerialized = qs.stringify(params, {
    arrayFormat: 'brackets',
    encodeValuesOnly: true,
  });
  return paramsSerialized;
};

let defaultOptions = {};

class AxiosProvider {
  constructor(options) {
    this.axios = axios.create({
      baseURL: options.baseUrl,
      ...defaultOptions,
      ...options,
    });
  }

  async fetch(request) {
    try {
      // TODO: зарефакторить — добавить ApiRequest и заполнять из него специфичный для axios
      // TODO: сейчас ApiSchema готовит реквест в формате axios
      const axiosResponse = await this.axios(request);

      // TODO: зарефакторить на использование ApiResponse
      // response = new AxiosApiResponse(axiosResponse)
      // return response;
      return axiosResponse.data;
    } catch (error) {
      throw new ApiException('Ошибка запроса', error.response?.data);
    }
  }
}

export { nestedParamsSerializer };
export { AxiosProvider };
export default AxiosProvider;
