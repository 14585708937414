<template lang="pug">
.service-card(
  :class="classes"
  v-if="small"
  :style="styles"
  @click="$emit('click')"
)
  img.service-card__img(:src="imgUrl" :alt="service.title")
  .service-card__title(v-html="title")

.service-card(:class="classes" v-else @click="$emit('click')")
  img.service-card__img(:src="imgUrl" :alt="service.title")
  h3.service-card__title(v-html="title")
  p.service-card__text {{ service.description }}

  .service-card__tags.t-xsmall.mb-5(v-if="!noTags && service.tags && service.tags.length")
    ui-badge(
      v-for="tag in service.tags_text"
      :key="tag.id"
      color="grey"
      no-shadow
    ) {{ tag }}

  slot
</template>

<script>
  import { measurable } from '@/uikit/mixins/measurable';

  export default {
    mixins: [measurable],
    props: {
      imgSrc: { type: String, default: undefined },
      small: { type: Boolean, default: false },
      noTags: { type: Boolean, default: false },
      service: { type: Object, default: () => {} },
    },

    computed: {
      classes() {
        return {
          'service-card--small': this.small,
        };
      },

      styles() {
        return this.measurableStyles;
      },

      title() {
        return this.service.title_formatted || this.service.title;
      },

      imgUrl() {
        return this.imgSrc
          ? this.imgSrc
          : this.service.name
          ? this.$images.services[this.service.name]
          : '';
      },
    },
  };
</script>

<style lang="scss">
  .service-card {
    background-color: c('white');
    box-shadow: shadow('xsmall');
    padding: 24px 24px 32px 24px;
    border-radius: 12px;
  }

  .service-card__img {
    width: 96px;
    height: 96px;
    margin-left: -8px;
    margin-bottom: 12px;
  }

  .service-card__title {
    @include text-style('h3');
    color: c('gray100');
    margin-bottom: 8px;
  }

  .service-card__text {
    @include text-style('middle');
    color: c('gray80');
    margin-bottom: 0 !important;
  }

  .service-card--small {
    padding: 16px 16px 24px 16px;

    .service-card__img {
      width: 80px;
      height: 80px;
      margin-left: 0;
      margin-bottom: 0;
    }

    .service-card__title {
      @include text-style('caption-normal');
      color: c('gray100');
      margin-bottom: 0;
    }

    .service-card__text {
      display: none;
    }
  }
</style>
