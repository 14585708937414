<template lang="pug">
v-btn(
  :class="classes"
  v-on="$listeners"
  v-bind="$attrs"
  :color="color || settings.color"
  :dark="settings.theme == 'dark'"
  :depressed="depressed || settings.depressed"
  :light="settings.theme == 'light'"
  :outlined="outlined || settings.outlined"
  :text="text || settings.text"
  :disabled="disabled"
)
  template(v-if="loading")
    ui-loader

  template(v-else)
    slot
</template>

<script>
  import './UiBtn.scss';

  import { UiLoader } from '../UiLoader';
  import { themeable } from '../../mixins/themeable';
  import { sizeable } from '../../mixins/sizeable';

  import presets from './presets.js';

  export default {
    name: 'ui-btn',
    components: { UiLoader },
    mixins: [themeable, sizeable],
    inheritAttrs: false,

    props: {
      compound: { type: Boolean, default: false },
      primary: { type: Boolean, default: false },
      secondary: { type: Boolean, default: false },
      third: { type: Boolean, default: false },

      outlined: { type: Boolean, default: false },
      text: { type: Boolean, default: false },
      depressed: { type: Boolean, default: false },
      color: { type: String, default: undefined },
      loading: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      preset: { type: String, default: null },
    },

    data() {
      return {
        settings: { theme: null, color: null, outlined: null, text: null, className: null },
      };
    },

    computed: {
      classes() {
        let classes = {
          'ui-btn': true,
          'ui-btn--compound': this.compound,
          'ui-btn--loading': this.loading,
          'ui-state--disabled': this.disabled,
          ...this.sizeableClasses,
        };

        if (this.settings.className) {
          classes[this.settings.className] = true;
        }

        return classes;
      },
    },

    created() {
      this.initSettings();
    },

    methods: {
      initSettings() {
        let presetName = null;
        if (this.preset) presetName = this.preset;
        if (this.primary) presetName = 'primary';
        if (this.secondary) presetName = 'secondary';
        if (this.third) presetName = 'third';

        const preset =
          presetName && presets[this.themeName][presetName]
            ? presets[this.themeName][presetName]
            : null;
        if (!preset) return;

        this.settings = { ...this.settings, ...preset };
      },
    },
  };
</script>
