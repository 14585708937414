<template lang="pug">
.ui-linear-progress(:style="styles")
  .ui-linear-progress__fill(:style="fillStyles")
</template>

<script>
  import './UiLinearProgress.scss';

  export default {
    props: {
      value: { type: Number, default: 0 },
      size: { type: Number, default: 4 },
      bgColor: { type: String, default: undefined },
      color: { type: String, default: undefined },
    },

    computed: {
      styles() {
        return {
          height: `${this.size}px`,
          backgroundColor: this.bgColor ? `${this.bgColor} !important` : undefined,
        };
      },

      fillStyles() {
        return {
          width: `${this.value}%`,
          backgroundColor: this.color ? `${this.color} !important` : undefined,
        };
      },
    },
  };
</script>
