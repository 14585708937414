export const schema = {
  baseUrl: process.env.VUE_APP_API_HOST + '/api/v2',
  api: {
    featured_assets: {
      request: {
        method: 'get',
        url: '/catalog/featured.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    categories_tree: {
      request: {
        method: 'get',
        url: '/kinds/tree.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    asset_by_id: {
      request: {
        method: 'get',
        url: '/assets/(:id).json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    previous_trades: {
      request: {
        method: 'get',
        url: '/assets/(:id)/previous_trades.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    asset_biddings_by_id: {
      request: {
        method: 'get',
        url: '/assets/(:id)/biddings.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    catalog_filters_by_kind: {
      request: {
        method: 'get',
        url: '/catalog/filters.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    catalog_search: {
      request: {
        method: 'post',
        url: '/catalog/search.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    catalog_search_count: {
      request: {
        method: 'post',
        url: '/catalog/count.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    catalog_map_clusters: {
      request: {
        method: 'post',
        url: '/catalog/clusters.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    asset_get_contact_phone: {
      request: {
        method: 'get',
        url: '/assets/(:id)/phone.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    asset_favorites: {
      request: {
        method: 'get',
        url: '/catalog/favorites.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    asset_favorite_toggle: {
      request: {
        method: 'get',
        url: '/assets/(:id)/toggle_favorite.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    my_assets_search: {
      request: {
        method: 'post',
        url: '/assets/my/search.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    suggestions_locations: {
      request: {
        method: 'get',
        url: '/suggestions/locations.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    search_preset_create: {
      request: {
        method: 'post',
        url: '/search_presets.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    search_preset_by_id: {
      request: {
        method: 'get',
        url: '/search_presets/(:id).json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    search_preset_by_share_id: {
      request: {
        method: 'get',
        url: '/search_presets/shared/(:id).json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    search_presets_search: {
      request: {
        method: 'get',
        url: '/search_presets.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    search_preset_delete: {
      request: {
        method: 'delete',
        url: '/search_presets/(:id).json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    search_preset_share: {
      request: {
        method: 'post',
        url: '/search_presets/share.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },
  },
};
