import { SearchFilter } from './search-filter.js';
export class AssetQuery {
  constructor(searchData = {}) {
    const { kind, addresses, placement_purpose, purpose, query, bbox, page, per, sort } =
      searchData;

    // placement_purpose (как на беке) или purpose (как на фронте) или по умолчанию
    this.purpose = placement_purpose || purpose || 'buy';

    this.kind = kind;
    this.addresses = addresses || [];
    this.query = query;
    this.bbox = bbox;
    this.sort = sort;
    this.page = page || 1;
    this.per = per;
    this.filters = [];
  }

  setAddresses(addresses) {
    this.addresses = addresses;
  }

  setKind(kind) {
    this.kind = kind;
  }

  setBbox(bbox) {
    this.bbox = bbox;
  }

  setQuery(query) {
    this.query = query;
  }

  setPage(page) {
    this.page = page;
  }

  setPer(per) {
    this.per = per;
  }

  setPurpose(purpose) {
    this.purpose = purpose;
  }

  setFilters(filters = []) {
    this.filters = filters;
  }

  getFilters(predicateFn = undefined) {
    return this.predicateFn ? this.filters.filter(predicateFn) : this.filters;
  }

  findFilter(filterName) {
    return this.filters.find((f) => f.name === filterName);
  }

  buildFilters(filtersData) {
    const filters = AssetQuery.buildFilters(filtersData);
    this.setFilters(filters);
  }

  assignFromCriterias(criteriasData) {
    if (Array.isArray(criteriasData)) {
      criteriasData.forEach((cr) => {
        var filter = this.findFilter(cr.field);
        if (filter) filter.setValueFromCriteria(cr);
      });
    }
  }

  extractCriterias(criteriasNames) {
    const criteriasData = [];
    if (Array.isArray(criteriasNames)) {
      criteriasNames.forEach((filterName) => {
        var filter = this.findFilter(filterName);
        const criteria = filter ? filter.toCriteria() : undefined;
        if (criteria) criteriasData.push(criteria);
      });
    }
    return criteriasData;
  }

  reset() {
    this.filters.forEach((f) => f.reset());
  }

  getCriterias() {
    return this.filters.filter((f) => !f.isEmpty()).map((f) => f.toCriteria());
  }

  toJSON() {
    const filtersParams = this.getCriterias();
    const searchParams = {};
    if (filtersParams.length > 0) searchParams.filters = filtersParams;
    if (this.kind) searchParams.kind = this.kind;
    if (this.addresses && this.addresses.length) searchParams.addresses = this.addresses;
    if (this.query) searchParams.query = this.query;
    if (this.page) searchParams.page = this.page;
    if (this.per) searchParams.per = this.per;
    if (this.sort) searchParams.sort = this.sort;
    if (this.purpose) {
      searchParams.purpose = this.purpose;
      searchParams.placement_purpose = this.purpose;
    }
    return searchParams;
  }

  static build(filtersData = [], searchData = {}) {
    const assetQuery = new AssetQuery(searchData);
    assetQuery.buildFilters(filtersData);
    assetQuery.assignFromCriterias(searchData.filters);
    return assetQuery;
  }

  static buildFilters(filtersData) {
    if (Array.isArray(filtersData)) {
      return filtersData.map((fd) => SearchFilter.build(fd));
    } else {
      return undefined;
    }
  }
}
