<template lang="pug">
.ui-expansion-panels(:class="classes")
  slot
</template>

<script>
  import { createLogger } from '@/uikit/util/logger.js';
  const logger = createLogger('UiExpansionPanels', 'orange');

  export default {
    name: 'ui-expansion-panels',

    provide() {
      return {
        state: this.state,
        api: this.api,
        panels: this,
      };
    },

    props: {
      value: { type: Array, default: () => [] },
    },

    data() {
      return {
        items: [],
        state: {
          expanded: this.value,
          expandedPanelId: undefined,
        },
      };
    },

    computed: {
      classes() {
        return {
          'ui-expansion-panels': true,
        };
      },

      api() {
        return { toggle: this.toggle, close: this.close, open: this.open };
      },
    },

    methods: {
      registerPanel(panel) {
        const id = this.items.length;
        logger.log('registerPanel', id, this.items);
        this.items.push(panel);
        return id;
      },

      unregisterPanel(panelId) {
        logger.log('unregisterPanel', panelId, this.items);
        delete this.items[panelId];
      },

      toggle(panelId) {
        this.items[panelId].toggle();
        this.$emit('input', this.state);
      },

      open(panelId) {
        this.items[panelId].open();
        this.state.expanded[panelId] = true;
        this.state.expandedPanelId = panelId;
        this.$emit('input', this.state);
      },

      close(panelId) {
        this.items[panelId].close();
        this.state.expanded[panelId] = false;
        this.state.expandedPanelId = undefined;
        this.$emit('input', this.state);
      },
    },
  };
</script>
