<template lang="pug">
.ui-dropdown-button(:class="{ 'ui-dropdown-button--active': state.isOpen }" @click="toggle")
  .ui-dropdown-button__left
    slot(name="left" :api="api" :state="state")

  .ui-dropdown-button__content
    slot(:api="api" :state="state")

  .ui-dropdown-button__right
    slot(name="right" :api="api" :state="state")
      .icon.icon-chevron-down
</template>

<script>
  import { createLogger } from '@/uikit/util/logger.js';
  const logger = createLogger('UiDropdownButton', 'orange');

  export default {
    name: 'ui-dropdown-button',
    inject: ['state', 'api'],

    methods: {
      toggle() {
        logger.log('toggle');
        this.api.toggle();
      },
    },
  };
</script>

<style lang="scss">
  .ui-dropdown-button {
    display: flex;
    align-items: center;

    &__left {
      display: flex;
      align-items: center;
    }

    &__content {
      flex-grow: 1;
      min-width: 0;
      overflow: hidden;

      .text--truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__right {
      display: flex;
      align-items: center;

      .icon-chevron-down {
        @include transition--fast(transform);
      }
    }

    &__content + &__right {
      margin-left: 12px;
    }

    &--active &__right .icon-chevron-down {
      transform: rotate(180deg);
    }
  }
</style>
