<template lang="pug">
v-bottom-sheet(v-model="isOpen" content-class="auth-popup" width="100%")
  ui-dialog-header(
    close-btn
    flat
    transparent
    @click:close="close"
  )

  .auth-popup__content
    .avatar.mr-4
      img(src="~@/assets/avatar-user.svg" width=64 height=64)
    .t-middle.c-gray80.mt-2
      template(v-if="text")
        | {{ text }}
      template(v-else)
        | Войдите или зарегистрируйтесь, чтобы увидеть контакты продавцов, информацию о торгах и получить доступ к большему количеству активов

  .auth-popup__footer
    ui-btn.mb-4(
      large
      primary
      :to="$path.signIn()"
      width="100%"
    ) Войти

    ui-btn(
      large
      secondary
      :to="$path.signUp()"
      width="100%"
    ) Зарегистрироваться
</template>

<script>
  import { UiBtn, UiDialogHeader } from '@/uikit';

  export default {
    components: { UiBtn, UiDialogHeader },

    data() {
      return {
        text: undefined,
        isOpen: false,
      };
    },

    methods: {
      open(text) {
        this.text = text;
        this.isOpen = true;
      },

      close() {
        this.text = undefined;
        this.isOpen = false;
      },
    },
  };
</script>

<style lang="scss">
  .auth-popup {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    background-color: c('white', 'light');
  }

  .auth-popup__content {
    display: flex;
    padding: 0 48px 0 16px;
  }

  .auth-popup__footer {
    padding: 40px 16px 40px 16px;
  }
</style>
