export default {
  name: 'ui-theme-provider',

  provide() {
    return {
      theme: this.themeableProvide,
    };
  },

  props: {
    light: { type: Boolean, default: undefined },
    dark: { type: Boolean, default: undefined },
  },

  data() {
    return {
      themeableProvide: {
        isDark: false,
      },
    };
  },

  created() {
    this.themeableProvide.isDark = this.isDark;
  },

  computed: {
    isDark() {
      if (this.dark === true) {
        // принудительно темная
        return true;
      } else if (this.light === true) {
        // принудительно светлая
        return false;
      } else {
        // наследуем из настроек или по false умолчанию
        return this.$vuetify?.theme?.dark || false;
      }
    },
  },

  render() {
    return (
      this.$slots.default &&
      this.$slots.default.find((node) => !node.isComment && node.text !== ' ')
    );
  },
};
