<template lang="pug">
.ui-loader-panel(:style="measurableStyles")
  ui-loader
</template>

<script>
  import UiLoader from './UiLoader.vue';
  import { measurable } from '@/uikit/mixins/measurable';

  export default {
    name: 'ui-loader-panel',
    components: { UiLoader },
    mixins: [measurable],
  };
</script>

<style lang="scss">
  .ui-loader-panel {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
