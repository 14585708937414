import serviceSberlot from '@/assets/96/service-sberlot.svg';
import serviceEnforcementSberlot from '@/assets/96/service-enforcement_sberlot.svg';
import serviceCredit from '@/assets/96/service-credit.svg';
import serviceCreditDrpa from '@/assets/96/service-credit-drpa.svg';
import serviceAgroAi from '@/assets/96/service-agroai.svg';
import serviceLetterOfCredit from '@/assets/96/buy.svg';
import serviceCreditPotential from '@/assets/96/service-credit_potential.svg';
import serviceAutoCredit from '@/assets/96/service-auto_credit.svg';

const services = {
  sberlot: serviceSberlot,
  enforcement_sberlot: serviceEnforcementSberlot,
  credit: serviceCredit,
  credit_drpa: serviceCreditDrpa,
  agroai: serviceAgroAi,
  letter_of_credit: serviceLetterOfCredit,
  credit_potential: serviceCreditPotential,
  auto_credit: serviceAutoCredit,
};

const images = {
  services,
};

function install(Vue) {
  Vue.prototype.$images = images;
}

export { install as ImagesPlugin, images };
export default install;
