<template lang="pug">
v-text-field.text-field--search(
  :class="classes"
  v-on="$listeners"
  v-bind="$attrs"
  filled
  hide-details
  solo
)
  template(#prepend-inner)
    ui-icon(name="search")
  template(#append)
    ui-loader(:loading="loading")
</template>

<script>
  import { UiIcon } from '@/uikit/components/UiIcon';
  import { UiLoader } from '@/uikit/components/UiLoader';

  import './UiSearchTextField.scss';

  export default {
    name: 'ui-search-text-field',
    components: { UiIcon, UiLoader },
    inheritAttrs: false,

    props: {
      loading: { type: Boolean, default: false },
    },

    data() {
      return {};
    },

    computed: {
      classes() {
        return {};
      },
    },
  };
</script>
