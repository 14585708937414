<template lang="pug">
.ui-dropdown-content(
  :class="{ 'ui-dropdown-content--popup-menu': popup }"
  v-if="state.isOpen"
  :style="styleObject"
)
  slot(:api="api" :state="state")
</template>

<script>
  export default {
    name: 'ui-dropdown-content',
    inject: ['state', 'api'],

    props: {
      popup: { type: Boolean, default: true },
      justify: { type: String, default: 'left' },
      topOffset: { type: Number, default: 0 },
    },

    computed: {
      styleObject() {
        return this.topOffset ? 'top: calc(100% + ' + this.offset + 'px)' : null;
      },

      offset() {
        return this.topOffset || this.state.topOffset;
      },
    },

    watch: {
      'state.isOpen': {
        handler(active) {
          if (active) {
            window.addEventListener('keyup', this.onEsc);
          } else {
            window.removeEventListener('keyup', this.onEsc);
          }
        },
      },
    },

    methods: {
      onEsc(event) {
        if (event.which === 27) this.api.close();
      },
    },
  };
</script>

<style lang="scss">
  .ui-dropdown-content {
  }

  .ui-dropdown-content.ui-dropdown-content--popup-menu {
    position: absolute;
    z-index: 6;
    top: 100%;
    right: auto;
    left: 0;
    border-radius: 4px;
    background-color: c('white', 'light');
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.24);

    &:focus {
      outline: none;
    }

    .ui-dropdown--right & {
      right: 0;
      left: auto;
    }
  }
</style>
