<script>
  import './UiSidePanel.scss';
  export default {
    name: 'ui-side-panel-footer',
    functional: true,

    props: {
      sticky: { type: Boolean, default: false },
      padless: { type: Boolean, default: false },
    },

    render(h, { props, data = {}, children }) {
      const classes = {
        'ui-side-panel__footer': true,
        'ui-side-panel__footer--sticky': props.sticky,
        'ui-side-panel__footer--padless': props.padless,
      };

      return h('div', { ...data, class: classes }, children);
    },
  };
</script>
