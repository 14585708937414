import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/lib/locale/ru';
import icons from './pda-icons';

const options = {
  treeShake: true,
  icons: {
    iconfont: 'mdiSvg',
    ...icons,
  },
  theme: {
    dark: false,
    options: {
      customProperties: false, // css-переменные var() не работают в IE  даже после postcss
      variations: false,
    },
    themes: {
      dark: {
        primary: '#4CAF50',
        secondary: '#F3F4F6', // светлые кнопки
        accent: '#7047BB',
        error: '#f44336',
        info: '#2095F3',
        success: '#4CAF50',
        warning: '#FF9D0A',
      },
      light: {
        primary: '#4CAF50',
        secondary: '#33373d', // тёмные кнопки
        accent: '#7047BB',
        error: '#f44336',
        info: '#2095F3',
        success: '#4CAF50',
        warning: '#FF9D0A',
      },
    },
  },
  lang: {
    locales: { ru },
    current: 'ru',
  },
};

export default new Vuetify(options);
