<template lang="pug">
component(:key="componentKey" v-bind="layoutProps" :is="layout")
  router-view
  auth-popup(ref="authRequiredDialog")
  open-desktop-confirmation-dialog(ref="openDesktopConfirmationDialog")
  open-go-wizard-dialog(ref="openGoWizardDialog")
</template>

<script>
  import AuthPopup from '@/components/AuthPopup.vue';
  import OpenDesktopConfirmationDialog from '@/components/OpenDesktopConfirmationDialog.vue';
  import OpenGoWizardDialog from '@/components/OpenGoWizardDialog.vue';
  import { getSessionAuth } from '@/libs/user-token';

  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('App', 'blue');

  export default {
    components: { AuthPopup, OpenDesktopConfirmationDialog, OpenGoWizardDialog },
    provide() {
      return {
        requireAuth: this.requireAuth,
        openDesktop: this.openDesktop,
        openGoWizardDialog: this.openGoWizardDialog,
      };
    },

    data() {
      return {
        componentKey: 0,
      };
    },

    computed: {
      layout() {
        const layout = this.$route.meta.layout || 'LayoutDefault';
        return () => import(`./layouts/${layout}.vue`);
      },

      layoutProps() {
        return this.$route.meta.layoutProps || undefined;
      },
    },

    mounted() {
      // Добавьте обработчик события при монтировании компонента

      window.addEventListener('componentKeyChanged', this.handleComponentKeyChange);
    },
    beforeDestroy() {
      // Удалите обработчик события при уничтожении компонента
      window.removeEventListener('componentKeyChanged', this.handleComponentKeyChange);
    },

    created() {
      this.saveUTM();
    },

    methods: {
      requireAuth(msg) {
        this.$refs.authRequiredDialog.open(msg);
      },

      openDesktop(msg) {
        this.$refs.openDesktopConfirmationDialog.open(msg);
      },

      openGoWizardDialog() {
        this.$refs.openGoWizardDialog.open();
      },

      handleComponentKeyChange() {
        // Эта функция будет вызываться при изменении window.componentKey
        if (window.componentKey === 1) {
          // Вызовите вашу функцию здесь
          this.componentKey++;
        }
      },

      parseQueryString(queryString) {
        const params = {};
        queryString = queryString.substring(1); // Удаляем ведущий символ "?"
        const paramArray = queryString.split('&');

        paramArray.forEach((param) => {
          const [key, value] = param.split('=');
          params[key] = value;
        });

        return params;
      },

      saveUTM() {
        const UTMs = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

        const params = {};
        let queryString = window.location.search;

        queryString = queryString.substring(1); // Удаляем ведущий символ "?"

        const paramArray = queryString.split('&');

        paramArray.forEach((param) => {
          const [key, value] = param.split('=');

          // Проверяем, присутствует ли ключ в массиве UTMs
          if (UTMs.includes(key)) {
            params[key] = value;
          }
        });

        if (Object.keys(params).length) {
          localStorage.setItem('utms', JSON.stringify(params));
        }
      },
    },
  };
</script>
