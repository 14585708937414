import { BaseApi, ApiSchema, AxiosProvider, nestedParamsSerializer } from '@/libs/api';
import { schema } from './schema';

const apiSchema = new ApiSchema(schema);
const axiosProvider = new AxiosProvider({
  baseUrl: apiSchema.getBaseUrl(),
  paramsSerializer: nestedParamsSerializer,
});

export function customSortServices(services) {
  // см house/-/issues/4542#note_188889
  // 1. Кредит на выкуп с торгов по банкротству (credit_drpa)
  // 2. Ипотека на выкуп с торгов по банкротству (credit)
  // 3. Агент в торгах (sberlot)
  // 4. Автокредит (auto_credit)
  // 5. Кредитный потенциал (credit_potential)
  // 6. Аккредитив (letter_of_credit)
  // 7. AgroAI (agroai)
  const priority = {
    credit_drpa: 1,
    credit: 2,
    sberlot: 3,
    auto_credit: 4,
    credit_potential: 5,
    letter_of_credit: 6,
    agroai: 7,
  };

  return services.sort((s1, s2) => {
    return priority[s1.name] - priority[s2.name];
  });
}

export function filterPrimary(services) {
  return services.filter((s) => s.primary);
}

export function filterOther(services) {
  return services.filter((s) => !s.primary);
}

export function sortServices(services) {
  return services.sort((s1, s2) => s1.priority - s2.priority);
}

class ServicesService extends BaseApi {
  async getServices() {
    const { data } = await this.fetch('get_services');
    return data;
  }

  /* аккредитив =============================================================== */
  async newLetterOfCredit(asset_id) {
    const { data } = await this.fetch('new_letter_of_credit', {}, { id: asset_id });
    return data.letter_of_credit;
  }

  async getLetterOfCredit(asset_id) {
    const { data } = await this.fetch('get_letter_of_credit', {}, { id: asset_id });
    return data.letter_of_credit;
  }

  async createLetterOfCreditApplication(application) {
    const { data } = await this.fetch('letter_of_credit_application_create', {
      letter_of_credit: application,
    });
    return data;
  }
  /* //аккредитив ============================================================= */

  /* заявка на ипотеку на выкуп с торгов ====================================== */
  async newCredit(asset_id) {
    const { data } = await this.fetch('new_credit', {}, { asset_id: asset_id });
    return { application: data.credit, applicationId: data.application_id };
  }

  async createCreditApplication(application) {
    const { data } = await this.fetch('credit_application_create', {
      service_application: application,
    });
    return { redirectUrl: data.redirect_uri, applicationId: data.application_id };
  }
  /* //заявка на ипотеку на выкуп с торгов ==================================== */

  /* заявка на кредит на выкуп с торгов ====================================== */
  async newCreditDrpa(asset_id) {
    const { data } = await this.fetch('new_credit_drpa', {}, { asset_id: asset_id });
    return { application: data.credit_drpa, applicationId: data.application_id };
  }

  async createCreditDrpaApplication(application) {
    const { data } = await this.fetch('credit_drpa_application_create', {
      service_application: application,
    });
    return { redirectUrl: data.redirect_uri, applicationId: data.application_id };
  }
  /* //заявка на ипотеку на выкуп с торгов ==================================== */

  async getEfrsbLots(asset_id) {
    const { data } = await this.fetch('asset_efrsb_lots', {}, { id: asset_id });
    return data;
  }
}

const service = new ServicesService(apiSchema, axiosProvider);

export { ServicesService };
export { service };
export default service;
