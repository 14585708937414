import store from '@/store';
import router from '@/router';
import { path } from '@/router/path';
import { service as authService } from '@/services/auth/auth-service';
import { appNotificationToast } from '@/plugins/app-notify-plugin';

// Глобальная переменная для хранения состояния блокировки
let refreshTokenInProgress = false;

export async function handleError(error, logger = undefined) {
  if (logger) logger.error(error);

  // истекший токен авторизации
  if (error.data && error.data.code === 'token_expired') {
    try {
      if (logger) logger.log('Токен авторизации истек');

      const currentUser = store.getters['currentUser'];
      const currentRefreshToken = store.getters['user/refreshToken'];
      if (!currentUser || !currentRefreshToken) {
        throw 'Нет пользователя или токена';
      }

      // пробуем освежить токен и перелогиниться
      if (logger) logger.log('Пробуем освежить токен и перелогиниться');
      if (!refreshTokenInProgress) {
        refreshTokenInProgress = true;
        const { token, refreshToken } = await authService.refreshToken(currentRefreshToken);
        if (token && refreshToken) {
          if (logger) logger.log('Токен обновлён');
          store.dispatch('user/signInWithToken', { token, refreshToken, user: currentUser });

          // для обновления страницы после обновления токена
          window.componentKey = 1;
          const event = new Event('componentKeyChanged');
          window.dispatchEvent(event);
          refreshTokenInProgress = false;
        } else {
          refreshTokenInProgress = false;
          throw 'Не удалось обновить токен';
        }
      }
    } catch (error) {
      if (error.data && error.data.code === 'refresh_token_expired') {
        // разлогиниваем если рефреш токен истёк
        store.dispatch('user/signOut');
        appNotificationToast.error('Истёк токен авторизации. Необходимо войти в систему заново');
        router.push(path.signIn());
      }

      if (!refreshTokenInProgress) {
        if (logger) {
          // ничего не вышло
          logger.log('Не вышло обновить токен. Разлогиниваем и отправляем на страницу входа');
          logger.error(error);
        }
        store.dispatch('user/signOut');
        appNotificationToast.error('Истёк токен авторизации. Необходимо войти в систему заново');
        router.push(path.signIn());
      }
    }
  }
}
