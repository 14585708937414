<template lang="pug">
.ui-dialog-header(:class="classes")
  .ui-dialog-header__back(v-if="backBtn" @click="$emit('click:back')")
    ui-icon.c-gray70(name="arrow-outline2-left")
  .ui-dialog-header__title
    slot
      | {{ title }}
  .ui-dialog-header__close(v-if="closeBtn" @click="$emit('click:close')")
    ui-icon.c-gray70(name="close")
</template>

<script>
  import UiBtn from './UiBtn';
  import UiIcon from './UiIcon';

  export default {
    components: { UiBtn, UiIcon },
    inheritAttrs: false,

    props: {
      left: { type: Boolean, default: false },
      title: { type: String, default: '' },
      backBtn: { type: Boolean, default: false },
      closeBtn: { type: Boolean, default: false },
      rounded: { type: Boolean, default: false },
      transparent: { type: Boolean, default: false },
      flat: { type: Boolean, default: false },
    },

    computed: {
      classes() {
        return {
          'ui-dialog-header--left': this.left,
          'ui-dialog-header--flat': this.flat,
          'ui-dialog-header--rounded': this.rounded,
          'ui-dialog-header--transparent': this.transparent,
        };
      },
    },
  };
</script>

<style lang="scss">
  .ui-dialog-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 100%;
    padding-left: 44px;
    padding-right: 44px;
    background-color: c('white');
    box-shadow: shadow('xsmall');

    &--flat {
      box-shadow: unset;
    }

    &--rounded {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    &--transparent {
      background-color: transparent;
    }
  }

  .ui-dialog-header--left {
    justify-content: start;
    padding-left: 16px;
  }

  .ui-dialog-header__title {
    @include text-style('h3-mobile');
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: c('gray100');
  }

  .ui-dialog-header__back {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 2px;
    left: 4px;
    width: 44px;
    height: 44px;
  }

  .ui-dialog-header__close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 2px;
    right: 4px;
    width: 44px;
    height: 44px;
  }
</style>
