import store from '@/store';

function getConfig(additionalConfig = {}) {
  let config = {};
  config.headers = {};

  let headers = {};

  // Запросы от авторизованного пользователя
  let userToken = store.getters['userToken'] || undefined;
  if (userToken) {
    headers['Colibri-Token'] = userToken;
    headers['Auth-Token'] = userToken;
  }

  // BasicAuth для стейджинга
  if (process.env.NODE_ENV === 'staging' && process.env.VUE_APP_BASIC_AUTHENTICATION) {
    headers['Authorization'] = `Basic ${btoa(process.env.VUE_APP_BASIC_AUTHENTICATION)}`;
  }

  // объединение общих настроек
  config = Object.assign({}, config, additionalConfig);

  // объединение хэдеров отдельно
  headers = Object.assign({}, headers, additionalConfig.headers || {});
  config.headers = headers;

  return config;
}

export { getConfig };
export default getConfig;
