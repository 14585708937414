export class Category {
  constructor(data) {
    this.class_name = data.class_name;
    this.kind = data.class_name;
    this.title = data.title;
    this.level = data.level;
    this.parent = data.parent;
    this.parents = data.parents;
    this.total = data.total;
    this.leaf = data.leaf;
  }

  toString() {
    return `${this.level}:${this.kind} ↰[${this.parents}]`;
  }
}
export class CategoriesTree {
  constructor(tree) {
    this.tree = tree;
    this.map = {};
    this.index = [];
    this.addCategories(tree);
  }

  addCategories(categories, parents = [], level = 1) {
    if (!Array.isArray(categories)) return;

    categories.forEach((category) => {
      this.add(category, parents, level);
      if (category.subkinds)
        this.addCategories(category.subkinds, [...parents, category.class_name], level + 1);
    });
  }

  add(category, parents, level) {
    const parent = parents[parents.length - 1];
    const c = new Category({
      class_name: category.class_name,
      kind: category.class_name,
      title: category.title,
      level: level,
      parent: parent,
      parents: parents,
      total: category.total,
      leaf: Boolean(!category.subkinds),
    });

    this.map[category.class_name] = c;
    this.index.push(c);
  }

  find(kind, predicateFn = () => true) {
    if (kind) {
      const category = this.map[kind];
      return category && predicateFn(category) ? category : undefined;
    } else {
      return undefined;
    }
  }

  get(kind) {
    if (kind) {
      return this.map[kind];
    } else {
      return undefined;
    }
  }

  getTree() {
    return this.tree;
  }

  getIndex() {
    return this.index;
  }

  searchByTitle(query) {
    if (!query || !query.length) return [];
    return this.index.reduce((found, c) => {
      if (c.title.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        found.push(this.map[c.kind]);
      }
      return found;
    }, []);
  }
}
