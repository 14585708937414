// @param value — time in seconds
const filterTime = function (value) {
  if (!value && value !== 0) return '';

  let days = Math.floor(value / 86400);
  let hours = Math.floor((value - days * 86400) / 3600);
  let mins = Math.floor((value - days * 86400 - hours * 3600) / 60);
  let secs = Math.floor(value - days * 86400 - hours * 3600 - mins * 60);

  secs = secs < 10 ? '0' + secs : secs;
  mins = mins < 10 ? '0' + mins : mins;
  return (days ? days + 'д ' : '') + (hours ? hours + ':' : '') + mins + ':' + secs;
};

function install(Vue) {
  Vue.filter('duration', filterTime);
}

export default install;
