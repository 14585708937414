<template lang="pug">
.ui-alert.d-flex.align-start(:class="classObject")
  .ui-alert__balloon-arrow(v-if="balloon" :style="{ left: balloonArrowLeft + 'px' }")
  ui-icon.ui-alert__icon.flex-shrink-0.flex-grow-0.mr-2(
    v-if="iconName && !loading"
    :name="iconName"
    :size="iconSize"
  )
  ui-icon.ui-alert__icon.flex-shrink-0.flex-grow-0.mr-2(v-if="loading" name="loadings")
  .ui-alert__content.flex-grow-1
    .ui-alert__content-title(v-if="title || $slots.title")
      slot(name="title")
        | {{ title }}
    .ui-alert__content-text(v-if="$slots.default")
      slot
</template>

<script>
  import UiIcon from '../UiIcon/UiIcon.vue';

  export default {
    name: 'ui-alert',
    components: { UiIcon },

    props: {
      icon: { type: String, default: '' },
      iconSize: { type: String, default: '24' },
      title: { type: String, default: '' },
      loading: { type: Boolean, default: false },
      error: { type: Boolean, default: false },
      warning: { type: Boolean, default: false },
      success: { type: Boolean, default: false },
      iconOnly: { type: Boolean, default: false },
      dense: { type: Boolean, default: false },
      info: { type: Boolean, default: false },
      rounded: { type: Boolean, default: false },
      borderTop: { type: Boolean, default: false },
      borderBottom: { type: Boolean, default: false },
      borderTopLarge: { type: Boolean, default: false },
      borderBottomLarge: { type: Boolean, default: false },
      balloon: { type: Boolean, default: false },
      oneLine: { type: Boolean, default: false },
      balloonArrowOverlap: { type: Boolean, default: false },
      balloonArrowLeft: { type: String, default: '34' },
    },

    data() {
      return {
        hasBorderOnBottom: this.borderBottom || this.borderBottomLarge,
        hasBorderOnTop: this.borderTop,
        hasBorderOnLeft:
          !this.iconOnly &&
          !this.borderTop &&
          !this.borderTopLarge &&
          !this.borderBottom &&
          !this.borderBottomLarge,
        isDense: this.dense,
      };
    },

    computed: {
      classObject() {
        return {
          ['ui-alert--loading']: this.loading,
          ['ui-alert--icon-only']: this.iconOnly,
          ['ui-alert--dense']: this.isDense,
          ['ui-alert--error']: this.error,
          ['ui-alert--warning']: this.warning,
          ['ui-alert--info']: this.info,
          ['ui-alert--success']: this.success,
          ['ui-alert--border-top']: this.hasBorderOnTop,
          ['ui-alert--border-top-large']: this.borderTopLarge,
          ['ui-alert--border-bottom']: this.hasBorderOnBottom,
          ['ui-alert--border-bottom-large']: this.borderBottomLarge,
          ['ui-alert--border-left']: this.hasBorderOnLeft,
          ['ui-alert--rounded']: this.rounded,
          ['ui-alert--balloon']: this.balloon,
          ['ui-alert--balloon-arrow-overlap']: this.balloonArrowOverlap,
        };
      },

      iconName() {
        if (this.icon) return this.icon;

        let icon = '';
        if (this.error) icon = 'message-alert';
        if (this.warning) icon = 'error';
        if (this.info) icon = 'info';
        if (this.success) icon = 'check-round-full';
        return icon;
      },
    },

    created() {
      if (this.balloon) {
        this.hasBorderOnTop = true;
        this.hasBorderOnLeft = false;
        this.hasBorderOnBottom = false;
      }

      if (this.oneLine) {
        this.isDense = true;
        this.hasBorderOnLeft = false;
        this.hasBorderOnTop = true;
      }
    },
  };
</script>

<style lang="scss">
  .ui-alert {
    padding-top: 16px;
    padding-right: 32px;
    padding-bottom: 16px;
    padding-left: 32px;
    border-width: 0;
    border-style: solid;
  }

  .ui-alert--icon-only {
    padding-left: 0;
    border: 0;
    background: none !important;
  }

  .ui-alert--dense {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .ui-alert--border-left {
    padding-right: 16px;
    padding-left: 16px;
    border-left-width: 3px;
    border-style: solid;
  }

  .ui-alert--border-top {
    border-top-width: 1px;
    border-style: solid;
  }

  .ui-alert--border-top-large {
    border-top-width: 4px;
    border-style: solid;
  }

  .ui-alert--border-bottom {
    border-bottom-width: 1px;
    border-style: solid;
  }

  .ui-alert--border-bottom-large {
    border-bottom-width: 4px;
    border-style: solid;
  }

  .ui-alert--balloon {
    position: relative;
    margin-bottom: 16px;
    padding-right: 32px;
    padding-left: 32px;

    &.ui-alert--balloon-arrow-overlap {
      margin-bottom: 0;
    }

    .ui-alert__balloon-arrow {
      position: absolute;
      bottom: -6px;
      left: 34px;
      width: 18px;
      height: 18px;
      transform: rotate(-45deg);
      background-color: inherit;
    }
  }

  .ui-alert--border-top.ui-alert--rounded,
  .ui-alert--border-top-large.ui-alert--rounded {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .ui-alert--border-bottom.ui-alert--rounded,
  .ui-alert--border-bottom-large.ui-alert--rounded {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .ui-alert--error {
    border-color: c('red60');
    background-color: c('red10');

    .ui-alert__icon {
      color: c('red60');
    }
  }

  .ui-alert--warning {
    border-color: c('orange60');
    background-color: c('orange10');

    .ui-alert__icon {
      color: c('orange60');
    }
  }

  .ui-alert--success {
    border-color: c('primary60');
    background-color: c('primary10');

    .ui-alert__icon {
      color: c('primary60');
    }
  }

  .ui-alert--info {
    border-color: c('blue60');
    background-color: c('blue10');

    .ui-alert__icon {
      color: c('blue60');
    }
  }

  .ui-alert--loading {
    & .ui-alert__icon {
      animation: rotate360 1s infinite;
    }
  }

  @keyframes rotate360 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(359deg);
    }
  }

  .ui-alert__content-title {
    @include text--h5;
    margin-top: 2px;
  }

  .ui-alert__content-title + .ui-alert__content-text {
    margin-top: 8px;
  }
</style>
