<template lang="pug">
v-btn-toggle.ui-btn-toggle-checkbox(
  :class="classes"
  v-on="$listeners"
  v-bind="$attrs"
  @change="emitValue"
)
  slot
</template>

<script>
  import './UiBtnToggleCheckbox.scss';

  export default {
    inheritAttrs: false,

    props: {
      vertical: { type: Boolean, default: false },
    },

    computed: {
      classes() {
        return [{ 'ui-btn-toggle-checkbox--vertical': this.vertical }];
      },
    },

    methods: {
      emitValue(value) {
        this.$emit('input', value);
      },
    },
  };
</script>
