/* Dependencies ============================================================= */
import { removeSessionAuth, saveSessionAuth } from '@/libs/user-token';

import { createLogger } from '@/uikit/util/logger';
const logger = createLogger('User', 'green');

/* State ==================================================================== */
const state = () => ({
  token: undefined,
  refreshToken: undefined,
  currentUser: undefined,
});

/* Getters ================================================================== */
const getters = {
  token: (state) => {
    return state.token;
  },

  refreshToken: (state) => {
    return state.refreshToken;
  },
};

/* Mutations ================================================================ */

const mutations = {
  SIGN_IN_WITH_TOKEN(state, { token, refreshToken, user }) {
    state.token = token;
    state.refreshToken = refreshToken;
    state.currentUser = user;
  },

  SIGN_OUT(state) {
    state.token = undefined;
    state.refreshToken = undefined;
    state.currentUser = undefined;
  },

  SET_USER(state, user) {
    state.currentUser = user;
  },

  SET_TOKENS(state, token, refreshToken) {
    state.token = token;
    state.refreshToken = refreshToken;
  },
};

/* Actions ================================================================== */

const actions = {
  signInWithToken({ commit }, { token, refreshToken, user }) {
    logger.log('Проставляем текущего пользователя', user, token, refreshToken);
    commit('SIGN_IN_WITH_TOKEN', { token, refreshToken, user });
    saveSessionAuth(token, refreshToken, user);
    // метрика
    // eslint-disable-next-line no-undef
    ym(49130266, 'userParams', {
      UserID: user.id,
      email: user.email,
      role: user.role,
    });
  },

  signOut({ commit }) {
    logger.log('Обнуляем текущего пользователя');
    commit('SIGN_OUT');
    removeSessionAuth();
    const path = `${process.env.VUE_APP_API_HOST}/auth/sign_out_phoenix`
    window.location.href = path;
  },

  updateUser({ commit, getters }, user) {
    commit('SET_USER', user);
    saveSessionAuth(getters['token'], getters['refreshToken'], user);
  },

  updateTokens({ commit }, token, refreshToken) {
    commit('SET_TOKENS', token, refreshToken);
  },
};

/* Exports ================================================================== */
export default {
  state,
  getters,
  actions,
  mutations,
};
