export default {
  light: {
    primary: {
      theme: 'light',
      depressed: true,
      outlined: false,
      className: 'btn-primary',
    },

    secondary: {
      theme: 'light',
      depressed: true,
      outlined: true,
      className: 'btn-secondary',
    },

    secondary_transparent: {
      theme: 'light',
      depressed: true,
      outlined: true,
      className: 'btn-secondary-transparent',
    },

    third: {
      theme: 'light',
      depressed: true,
      outlined: false,
      className: 'btn-third',
    },

    transparent: {
      theme: 'light',
      depressed: true,
      outlined: false,
      className: 'btn-transparent',
    },

    overlay: {
      theme: 'light',
      depressed: true,
      outlined: false,
      className: 'btn-overlay',
    },

    rounded: {
      theme: 'light',
      depressed: true,
      outlined: false,
      className: 'btn-rounded',
    },

    outlined_mobile: {
      theme: 'light',
      depressed: true,
      outlined: true,
      className: 'btn-outlined-mobile',
    },
  },

  dark: {
    primary: {
      theme: 'dark',
      depressed: true,
      outlined: false,
      className: 'btn-primary',
    },
    secondary: {
      theme: 'dark',
      depressed: true,
      outlined: true,
      className: 'btn-secondary',
    },
    third: {
      theme: 'dark',
      depressed: true,
      outlined: false,
      className: 'btn-third',
    },

    primary_outlined: {
      theme: 'dark',
      depressed: true,
      outlined: true,
      className: 'btn-primary-outlined',
    },
  },
};
