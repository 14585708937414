import Constants from '../util/constants';

const filterCurrency = function (value, options = {}) {
  if (!value && value !== 0) return '';

  const defaultOptions = Constants.MONEY_FORMAT;

  const decimal_sep = options.decimal || defaultOptions.decimal || ',';
  const precision = options.precision || defaultOptions.precision || 0;
  const thousands = options.thousands || defaultOptions.thousands || ' ';
  const prefix = options.prefix || defaultOptions.prefix || '';
  const suffix = options.suffix || defaultOptions.prefix || ' ₽';

  let price = parseFloat(value);

  let exp10 = Math.pow(10, precision); // приводим к правильному множителю
  price = Math.round(price * exp10) / exp10; // округляем до необходимого числа знаков после запятой

  let rr = Number(price).toFixed(precision).toString().split('.');

  let whole = rr[0].replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g, '$1' + thousands);

  price = rr[1] ? whole + decimal_sep + rr[1] : whole;
  return prefix + price + suffix;
};

function install(Vue) {
  Vue.filter('currency', filterCurrency);
}

export default install;
