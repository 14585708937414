// import Constants from '@/shared/constants';
import { default as dateFormatFilter, dateFormat } from '@vuejs-community/vue-filter-date-format';

const DATE_FORMAT = {
  dayOfWeekNames: [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
  ],
  dayOfWeekNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  monthNames: [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ],
  monthNamesShort: [
    'янв',
    'фев',
    'мар',
    'апр',
    'май',
    'июн',
    'июл',
    'авг',
    'сен',
    'окт',
    'ноя',
    'дек',
  ],
  dateFormal: 'DD.MM.YY',
  dateShort: 'D MMM',
  dateFull: 'DD MMM, YYYY',
  dateFullest: 'DD MMMM, YYYY',
  timeShort: 'HH:mm',
  // Timezone offset, in minutes (0 - UTC, 180 - Russia, undefined - current)
  // timezone: 0
};

export function formatDate(timestamp, forceFull = undefined) {
  if (!timestamp) return '';

  const date = new Date(timestamp);
  const format =
    (date.getFullYear() != new Date().getFullYear() && forceFull === undefined) ||
    forceFull === true
      ? DATE_FORMAT.dateFull
      : DATE_FORMAT.dateShort;
  return dateFormat(date, format, DATE_FORMAT);
}

export function formatDateFormal(timestamp) {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  return dateFormat(date, DATE_FORMAT.dateFormal, DATE_FORMAT);
}

export function formatTime(timestamp) {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  return dateFormat(date, DATE_FORMAT.timeShort, DATE_FORMAT);
}

export function formatDateTime(timestamp) {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  return formatDate(timestamp) + ' в ' + dateFormat(date, 'HH:mm', DATE_FORMAT);
}

export function formatFullMonth(timestamp) {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  return dateFormat(date, 'DD MMMM, YYYY', DATE_FORMAT);
}

function install(Vue) {
  Vue.use(dateFormatFilter, DATE_FORMAT);
  Vue.filter('date', formatDate);
  Vue.filter('dateFullest', (timestamp) => formatFullMonth(timestamp));
  Vue.filter('dateFull', (timestamp) => formatDate(timestamp, true));
  Vue.filter('dateShort', (timestamp) => formatDate(timestamp, false));
  Vue.filter('dateFormal', (timestamp) => formatDateFormal(timestamp));
  Vue.filter('time', (timestamp) => formatTime(timestamp));
  Vue.filter('datetime', (timestamp) => formatDateTime(timestamp));
}

export default install;
