<template lang="pug">
ui-badge.asset-deal-type-badge(:class="bgColor" no-shadow) {{ title }}
</template>

<script>
  import { UiBadge } from '@/uikit';

  export default {
    name: 'asset-deal-type-badge',
    components: { UiBadge },
    props: {
      type: { type: String, default: undefined },
    },

    data() {
      return {
        badgeTypes: {
          bidding_commerce: {
            color: 'turquoise',
            title: 'Коммерческие торги',
          },
          bidding_bankruptcy: {
            color: 'turquoise',
            title: 'Торги в банкротстве',
          },
          bidding_enforcement: {
            color: 'turquoise',
            title: 'Торги в испол. производстве',
          },
          sale: {
            color: 'blue',
            title: 'Прямая продажа',
          },
          sale_or_rent: {
            color: 'blue',
            title: 'Прямая продажа / Аренда',
          },
          rent: {
            color: 'blue',
            title: 'Аренда',
          },
          trade_default: {
            color: 'purple',
            title: 'Аукцион на повышение',
          },
          trade_dutch: {
            color: 'purple',
            title: 'Аукцион на понижение',
          },
        },
      };
    },

    computed: {
      badge() {
        return this.badgeTypes[this.type];
      },

      title() {
        return this.badge.title;
      },

      bgColor() {
        return `asset-deal-type-badge--${this.badge.color}`;
      },
    },
  };
</script>

<style lang="scss">
  .asset-deal-type-badge {
    font-size: 13px !important;
    border: 1px solid #fff;
    padding: 2px 8px;
    border-radius: 4px;

    &--turquoise {
      background: c('turquoise10', 'light') !important;
      border-color: c('turquoise30', 'light') !important;
      color: c('turquoise80', 'light') !important;
    }

    &--blue {
      background: c('blue10', 'light') !important;
      border-color: c('blue30', 'light') !important;
      color: c('blue80', 'light') !important;
    }

    &--purple {
      background: c('purple10', 'light') !important;
      border-color: c('purple30', 'light') !important;
      color: c('purple80', 'light') !important;
    }
  }
</style>
