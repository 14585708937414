<template lang="pug">
ui-badge.asset-badge(
  v-bind="$attrs"
  :color="color"
)
  slot
</template>

<script>
  import { UiBadge } from '@/uikit';

  const COLORS = {
    new: 'blue',
    trade: 'green',
    business: 'green',
    trade_finished: 'dark',
    rights_purchase: 'grey',
    bidding_lot: 'grey',
    biddings: 'orange',
    my: 'dark',
  };

  export default {
    name: 'asset-badge',
    components: { UiBadge },
    inheritAttrs: false,

    props: {
      kind: { type: String, default: '' },
    },

    computed: {
      color() {
        return COLORS[this.kind];
      },
    },
  };
</script>
