<template lang="pug">
v-snackbar.ui-toast(
  :class="style.className"
  v-model="valueModel"
  :bottom="bottom"
  :centered="centered"
  :max-width="maxWidth"
  :min-width="minWidth"
  :right="right"
  :timeout="delay"
  :top="top"
)
  ui-icon.ui-toast__icon(:name="style.icon")
  .ui-toast__content
    .ui-toast__title(v-if="title") {{ title }}
    .ui-toast__text
      | {{ message }}
      slot
</template>

<script>
  import { UiIcon } from '../';

  export default {
    name: 'ui-toast',
    components: { UiIcon },

    props: {
      title: { type: String, default: '' },
      message: { type: String, default: '' },
      type: { type: String, default: 'info' },
      delay: { type: Number, default: 2000 },
      value: { type: Boolean, default: false },
      top: { type: Boolean, default: true },
      centered: { type: Boolean, default: false },
      right: { type: Boolean, default: false },
      bottom: { type: Boolean, default: false },
      maxWidth: { type: String, default: undefined },
      minWidth: { type: String, default: undefined },
    },

    data() {
      return {
        isOpen: true,
      };
    },

    computed: {
      valueModel: {
        set(value) {
          this.$emit('input', value);
        },

        get() {
          return this.value;
        },
      },

      style() {
        let icon = '';
        let className = '';

        switch (this.type) {
          case 'info':
            icon = 'info';
            className = 'ui-toast--info';
            break;

          case 'success':
            icon = 'check-round-full';
            className = 'ui-toast--success';
            break;

          case 'warning':
            icon = 'error';
            className = 'ui-toast--warning';
            break;

          case 'error':
            icon = 'close-round';
            className = 'ui-toast--error';
            break;

          default:
            break;
        }
        return { icon, className };
      },
    },
  };
</script>

<style lang="scss">
  .ui-toast {
    .v-sheet.v-snack__wrapper {
      border-radius: 8px !important;
    }
  }

  .ui-toast__icon {
    margin-right: 8px;
  }

  .ui-toast__text {
    @include text--middle;
    margin-top: 2px;
    color: c('black100', 'light');
  }

  .ui-toast--info {
    .v-sheet.v-snack__wrapper {
      border: 2px solid c('blue--light', 'light') !important;
      background: c('blue--lightest', 'light');
    }

    .ui-toast__icon {
      background-color: c('blue', 'light');
    }
  }

  .ui-toast--success {
    .v-sheet.v-snack__wrapper {
      border: 2px solid c('primary--light', 'light') !important;
      background: c('primary--lightest', 'light');
    }

    .ui-toast__icon {
      background-color: c('primary', 'light');
    }
  }

  .ui-toast--warning {
    .v-sheet.v-snack__wrapper {
      border: 2px solid c('orange--light', 'light') !important;
      background: c('orange--lightest', 'light');
    }
    .ui-toast__icon {
      background-color: c('orange', 'light');
    }
  }

  .ui-toast--error {
    .v-sheet.v-snack__wrapper {
      border: 2px solid c('red--light', 'light') !important;
      background: c('red--lightest', 'light');
    }
    .ui-toast__icon {
      background-color: c('red', 'light');
    }
  }

  .v-snack__content {
    display: flex;
    align-items: top;
    padding: 18px;
  }
</style>
