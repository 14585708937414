import { convertToUnit } from '../util';

export const measurable = {
  props: {
    width: { type: [Number, String], default: undefined },
    height: { type: [Number, String], default: undefined },
    minWidth: { type: [Number, String], default: undefined },
    maxWidth: { type: [Number, String], default: undefined },
    minHeight: { type: [Number, String], default: undefined },
    maxHeight: { type: [Number, String], default: undefined },
  },

  computed: {
    measurableStyles() {
      const styles = {};

      const width = convertToUnit(this.width);
      const minWidth = convertToUnit(this.minWidth);
      const maxWidth = convertToUnit(this.maxWidth);
      const height = convertToUnit(this.height);
      const minHeight = convertToUnit(this.minHeight);
      const maxHeight = convertToUnit(this.maxHeight);

      if (width) styles.width = width;
      if (minWidth) styles.minWidth = minWidth;
      if (maxWidth) styles.maxWidth = maxWidth;
      if (height) styles.height = height;
      if (minHeight) styles.minHeight = minHeight;
      if (maxHeight) styles.maxHeight = maxHeight;

      return styles;
    },
  },
};
