// главный компонент для лэйаута приложения
// используется на верхнем уровне в компонентах Page*
// через пропс :name можно передавать `LayoutFull` или `LayoutDefault`
export default {
  name: 'app-layout',

  props: {
    name: { type: String, default: 'LayoutDefault' },
    props: { type: Object, default: () => {} },
  },

  render() {
    return this.$slots.default && this.$slots.default.length ? this.$slots.default[0] : null;
  },
};
