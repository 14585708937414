<template lang="pug">
.ui-drawer(:class="classes")
  slot(:api="api" :state="state")
</template>

<script>
  import { createLogger } from '@/uikit/util/logger.js';
  const logger = createLogger('UiDrawer', 'orange');

  export default {
    name: 'ui-drawer',

    provide() {
      return {
        state: this.state,
        api: this.api,
      };
    },

    props: {
      value: { type: Boolean, default: false },
      justify: { type: String, default: 'left' },
    },

    data() {
      return {
        state: {
          isOpen: this.value,
        },
      };
    },

    computed: {
      classes() {
        return {
          'ui-drawer--active': this.state.isOpen,
        };
      },

      api() {
        return { toggle: this.toggle, close: this.close, open: this.open };
      },
    },

    watch: {
      value(isOpen) {
        // logger.log('watch(value)', isOpen);
        isOpen ? this.open() : this.close();
      },
    },

    methods: {
      toggle() {
        const open = !this.state.isOpen;
        logger.log('toggle to', open);
        open ? this.open() : this.close();
      },

      open() {
        this.state.isOpen = true;
        logger.log('open()', this.state.isOpen);
        this.$emit('input', this.state.isOpen);
      },

      close() {
        this.state.isOpen = false;
        logger.log('close()', this.state.isOpen);
        this.$emit('input', this.state.isOpen);
      },
    },
  };
</script>
