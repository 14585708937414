import { UiIcon } from '../components';

// надо поддерживать согласовынный список
// с $svg-icons из @css/common/uikit/base/_icon.scss
export const iconNames = [
  'adm-file-default-32',
  'adm-file-doc-32',
  'adm-file-jpg-32',
  'adm-file-jpeg-32',
  'adm-file-zip-32',
  'adm-file-pdf-32',
  'folder',
  'compass',
  'sort',
  'search',
  'plus',
  'cross',
  'check',
  'check2',
  'arrow-down',
  'arrow-right',
  'arrow-left',
  'chevron-up',
  'chevron-down',
  'chevron-right',
  'chevron-left',
  'chevron',
  'time',
  'eye-on',
  'eye-off',
  'eye-lock',
  'print',
  'share',
  'bookmark',
  'bookmark-outline',
  'album-add',
  'catalog-list-16',
  'check-round-full-16',
  'check-round-full',
  'fullscreen',
  'map-pointer',
  'map-pin-16',
  'alert-round-full',
  'message-question',
  'play',
  'up',
  'down',
  'decrease',
  'increase',
  'help',
  'open-window',
  'tune',
  'add-round',
  'file',
  'pen-plus',
  'file-download',
  'documents-sign',
  'minus',
  'history',
  'grid-view',
  'table-view',
  'list-view',
  'map-view',
  'arrow-outline-left',
  'arrow-outline-right',
  'renew',
  'loadings',
  'preloader',
  'message',
  'message-outline',
  'message2',
  'bell',
  'user-round',
  'enter',
  'exit',
  'send',
  'plus24',
  'pencil',
  'close',
  'close-2',
  'close-round',
  'asset',
  'asset-search',
  'asset-search-2',
  'error',
  'case-check',
  'phone-eye',
  'phone',
  'trash',
  'shield-link',
  'time-s',
  'date',
  'dot',
  'filter',
  'message-alert',
  'info',
  'alert',
  'check-double',
  'check-single',
  'calendar',
  'calendar-check',
  'magnifier',
  'error-page-warning',
  'ie',
  'firefox',
  'chrome',
  'video',
  'undo',
  'redo',
  'format-bold',
  'format-italic',
  'format-indent-increase',
  'format-indent-decrease',
  'format-list-bulleted',
  'format-list-numbered',
  'format-title',
  'auction',
  'information-small',
  'more',
  'email',
  'menu',
  'image-outline',
  'download',
  'file-with-lines',
  'sliders',
  'default-user',
];

const icons = iconNames.reduce((icons, iconName) => {
  icons[`pda-${iconName}`] = {
    component: UiIcon,
    props: { name: iconName },
  };
  return icons;
}, {});

export default icons;
