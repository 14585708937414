<template lang="pug">
ui-drawer.ui-expansion-panel(:class="classes" v-model="isOpen")
  ui-dropdown-button.ui-expansion-panel__header.ui-clickable
    slot(name="header" :active="isOpen")
  ui-drawer-content.ui-expansion-panel__content
    slot
</template>

<script>
  import UiDrawer from '@/uikit/components/UiDrawer.vue';
  import UiDrawerContent from '@/uikit/components/UiDrawerContent.vue';
  import UiDropdownButton from '@/uikit/components/UiDropdown/UiDropdownButton.vue';

  import { createLogger } from '@/uikit/util/logger.js';
  const logger = createLogger('UiExpansionPanel', 'orange');

  export default {
    name: 'ui-expansion-panel',
    components: { UiDrawer, UiDrawerContent, UiDropdownButton },
    inject: { panels: { default: undefined } },

    props: {
      value: { type: Boolean, default: false },
    },

    data() {
      return {
        id: null,
        isOpen: this.value,
      };
    },

    computed: {
      classes() {
        return {
          'ui-expansion-panel--active': this.isOpen,
        };
      },
    },

    watch: {
      value(newValue) {
        this.isOpen = newValue;
      },

      isOpen(newValue) {
        this.$emit('input', newValue);
      },
    },

    created() {
      this.id = this.panels ? this.panels.registerPanel(this) : undefined;
    },

    beforeDestroy() {
      if (this.panels) this.panels.unregisterPanel(this);
    },

    methods: {
      toggle() {
        this.isOpen = !this.isOpen();
        if (this.panels) this.panels.toggle(this.id);
      },

      open() {
        this.isOpen = true;
        if (this.panels) this.panels.open(this.id);
      },

      close() {
        this.isOpen = false;
        if (this.panels) this.panels.close(this.id);
      },
    },
  };
</script>
