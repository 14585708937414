class SearchFilter {
  constructor({ name, type, label, options, value }) {
    this.name = name;
    this.type = type;
    this.options = options;
    this.label = label;
    this.setValue(value);
  }

  setValue(value) {
    if (!value && value !== 0) {
      this.value = undefined;
    }
    // проставляем значение только из доступных
    if (this.options.find((opt) => opt.key === value)) this.value = value;
  }

  getValue() {
    return this.value;
  }

  getHumanValue() {
    if (!this.isEmpty()) {
      return this.getLabelFor(this.value);
    } else {
      return '';
    }
  }

  getLabelFor(value) {
    if (value !== undefined && this.options && this.options.length) {
      const option = this.options.find((opt) => opt.key === value);
      return option ? option.label : value;
    } else return undefined;
  }

  reset() {
    this.value = undefined;
  }

  isEmpty() {
    const isEmpty = Boolean(this.value === undefined) || Boolean(this.value === 'any');
    return isEmpty;
  }

  hasTooManyOptions() {
    return this.options.length > 10;
  }

  toCriteria() {
    return { field: this.name, value: this.value };
  }

  setValueFromCriteria(criteria) {
    this.setValue(criteria ? criteria.value : undefined);
  }

  toString() {
    return `${this.name}: ${this.getValue()}`;
  }

  static build(filterData) {
    if (!filterData) throw 'Нет данных о фильтре';

    let filterInstance = undefined;

    switch (filterData.type) {
      // одно значение
      case 'sort':
      case 'switch':
      case 'radio':
      case 'inline-radio':
      case 'inline-radio-chip':
        filterInstance = new SearchFilter(filterData);
        break;

      // несколько значений
      case 'checkbox':
      case 'inline-checkbox':
      case 'inline-checkbox-chip':
        filterInstance = new SearchFilterMultiple(filterData);
        break;

      // диапазон значений
      case 'range':
        filterInstance = new SearchFilterRange(filterData);
        break;

      case 'suggestion':
        filterInstance = new SearchFilterSuggestion(filterData);
        break;

      default:
        throw `Неподдерживаемый тип фильтра', ${filterData.type}`;
    }
    return filterInstance;
  }

  static getHumanValue(filterData, value) {
    const sf = SearchFilter.build(filterData);
    if (sf) {
      sf.setValue(value);
      return sf.getHumanValue();
    }
  }
}

class SearchFilterMultiple extends SearchFilter {
  setValue(val) {
    if (Array.isArray(val)) {
      this.value = val.length > 0 ? val : undefined;
    } else {
      this.value = undefined;
    }
  }

  addValue(value) {
    if (this.value.includes(value)) return;
    this.value.push(value);
  }

  removeValue(value) {
    this.value = this.value.filter((v) => v != value);
  }

  getHumanValue() {
    if (!this.isEmpty()) {
      let humanValue = this.value.map((value) => this.getLabelFor(value)).join(', ');

      // FIX: для варианта "Нет" в фильрах-массивах
      if (
        this.value[0] === 'no' &&
        (this.name === 'execution_procedure' || this.name === 'bankruptcy')
      ) {
        humanValue = 'Нет';
      }
      return humanValue;
    } else {
      return '';
    }
  }

  reset() {
    this.value = undefined;
  }

  isEmpty() {
    const isEmpty =
      Boolean(this.value === undefined) ||
      Boolean(Array.isArray(this.value) && this.value.length === 0) ||
      Boolean(Array.isArray(this.value) && this.value.length === 1 && this.value[0] === 'any');
    return isEmpty;
  }

  toCriteria() {
    return !this.isEmpty() ? { field: this.name, values: this.value } : undefined;
  }

  setValueFromCriteria(criteria) {
    this.setValue(criteria ? criteria.values : undefined);
  }

  toString() {
    if (!this.isEmpty()) {
      return `${this.name}: [${this.getValue()}]`;
    } else return '';
  }
}
class SearchFilterSuggestion extends SearchFilterMultiple {
  getHumanValue() {
    if (!this.isEmpty()) {
      return this.value.join(', ');
    } else {
      return '';
    }
  }

  hasTooManyOptions() {
    return false;
  }
}

class SearchFilterRange extends SearchFilter {
  constructor(filterData) {
    super(filterData);
    this.measure = filterData.measure;
  }

  setValue(value) {
    if (
      !value ||
      value.min > value.max ||
      value.min >= this.options.max ||
      value.max <= this.options.min
    ) {
      this.value = undefined;
    } else {
      // #4219 приводим range в допустимые границы
      const min = Math.max(this.options.min, value.min);
      const max = Math.min(this.options.max, value.max);
      this.value = { min, max };
    }
  }

  getHumanValue() {
    if (!this.isEmpty()) {
      return `${this.value.min} — ${this.value.max} ${this.measure ? this.measure : ''}`;
    } else {
      return '';
    }
  }

  getRangeHumanValue() {
    return `${this.options.min} — ${this.options.max} ${this.measure ? this.measure : ''}`;
  }

  reset() {
    this.value = undefined;
  }

  isEmpty() {
    return Boolean(this.value === undefined);
  }

  hasTooManyOptions() {
    return false;
  }

  toCriteria() {
    return !this.isEmpty()
      ? { field: this.name, min: this.value.min, max: this.value.max }
      : undefined;
  }

  setValueFromCriteria(criteria) {
    this.setValue(criteria ? { min: criteria.min, max: criteria.max } : undefined);
  }

  toString() {
    if (!this.isEmpty()) {
      return `${this.name}: {${this.getValue().min}, ${this.getValue().max}}`;
    } else return '';
  }
}

export { SearchFilter, SearchFilterMultiple, SearchFilterRange, SearchFilterSuggestion };
