<template lang="pug">
.ui-overflow-fader(:class="classes")
  .ui-overflow-fader__content(ref="content" :style="styles")
    slot
  .ui-overflow-fader__btn(v-if="hasOverflow")
    ui-btn(third :width="btnWidth" @click="hasOverflow = false") {{ btnText }}
</template>

<script>
  import UiBtn from '@/uikit/components/UiBtn';
  import { convertToUnit } from '@/uikit/util';

  export default {
    name: 'ui-overflow-fader',
    components: { UiBtn },

    props: {
      maxHeight: { type: [Number, String], default: undefined },
      btnText: { type: String, default: 'Ещё' },
      btnWidth: { type: String, default: undefined },
    },

    data() {
      return {
        hasOverflow: false,
      };
    },

    computed: {
      classes() {
        return {
          'ui-overflow-fader--expanded': !this.hasOverflow,
        };
      },

      styles() {
        const maxHeight = this.hasOverflow ? convertToUnit(this.maxHeight) : undefined;
        return {
          maxHeight: maxHeight,
        };
      },
    },

    mounted() {
      this.hasOverflow = this.$refs.content.clientHeight >= this.maxHeight;
    },
  };
</script>

<style lang="scss">
  .ui-overflow-fader {
  }

  .ui-overflow-fader__content {
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 64px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }
  }

  .ui-overflow-fader.ui-overflow-fader--expanded {
    .ui-overflow-fader__content::after {
      display: none;
    }
  }

  .ui-overflow-fader__btn {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
    margin-top: 8px;
  }
</style>
