// import { service as API } from '@/services/interactions';
import { SimpleApi as API } from '@/libs/api/lib/simpleApi';

export default {
  namespaced: true,
  state: () => ({
    dialogs: [],
    total: 0,
    one: {},
    files: [],
    assetPath: '',
    extensionsImages: ['jpg', 'jpeg', 'png', 'bmp', 'tiff', 'heic'],
    extensionsFiles: [
      'pdf',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'txt',
      'zip',
      'tar',
      'gz',
      'tar-gz',
      'tgz',
      '7z',
      'rar',
      'mp3',
      'mp4',
      'xml',
    ],
    docErrors: [],
    showDocErrors: false,
  }),
  mutations: {
    // s: state, p: { field: string, value: any }
    setValue(s, p) {
      s[p.field] = p.value;
    },
    // s: state, p: { dialogs: [], total: number}
    setDialogs(s, p) {
      s.dialogs = p.dialogs;
      s.total = p.total;
    },
    // s: state, p: any
    setSingleDialog(s, p) {
      s.one = Object.assign(
        {},
        {
          asset: Object.assign({}, p.asset),
          contact: Object.assign({}, p.contact),
          number: p.number,
          messages: [
            ...p.messages.map((m) => ({
              body: m.body,
              created_at: m.created_at,
              my: m.my,
              viewed: m.viewed,
              lead: Object.assign({}, m.lead),
              documents: [
                ...m.documents.map((d, i) => {
                  let fileType = '';
                  if (s.extensionsImages.indexOf(d.extension) > -1) {
                    fileType = 'image';
                  }
                  if (s.extensionsFiles.indexOf(d.extension) > -1) {
                    fileType = 'file';
                  }
                  return Object.assign({}, d, {
                    frontId: 'received-' + i,
                    type: fileType,
                  });
                }),
              ],
            })),
          ],
        }
      );
    },
    // s: state, p: { body: string, created_at: string, documents: [], lead: any, my: boolean, viewed: boolean }
    addMessage(s, p) {
      if (Array.isArray(s.one.messages)) {
        s.one.messages.push({
          body: p.body,
          created_at: p.created_at,
          documents: [
            ...p.documents.map((d, i) => {
              let fileType = '';
              if (s.extensionsImages.indexOf(d.extension) > -1) {
                fileType = 'image';
              }
              if (s.extensionsFiles.indexOf(d.extension) > -1) {
                fileType = 'file';
              }
              return Object.assign({}, d, {
                frontId: 'received-' + i,
                type: fileType,
              });
            }),
          ],
          lead: Object.assign({}, p.lead),
          my: p.my,
          viewed: p.viewed,
        });
      }
    },
    // s: state, p: []
    setFiles(s, p) {
      s.files.push(...p);
    },
    // s: state, frontId: string
    deleteFile(s, frontId) {
      const index = s.files.findIndex((f) => f.frontId == frontId);
      s.files.splice(index, 1);
    },
    // s: state
    deleteAllFiles(s) {
      s.files = [];
    },
  },
  actions: {
    // context
    async searchInteractions({ commit }) {
      const response = await API.post('/api/v2/interactions/search.json', null);

      if (response.ok && Array.isArray(response.data.interactions)) {
        commit('setDialogs', {
          dialogs: response.data.interactions,
          total: response.data.total || 0,
        });
      }
      return new Promise((res) => {
        res({ ok: response.ok });
      });
    },
    // context, id: number
    async requestDialog({ commit }, id) {
      const response = await API.get('/api/v2/interactions/' + id + '.json', null);

      if (response.ok && response.data.data) {
        commit('setSingleDialog', response.data.data);
      }
      return new Promise((res) => {
        res({ ok: response.ok && !!response.data.data });
      });
    },
    // context, p: {id: number, body: string}
    async sendMessage({ state: { files }, commit }, p) {
      commit('setValue', { field: 'docErrors', value: [] }); // удаляем предыдущие ошибки
      const formData = new FormData();
      formData.append('message[body]', p.body);
      (files || []).forEach((file) => {
        formData.append('message[documents][][file]', file.raw);
      });
      const response = await API.post('/api/v2/interactions/' + p.id + '/messages.json', formData, {
        timeout: 300000, // 5 мин, из-за возможной отправки прикреплённых файлов
      });

      if (response.ok && response.data.created_at) {
        commit('deleteAllFiles'); // удаляем успешно отправленные файлы
        commit('addMessage', response.data);
      }
      const docErrors = [
        ...new Set(
          (response.data?.documents || [])
            .map((doc) => doc.file && doc.file.error)
            .filter((error) => error)
        ),
      ];
      if (docErrors.length) {
        commit('setValue', { field: 'docErrors', value: docErrors });
        commit('setValue', { field: 'showDocErrors', value: true });
      }

      return new Promise((res) => {
        res({ ok: response.ok && !!response.data.created_at });
      });
    },
  },
};
