/* Dependencies ============================================================= */
import { createLogger } from '@/uikit/util/logger';
const logger = createLogger('Notifications', 'blue');

/* State===================================================================== */
const state = () => ({
  initialized: false,
  message: '',
  type: 'info', // info / success / error
  delay: 3000,
});

/* Getters ================================================================== */

const getters = {
  get: (state) => {
    return { message: state.message, type: state.type, delay: state.delay };
  },
};

/* Mutations ================================================================ */

const mutations = {
  ADD_NOTIFICATION: (state, { message, type, delay }) => {
    state.message = message;
    state.delay = delay;
    state.type = type;
  },
};

/* Actions ================================================================== */

const actions = {
  initialize: () => {
    logger.log('Инициализация');
  },

  notify: ({ commit }, { message, type, delay }) => {
    commit('ADD_NOTIFICATION', { message, delay, type });
  },
};

/* Exports ================================================================== */

export default {
  state,
  getters,
  actions,
  mutations,
};
