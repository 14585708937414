class Style {
  constructor(bg, color) {
    this.bg = bg;
    this.color = color;
  }

  toCSS() {
    return `background-color: ${this.bg}; color: ${this.color}; padding: 1px 4px;`;
  }
}
class StyledText {
  constructor(text, style) {
    this.text = text;
    this.style = style;
  }

  getStyle() {
    return this.style;
  }

  setStyle(style) {
    this.style = style;
  }

  toArray() {
    return this.style ? [`%c${this.text}`, this.style.toCSS()] : [this.text];
  }
}

const createStyle = (bg, color) => {
  return (text) => {
    return new StyledText(text, new Style(bg, color));
  };
};

const SCHEMES = [
  { name: 'cyan', bg: '#0ABCBA', text: '#fff' },
  { name: 'blue', bg: '#1E88E5', text: '#fff' },
  { name: 'brown', bg: '#6D4C41', text: '#fff' },
  { name: 'gray', bg: '#E7E7E7', text: '#000' },
  { name: 'grayer', bg: '#CCCCCC', text: '#000' },
  { name: 'green', bg: '#B9D888', text: '#2C371A' },
  { name: 'red', bg: '#E53935', text: '#fff' },
  { name: 'orange', bg: '#F4511E', text: '#fff' },
  { name: 'purple', bg: '#8E24AA', text: '#fff' },
  { name: 'yellow', bg: '#FFD600', text: '#000' },
];

const STYLES = SCHEMES.reduce((styles, scheme) => {
  styles[scheme.name] = new Style(scheme.bg, scheme.text);
  return styles;
}, {});

export const style = SCHEMES.reduce((styles, scheme) => {
  styles[scheme.name] = createStyle(scheme.bg, scheme.text);
  return styles;
}, {});

export class Logger {
  constructor(console, name, styleNameOrObject) {
    this.name = name || '';
    this.style =
      styleNameOrObject instanceof Style
        ? styleNameOrObject
        : STYLES[styleNameOrObject]
        ? STYLES[styleNameOrObject]
        : null;
    this.console = console ? console : {};
  }

  isEnabled() {
    try {
      const debugEnabled = window.debug || false;
      const forceDebug = localStorage ? localStorage.getItem('debug') : false;
      return debugEnabled || forceDebug;
    } catch (error) {
      this.console.error && this.console.error('Невозможно подключить логгер', error);
      return false;
    }
  }

  log() {
    if (!this.isEnabled()) return;

    const args = [].slice.call(arguments);
    const prefix = this.getPrefix();
    if (prefix) args.unshift(prefix);
    this.console.log && this.console.log.apply(this.console, this._expandArgs(args));
  }

  error(errorOrText) {
    if (!this.isEnabled()) return;

    const prefix = this.getPrefix();

    if (errorOrText.message) {
      let titleArgs = [];

      let title = errorOrText.message;
      if (errorOrText.data && errorOrText.data.message) {
        title += `: ${errorOrText.data.message}`;
      }

      if (prefix) {
        prefix.setStyle(STYLES.red);
        titleArgs.push(prefix);
        titleArgs.push(title);
      } else {
        titleArgs.push(style.red(title));
      }

      if (this.console.error) {
        this.console.groupCollapsed.apply(this.console, this._expandArgs(titleArgs));
        this.console.log.apply(this.console, this._expandArgs(arguments));
        this.console.trace();
        this.console.groupEnd();
      }
    } else {
      this.console.error && this.console.error.apply(this.console, this._expandArgs(arguments));
    }
  }

  getPrefix() {
    if (this.name) {
      return new StyledText(`${this.name}`, this.style);
    } else return '';
  }

  _expandArgs(args) {
    let expandedArgs = [];

    [].slice.call(args).forEach((arg) => {
      if (arg instanceof StyledText) {
        expandedArgs.push(...arg.toArray());
      } else {
        expandedArgs.push(arg);
      }
    });

    return expandedArgs;
  }
}

export const createLogger = (name, style) => {
  return new Logger(window.console, name, style);
};

export const logger = new Logger(window.console);
