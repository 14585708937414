<template lang="pug">
.ui-map
  transition(name="fade")
    .ui-map__skeleton(v-if="isMapHidden")
      ui-loader(preloader)
      .ui-map__error(v-if="hasError") Ошибка инициализации карты
  .ui-map__container(:class="{ 'ui-map__container--hidden': isMapHidden }" ref="map_container")
</template>

<script>
  import { UiLoader } from '../';
  import ymaps from 'ymaps';
  import { initMarkers } from './markers';
  import { logger } from '../../util/logger';

  const MAP_DEFAULT_CENTER = [55.753994, 37.622093]; // Порядок по умолчанию: «широта, долгота». Это Москва
  const MAP_DEFAULT_ZOOM = 10; // от 0 (весь мир) до 19.

  // Yandex Maps API namespace
  let yMaps = null;

  export default {
    name: 'ui-yandex-map',
    components: { UiLoader },

    provide() {
      return {
        ymaps: this.getYMaps,
        getMap: this.getMap,
      };
    },

    props: {
      center: { type: Array, default: () => MAP_DEFAULT_CENTER },
      zoom: { type: Number, default: () => MAP_DEFAULT_ZOOM },
      hideOnResize: { type: Boolean, default: false },
      loading: { type: Boolean, default: false },
    },

    data() {
      return {
        isMapInitialized: false,
        isResizing: false,
        isLoading: this.loading,
        isShowing: true,
        mapInstance: null,
        hasError: false,
        mapState: {
          center: this.center,
          zoom: this.zoom,
          controls: [],
        },
        mapOptions: {
          suppressMapOpenBlock: true,
          minZoom: 3,
          maxZoom: 17,
          yandexMapAutoSwitch: false,
        },
        settings: {
          apiKey: '72d90556-11a1-4f58-9ea9-31812eb6cfba',
          lang: 'ru_RU',
          coordorder: 'latlong',
          version: '2.1',
        },
      };
    },

    computed: {
      isMapHidden() {
        const hidden =
          !this.isMapInitialized ||
          (this.hideOnResize && this.isResizing) ||
          this.isLoading ||
          !this.isShowing;
        return hidden;
      },
    },

    watch: {
      loading(isLoading) {
        this.setLoading(isLoading);
      },
    },

    mounted() {
      this.$nextTick(() => {
        this.initYMaps();
      });
    },

    beforeDestroy() {
      this.destroyMap();
    },

    methods: {
      async initYMaps() {
        try {
          yMaps = await ymaps.load(yandexMapScriptLink(this.settings));
          initMarkers(yMaps);
        } catch (error) {
          logger.error(error);
          this.hasError = true;
        }
      },

      async getYMaps() {
        if (!yMaps) await this.initYMaps();
        return yMaps;
      },

      async getMap(state, options) {
        if (this.mapInstance) return this.mapInstance;

        state = { ...this.mapState, ...state };
        options = { ...this.mapOptions, ...options };

        const maps = await this.getYMaps();
        // logger.log('object :>> ', object);
        this.mapInstance = new maps.Map(this.$refs['map_container'], state, options);
        this.mapInstance.events.add('boundschange', this.onBoundsChange);

        this.isMapInitialized = true;
        return this.mapInstance;
      },

      destroyMap() {
        if (this.mapInstance) {
          this.mapInstance.destroy();
          this.isMapInitialized = false;
        }
      },

      async onBoundsChange(mapEvent) {
        const viewport = {
          bounds: mapEvent.get('newBounds'),
          zoom: mapEvent.get('newZoom'),
          oldBounds: mapEvent.get('oldBounds'),
          oldZoom: mapEvent.get('oldZoom'),
        };

        if (viewport.zoom != viewport.oldZoom) {
          this.$emit('update:zoom', viewport.zoom);
          this.$emit('change:zoom', viewport);
        }

        if (viewport.bounds != viewport.oldBounds) {
          this.$emit('update:bounds', viewport.bounds);
          this.$emit('change:bounds', viewport);
        }

        this.$emit('map:boundschange', viewport, mapEvent);
      },

      onResize() {
        this.isResizing = true;
      },

      matchMapSize() {
        this.isResizing = false;

        if (this.mapInstance) {
          this.mapInstance.container.fitToViewport(true);
        }
      },

      setLoading(isLoading) {
        this.isLoading = isLoading;
      },
    },
  };

  function yandexMapScriptLink(settings) {
    const {
      apiKey = '',
      lang = 'ru_RU',
      version = '2.1',
      coordorder = 'latlong',
      debug = false,
    } = settings;
    const mode = debug ? 'debug' : 'release';
    const settingsPart = `lang=${lang}${
      apiKey && `&apikey=${apiKey}`
    }&mode=${mode}&coordorder=${coordorder}`;
    return `//api-maps.yandex.ru/${version}/?${settingsPart}`;
  }
</script>

<style lang="scss">
  .cluster {
    position: absolute;
    top: -11px;
    left: -11px;
    width: 22px;
    height: 22px;
    transition: opacity 1s;
    border: 2px solid #fff;
    border-radius: 9999px;
    background: #007aff;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
    color: #fff;
    font-size: 10px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;

    &--large {
      top: -14px;
      left: -14px;
      width: 28px;
      height: 28px;
      line-height: 28px;
    }
  }

  .marker {
    position: absolute;
    top: -8px;
    left: -8px;
    width: 16px;
    height: 16px;
    transition: opacity 1s;
    border: 2px solid #fff;
    border-radius: 9999px;
    background: #007aff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    color: #fff;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;

    .marker__content {
      display: flex;
      top: 0;
      left: 8px;
      line-height: 22px;
      font-size: 11px;
      font-weight: bold;
      padding-left: 4px;
      padding-right: 4px;
      color: #52575c;
      white-space: nowrap;
    }

    &--large {
      top: -14px;
      left: -14px;
      width: 28px;
      height: 28px;
      line-height: 28px;
    }

    &--red {
      background: #f44336;
      box-shadow: 0 16px 32px rgba(0, 0, 0, 0.24);
    }

    .marker-wrapper {
      position: absolute;
      top: -8px;
      left: -8px;
      display: flex;
      line-height: 22px;
      background-color: #fff;
      color: #52575c;
      white-space: nowrap;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);

      .marker {
        top: 0;
        left: 0;
      }
    }
  }

  .hidden ymaps {
    transition: opacity 1s;
  }

  .hidden ymaps {
    transition: opacity 1s;
    opacity: 0;
  }
</style>
