<script>
  import './UiIcon.scss';

  export default {
    name: 'ui-icon',
    functional: true,

    props: {
      name: { type: String, required: true },
      left: { type: Boolean, default: false },
      right: { type: Boolean, default: false },
      size: { type: String, default: '' },
      nudgeTop: { type: String, default: '' },
      fit: { type: Boolean, default: false },
      color: { type: String, default: undefined },
      originalColor: { type: Boolean, default: false },
    },

    render(h, ctx) {
      const classes = ['icon', ctx.data.staticClass, ctx.data.class];

      if (ctx.props.originalColor) classes.push(`icon-${ctx.props.name}--colored`);
      else classes.push(`icon-${ctx.props.name}`);

      if (!ctx.props.size && !ctx.props.fit) classes.push('icon-24');
      if (ctx.props.fit) classes.push('icon--fit');
      if (ctx.props.left) classes.push('icon--on-left');
      if (ctx.props.right) classes.push('icon--on-right');

      const size = ctx.props.size ? `${ctx.props.size}px` : null;
      const nudgeTop = ctx.props.nudgeTop ? `${ctx.props.nudgeTop}px` : null;
      const styles = {
        width: size,
        height: size,
        position: nudgeTop ? 'relative' : null,
        top: nudgeTop,
        backgroundColor: ctx.props.color,
      };
      return h('div', { ...ctx.data, class: classes, style: styles });
    },
  };
</script>
