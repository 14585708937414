<script>
  import './UiSidePanel.scss';
  export default {
    name: 'ui-side-panel-header',
    functional: true,

    props: {
      sticky: { type: Boolean, default: false },
    },

    render(h, { props, data = {}, children }) {
      const classes = {
        'ui-side-panel__header': true,
        'ui-side-panel__header--sticky': props.sticky,
        'ui-side-panel__header--padless': props.padless,
      };

      return h('div', { ...data, class: classes }, children);
    },
  };
</script>
