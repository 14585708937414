<template lang="pug">
.ui-swipe
  .ui-swipe__wrapper
    slot
</template>

<script>
  import Swipe from 'swipejs';

  export default {
    props: {
      value: { type: Number, default: 0 },
      draggable: { type: Boolean, default: false },
      continuous: { type: Boolean, default: false },
      scrollable: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
    },

    data() {
      return {
        currentSlide: this.value,
      };
    },

    watch: {
      disabled(flag) {
        this.enable(flag);
      },
    },

    mounted() {
      this.swipe = new Swipe(this.$el, {
        startSlide: this.currentSlide,
        draggable: this.draggable,
        continuous: this.continuous,
        disableScroll: !this.scrollable,
        stopPropagation: true,
        callback: this.onSlideChange,
        // transitionEnd: this.onSlideChange,
      });

      this.enable(this.disabled);
    },

    beforeDestroy() {
      this.swipe.kill();
    },

    methods: {
      onSlideChange(index) {
        this.currentSlide = index;
        this.$emit('input', index);
      },

      enable(flag) {
        if (flag) {
          this.swipe.disable();
        } else {
          this.swipe.enable();
        }
      },
    },
  };
</script>

<style lang="scss">
  .ui-swipe {
    overflow: hidden;
    visibility: hidden;
    position: relative;
  }

  .ui-swipe__wrapper {
    overflow: hidden;
    position: relative;
  }

  .ui-swipe__wrapper > * {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
</style>
