<template lang="pug">
v-input.ui-placeholder-field(
  :class="classes"
  v-on="listeners"
  v-bind="$attrs"
  ref="vinput"
  :style="styles"
)
  template(v-if="!!valueModel")
    slot(name="item" :item="valueModel")
    .ui-placeholder-field__clearable(v-if="clearable")
      ui-icon.ui-clickable(name="adm-close" @click.stop="$emit('click:clear')")

  template(v-else)
    slot(name="empty" :item="valueModel")
      button.ui-placeholder-field__placeholder-button(
        @click="$emit('click:select')"
        @blur="onBlur"
        @focus="onFocus"
      )
        .ui-placeholder-field__placeholder-icon(v-if="placeholderIcon")
          ui-icon(size=32 :name="placeholderIcon")
        .ui-placeholder-field__placeholder-text.text--small.c-text--dimmed(
          v-if="$attrs.placeholder"
        ) {{ $attrs.placeholder }}
</template>

<script>
  import { UiIcon } from '../';
  import { measurable } from '../../mixins/measurable';
  import { themeable } from '../../mixins/themeable';

  export default {
    name: 'ui-placeholder-field',
    components: { UiIcon },
    mixins: [measurable, themeable],
    inheritAttrs: false,

    props: {
      value: { type: Object, default: undefined },
      placeholderIcon: { type: String, default: undefined },
      clearable: { type: Boolean, default: false },
    },

    computed: {
      valueModel: {
        set(value) {
          this.$emit('input', value);
        },
        get() {
          return this.value;
        },
      },

      listeners() {
        return this.$listeners;
      },

      classes() {
        return {
          'ui-placeholder-field--empty': !this.valueModel,
          'ui-placeholder-field--has-value': !!this.valueModel,
          ...this.themeClasses,
        };
      },

      styles() {
        return {
          ...this.measurableStyles,
        };
      },

      input() {
        return this.$refs.vinput;
      },
    },

    methods: {
      onFocus() {
        this.input.isFocused = true;
      },

      onBlur() {
        this.input.isFocused = false;
      },
    },
  };
</script>

<style lang="scss">
  .ui-placeholder-field {
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;

    > .v-input__control .v-input__slot {
      position: relative;
      flex-grow: 1;
      border-radius: 8px;
    }

    &.v-input--is-focused > .v-input__control .v-input__slot {
      border: 1px solid c('white32', 'dark');
    }
  }

  .ui-placeholder-field__clearable {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  .ui-placeholder-field--empty {
  }

  .ui-placeholder-field--has-value {
    > .v-input__control .v-input__slot {
      border: 1px solid c('white12', 'dark');
      border-radius: 8px;
    }
  }

  .ui-placeholder-field__placeholder-button {
    width: 100%;
    height: 100%;
    padding: 8px;
    border-radius: 8px;
    background-color: c('bright2', 'dark');

    .ui-placeholder-field__placeholder-icon {
      color: c('white12', 'dark');
    }

    &:hover {
      background-color: c('bright3', 'dark');
      box-shadow: shadow('middle', 'dark');

      .ui-placeholder-field__placeholder-icon {
        color: c('white32', 'dark');
      }
    }
  }
</style>
