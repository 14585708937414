import AppNotification from '@/components/app/AppNotification.vue';
import notificationModule from '@/store/notification';

function createElement() {
  const el = Object.assign(document.createElement('div'));
  document.body.appendChild(el);
  return el;
}

let appNotificationToast = undefined;

export default {
  name: 'Нотификации',

  registerStoreModule(store) {
    if (!store.hasModule('notification')) {
      store.registerModule('notification', { namespaced: true, ...notificationModule });
    }
  },

  install(Vue, { store, vuetify }) {
    this.registerStoreModule(store);
    const el = createElement();
    const Component = Vue.extend(AppNotification);
    const instance = new Component({
      store,
      vuetify,
    }).$mount(el);

    appNotificationToast = instance;
    Vue.prototype.$notify = instance;
    window.$notify = instance;
  },
};

export { appNotificationToast };
