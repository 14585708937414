const path = {
  home: () => '/',
  myFavorites: () => '/objects/favorites',
  myAuctions: () => '/objects/my_trades',
  catalog: (kind = '', purpose = 'buy') =>
    kind ? `/objects/catalog/${purpose}/${kind}` : `/objects/catalog/${purpose}`,
  asset: (id) => `/objects/${id}`,
  assetTradeApplications: (assetId, tradeId) =>
    `/objects/${assetId}/trade/${tradeId}/trade_applications`,
  myAssets: () => `${process.env.VUE_APP_PHOENIX_BASE_URL}/profile/my-assets`,
  myAssets: () => `${process.env.VUE_APP_PHOENIX_BASE_URL}/profile/dashboard`,
  newAsset: () => '/new-asset',
  signIn: () => '/auth/sign_in',
  signUp: () => '/auth/sign_up',
  signUpIndividual: () => '/auth/sign_up/individual',
  signUpLegal: () => '/auth/sign_up/legal',
  createPRO: () => '/create_pro',
  searchFilters: () => '/i/searchFilters',
  blog: () => '/blog',
  blogArticle: (id) => `/article/${id}`,
  faq: () => '/faq',
  splash: () => '/splash',
  sbbol: () => '/enter/sbbol',
  faqSection: (id) => `/faq/${id}`,
  about: () => '/about',
  interactions: () => '/interactions',
  interactionDialog: (id) => `/interactions/${id}`,
  notifications: () => '/i/notifications',
  profile: () => '/profile',
  getAppApple: () => 'https://apps.apple.com/ru/app/portal-da/id1463395501',
  getAppRustore: () => 'https://apps.rustore.ru/app/ru.activebc.portal.da',
  services: () => '/services',
  service: (name, assetId = undefined) =>
    assetId ? `/services/${name}?asset_id=${assetId}` : `/services/${name}`,
  toPhoenix: () => `${process.env.VUE_APP_PHOENIX_BASE_URL}`,
  creditServiceApplication: (assetId = undefined) =>
    assetId ? `/service_applications/credit/${assetId}/new` : '/i/404',
  creditDrpaServiceApplication: (assetId = undefined) =>
    assetId ? `/service_applications/credit_drpa/${assetId}/new` : '/i/404',
};

function install(Vue) {
  Vue.prototype.$path = path;
}

export { install as PathPlugin, path };
export default install;
