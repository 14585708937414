import { service as API } from '@/services/auth';
import { createLogger } from '@/uikit/util';
import { service as authService } from '@/services/auth/auth-service';
import store from '@/store';
const logger = createLogger('sessionAuthStorage', 'green');

const TOKEN = 'ColibriUserToken';
const REFRESH_TOKEN = 'ColibriRefreshToken';
const USER = 'ColibriUser';

async function getUser() {
  try {
    const fetchUser = await API.getUserInfo();
    localStorage.setItem(USER, fetchUser ? JSON.stringify(fetchUser) : undefined);
  } catch (error) {
    console.log('error', error);
  }
}
 
async function refreshTheToken(currentRefreshToken) {
  try {
    const { token, refreshToken } = await authService.refreshToken(currentRefreshToken);
    if (token && refreshToken) {
      if (logger) logger.log('Токен обновлён');

      store.dispatch('user/updateTokens', token, refreshToken);

      localStorage.setItem(TOKEN, token);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);

      $cookies.set(
        'HouseRefreshToken', // cookie name
        refreshToken, // value
        '60d', // expired
        null, // path
        process.env.VUE_APP_PHOENIX_COOKIE_DOMAIN || '.portal-da.ru' // domain
      );

      return { token, refreshToken }
    }
  } catch (error) {
    console.log('Не удалось обновить токен');
    throw 'Не удалось обновить токен';
  }
}

export function saveSessionAuth(token = undefined, refreshToken = undefined, user = undefined) {
  try {
    // ставим куку для Phoenix
    if ($cookies.get('HouseRefreshToken') === null) {
      $cookies.set(
        'HouseRefreshToken', // cookie name
        refreshToken, // value
        '60d', // expired
        null, // path
        process.env.VUE_APP_PHOENIX_COOKIE_DOMAIN || '.portal-da.ru' // domain
      );

      // сохраняем полученный токен в localStorage
      localStorage.setItem(TOKEN, token);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      localStorage.setItem(USER, user ? JSON.stringify(user) : undefined);
    } else {
      localStorage.setItem(TOKEN, token);
      localStorage.setItem(REFRESH_TOKEN, $cookies.get('HouseRefreshToken'));
      getUser();
    }

    logger.log('Сохраняем auth', user, token, refreshToken);
  } catch (error) {
    logger.error(error);
  }
}

export async function getSessionAuth() {
  try {
    if ($cookies.get('HouseRefreshToken') !== null) {
      const { token, refreshToken } = await refreshTheToken($cookies.get('HouseRefreshToken'));

      await getUser();

      const user = localStorage.getItem(USER) ? JSON.parse(localStorage.getItem(USER)) : undefined;

      logger.log('Вытаскиваем сохраненный auth', user, token, refreshToken);

      return { token, refreshToken, user: user };
    } else {
      return
    }
  } catch (error) {
    logger.error(error);
  }
}

export function removeSessionAuth() {
  try {
    logger.log('Очищаем сохраненный auth');
    // удаляем куку для феникса
    if ($cookies.get('HouseToken') || $cookies.get('HouseRefreshToken')) {
      $cookies.remove('HouseToken');
      $cookies.remove('HouseRefreshToken');
    }
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(USER);
  } catch (error) {
    logger.error(error);
  }
}
