<template lang="pug">
.ui-file-attachments-list(:class="classes" :style="styles")
  .ui-file-attachments-list__items(v-if="valueModel.length")
    template(v-for="(item, index) in valueModel")
      slot(name="item" :index="index" :item="item")
        ui-file-info-item.ui-file-attachments-list__item(
          :class="itemClass"
          :key="'attachment' + index"
          :name="item.file.name"
          :outlined="itemOutlined"
          :size="item.file.size"
        )
          template(#actions)
            slot(name="item-actions" :index="index" :item="item")
              ui-btn(plain @click="removeAttachment(index)")
                ui-icon(name="adm-trash")

  .ui-file-attachments-list__expirable(v-if="expirable && valueModel.length")
    .text--small.c-text--dimmed.mb-1 Время жизни файлов, дн.
    .d-flex.align-center
      .flex-grow-0
        v-btn-toggle.ui-file-attachments-list__toggler(v-model="expireDays" mandatory)
          ui-btn(:value="-1") Не ограничено
          ui-btn(:value="1") 1
          ui-btn(:value="7") 7
          ui-btn(:value="30") 30
          ui-btn(:value="365") 365
      .d-flex.align-center.flex-grow-1.ml-4(v-if="expirationDate")
        ui-icon(name="adm-timer-outline" left)
        div {{ expirationDate | dateFull }}

  form(ref="fileForm")
    input(
      ref="file"
      type="file"
      multiple
      style="display: none"
      @change="addAttachment"
    )
</template>

<script>
  import './UiFileAttachmentsList.scss';

  import { themeable } from '../../mixins/themeable';
  import { measurable } from '../../mixins/measurable';
  import { UiBtn, UiIcon } from '../';
  import UiFileInfoItem from './UiFileInfoItem.vue';

  import { createLogger } from '../../util/logger';
  const logger = createLogger('UiFileAttachmentsList', 'orange');

  // TODO: добавить поддержку dropzone
  // TODO: добавить empty-state
  export default {
    name: 'ui-file-attachments-list',
    components: { UiFileInfoItem, UiBtn, UiIcon },
    mixins: [themeable, measurable],

    props: {
      value: { type: Array, default: () => [] },
      outlined: { type: Boolean, default: false },
      itemOutlined: { type: Boolean, default: false },
      itemClass: { type: String, default: null },
      expirable: { type: Boolean, default: false },
      decorator: { type: Function, default: (item) => item },
    },

    data() {
      return {
        files: [],
        expireDays: null,
      };
    },

    computed: {
      classes() {
        return {
          'ui-file-attachments-list--empty': !this.valueModel.length,
          'ui-file-attachments-list--outlined': this.outlined,
          'ui-file-attachments-list--item-outlined': this.itemOutlined,
          ...this.themeClasses,
        };
      },

      styles() {
        return {
          ...this.measurableStyles,
        };
      },

      valueModel: {
        set(value) {
          this.$emit('input', value);
        },
        get() {
          return this.value;
        },
      },

      expirationDate() {
        if (this.expirable && this.expireDays !== -1) {
          let date = new Date();
          date.setDate(date.getDate() + this.expireDays);
          return date;
        }
        return undefined;
      },
    },

    watch: {
      expirationDate(date) {
        this.updateExpiration(date);
      },
    },

    methods: {
      attach() {
        this.$refs.file.click();
      },

      reset() {
        this.$refs.fileForm.reset();
      },

      addAttachment(event) {
        const files = event.target.files;
        logger.log('Добавить аттачмент', event, files);

        files.forEach((file) => {
          this.valueModel.push(this.buildAttachment(file));
        });

        this.reset();
      },

      buildAttachment(file) {
        let attachment = { file: file };
        if (this.expirationDate) attachment.expire_at = this.expirationDate;
        attachment = this.decorator(attachment);
        return attachment;
      },

      updateExpiration(date) {
        let attachments = [];
        this.valueModel.forEach((attachment) => {
          attachments.push(this.buildAttachment(attachment.file));
        });
        this.valueModel = attachments;
      },

      removeAttachment(index) {
        this.$emit('click:remove', this.valueModel[index], index);
      },
    },
  };
</script>
