<template lang="pug">
.ui-range-field
  .ui-range-field__input
    .ui-range-field__start
      v-text-field(
        v-model="localMinValue"
        type="number"
        :label="labelStart"
        :error-messages="minError ? errorMessage : ''"
      )
        template(#append)
          span.text-truncate.t-small.mt-1(v-html="symbol")
    .ui-range-field__divider
    .ui-range-field__end
      v-text-field(
        v-model="localMaxValue"
        type="number"
        :label="labelEnd"
        reverse
        :error-messages="maxError ? errorMessage : ''"
      )
        template(#prepend-inner)
          span.text-truncate.t-small.ml-1.mt-1(v-html="symbol")
</template>

<script>
  export default {
    name: 'ui-range-field',

    props: {
      symbol: { type: String, default: undefined },
      min: { type: Number, default: undefined },
      max: { type: Number, default: undefined },
      value: { type: Array, default: () => [null, null] },
      label: { type: String, default: undefined },
      labelStart: { type: String, default: 'От' },
      labelEnd: { type: String, default: 'До' },
    },

    data() {
      return {
        minError: false,
        maxError: false,
        localMinValue: this.value[0] || this.min,
        localMaxValue: this.value[1] || this.max,
      };
    },

    computed: {
      errorMessage() {
        return (this.max && this.maxError) || (this.min && this.minError)
          ? `Значение от ${this.min} до ${this.max}`
          : undefined;
      },
    },

    watch: {
      value(val) {
        this.localMinValue = val[0];
        this.localMaxValue = val[1];
      },

      localMinValue(val) {
        if (val >= this.min && val < this.max) {
          this.minError = false;
          this.$emit('input', [Number(val), Number(this.localMaxValue)]);
        } else {
          this.minError = true;
        }
      },

      localMaxValue(val) {
        if (val <= this.max && val > this.min) {
          this.maxError = false;
          this.$emit('input', [Number(this.localMinValue), Number(val)]);
        } else {
          this.maxError = true;
        }
      },
    },

    created() {
      if (!this.value) this.reset();
    },

    methods: {
      reset() {
        this.localMinValue = this.min;
        this.localMaxValue = this.max;
      },
    },
  };
</script>

<style lang="scss">
  .ui-range-field {
  }

  .ui-range-field__input {
    display: flex;
    align-items: center;
  }

  .ui-range-field__start,
  .ui-range-field__end {
    flex-grow: 1;
  }

  .ui-range-field__divider {
    display: block;
    height: 1px;
    width: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 8px;
    border-bottom: 1px solid c('text', 'light');
  }

  .v-messages.ui-range-field__messages {
    color: c('red', 'light') !important;
  }

  .v-text-field--reverse .v-label {
    right: -40px !important;
  }
</style>
