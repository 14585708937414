<template lang="pug">
.ui-drawer-content(v-if="state.isOpen")
  slot(:api="api" :state="state")
</template>

<script>
  export default {
    name: 'ui-drawer-content',
    inject: ['state', 'api'],

    watch: {
      'state.isOpen': {
        handler(active) {
          if (active) {
            window.addEventListener('keyup', this.onEsc);
          } else {
            window.removeEventListener('keyup', this.onEsc);
          }
        },
      },
    },

    methods: {
      onEsc(event) {
        if (event.which === 27) this.api.close();
      },
    },
  };
</script>
