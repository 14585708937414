<template lang="pug">
v-chip-group.ui-chip-group(
  v-on="$listeners"
  v-bind="$attrs"
  dense
  @change="$emit('input', $event)"
)
  slot
</template>

<script>
  import './UiChip.scss';

  export default {
    name: 'ui-chip-group',
    inheritAttrs: false,
  };
</script>

<style lang="scss"></style>
