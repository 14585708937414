import Vue from 'vue';
import Vuex from 'vuex';
import interactions from './interactions'; // модуль для сообщений
import catalog from './catalog'; // модуль для каталога
import categories from './categories'; // модуль для категорий
import user from './user'; // модуль для пользователя

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    faqSections: undefined,
    forcedVersion: undefined,
    desktopUrl: undefined,
  },

  mutations: {
    SET_FAQ_SECTIONS(state, sections) {
      state.faqSections = sections;
    },

    SET_DESKTOP_URL(state, url) {
      state.desktopUrl = url;
    },
  },

  actions: {
    setFaqSections({ commit }, sections) {
      commit('SET_FAQ_SECTIONS', sections);
    },

    setDesktopUrl({ commit }, url) {
      commit('SET_DESKTOP_URL', url);
    },
  },

  getters: {
    currentUser(state) {
      return state.user.currentUser;
    },

    userToken(state) {
      return state.user.token;
    },

    faqSections(state) {
      return state.faqSections;
    },

    desktopUrl(state) {
      const url = state.desktopUrl || '';
      return 'https://portal-da.ru' + url;
    },
  },

  modules: {
    interactions,
    catalog: { namespaced: true, ...catalog },
    categories: { namespaced: true, ...categories },
    user: { namespaced: true, ...user },
  },
});
