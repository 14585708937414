<template lang="pug">
ui-btn.asset-favorite-button.btn-overlay(
  :class="{ 'asset-favorite-button--active': favorite }"
  v-on="$listeners"
  v-bind="$attrs"
  overlay
  @click="checkAuthorize"
)
  ui-icon.c-gray70(:name="favorite ? 'bookmark' : 'bookmark-outline'")
  span.ml-1(v-if="text") {{ text }}
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';
  import { service as API } from '@/services/assets';

  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('AssetFavoriteButton', 'orange');

  export default {
    name: 'asset-favorite-button',
    components: { UiBtn, UiIcon },
    inject: ['requireAuth'],
    inheritAttrs: false,

    props: {
      value: { type: Boolean, default: undefined },
      assetId: { type: String, required: true },
      overlay: { type: Boolean, default: false },
      noText: { type: Boolean, default: false },
      authorizedToToggle: { type: Boolean, default: undefined },
    },

    computed: {
      favorite: {
        set(value) {
          this.$emit('input', value);
        },
        get() {
          return this.value;
        },
      },

      text() {
        if (this.noText) return null;
        return this.favorite ? 'В избранном' : 'В избранное';
      },
    },

    methods: {
      checkAuthorize() {
        if (this.authorizedToToggle) {
          this.toggleFavorite();
        } else {
          this.requireAuth(
            'Чтобы добавить актив в Избранное необходимо войти или зарегистрироваться.'
          );
        }
      },

      async toggleFavorite() {
        try {
          const favorite = await API.toggleFavorite(this.assetId);
          this.favorite = favorite;
          this.$notify.success(
            favorite ? 'Актив добавлен в избранное' : 'Актив убран из избранного'
          );
        } catch (error) {
          logger.error(error);
        }
      },
    },
  };
</script>

<style lang="scss">
  .ui-btn.asset-favorite-button {
    &:hover {
      background: c('white', 'light') !important;
    }

    &.btn-overlay.v-btn.v-btn--has-bg.theme--light.v-size--default {
      background-color: c('white', 'light') !important;
    }

    &.btn-overlay.v-btn.v-btn--has-bg.theme--light.v-size--default.asset-favorite-button--active {
      .icon {
        background-color: c('primary60', 'light') !important;
      }
    }
  }
</style>
