<template lang="pug">
.toast-notifications(@click="isOpen = false")
  ui-toast(v-bind="notification" v-model="isOpen" top centered)
</template>

<script>
  import { UiToast } from '@/uikit';

  const DEFAULT_TIMEOUT = 3000;

  export default {
    name: 'toast-notifications',
    components: { UiToast },

    data() {
      return {
        isOpen: false,
      };
    },

    computed: {
      notification() {
        return this.$store.getters['notification/get'];
      },
    },

    methods: {
      info(message, delay = DEFAULT_TIMEOUT) {
        this.$store.dispatch('notification/notify', { type: 'info', delay, message });
        this.isOpen = true;
      },

      success(message, delay = DEFAULT_TIMEOUT) {
        this.$store.dispatch('notification/notify', { type: 'success', delay, message });
        this.isOpen = true;
      },

      warning(message, delay = DEFAULT_TIMEOUT) {
        this.$store.dispatch('notification/notify', { type: 'warning', delay, message });
        this.isOpen = true;
      },

      error(message, delay = DEFAULT_TIMEOUT) {
        this.$store.dispatch('notification/notify', { type: 'error', delay, message });
        this.isOpen = true;
      },
    },
  };
</script>
