<template lang="pug">
.skeleton(:class="classes" :style="styles")
  slot
</template>

<script>
  import './UiSkeleton.scss';
  import { measurable } from '@/uikit/mixins/measurable';

  export default {
    mixins: [measurable],

    props: {
      type: { type: String, default: undefined },
      darker: { type: Boolean, default: false },
    },

    computed: {
      classes() {
        return {
          'skeleton-card': this.type === 'card',
          'skeleton-asset-card': this.type === 'asset-card',
          'skeleton-text': this.type === 'text',
          'skeleton-filterbox': this.type === 'filterbox',
          'skeleton-btn': this.type === 'btn',
          'skeleton-btn-rounded': this.type === 'btn-rounded',
          'skeleton-asset-photo': this.type === 'asset-photo',
          'skeleton-rect': this.type === 'rect',
          'skeleton--darker': this.darker,
        };
      },

      styles() {
        return {
          ...this.measurableStyles,
        };
      },
    },
  };
</script>
