import axiosLib from 'axios';
import { nestedParamsSerializer } from '@/libs/api/lib/axiosProvider';
import { getConfig } from '@/libs/api/lib/config';

import { logger, style } from '@/uikit/util';

const axios = axiosLib.create({
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 30000, // 30 сек
  paramsSerializer: nestedParamsSerializer,
});

const SimpleApi = {
  get(url, params, config = {}) {
    config = getConfig(config);
    return new Promise((resolve) => {
      logger.log(style.green(`SimpleAPI: >>> ${url}`), 'GET', params);
      axios({ method: 'get', url, params, ...config })
        .then((response) => {
          logger.log(style.gray(`SimpleAPI: ${url}`), response.data);
          if (response && response.status === 200) {
            resolve({ ok: true, data: response.data });
          } else {
            resolve({ ok: false });
          }
        })
        .catch((error) => {
          logger.error('SimpleAPI error', error);
          resolve({ ok: false });
        });
    });
  },

  post(url, data, config) {
    config = getConfig(config);
    return new Promise((resolve) => {
      logger.log(style.green(`SimpleAPI: >>> ${url}`), 'POST', data);
      axios({ method: 'post', url, data, ...config })
        .then((response) => {
          if (response && response.data) {
            logger.log(style.gray(`SimpleAPI: ${url}`), response.data);
            if (response.data.status === 'ok') {
              resolve({ ok: true, data: response.data.data || {} });
            } else {
              resolve({
                ok: false,
                error: response.data?.error || '',
                errors: { ...(response.data.errors || {}) },
                info: { ...(response.data.info || {}) },
              });
            }
          } else {
            resolve({ ok: false, data: response.data || {} });
          }
        })
        .catch((error) => {
          logger.error('SimpleAPI error', error);
          resolve({ ok: false });
        });
    });
  },

  delete(url, params, config) {
    config = getConfig(config);
    return new Promise((resolve) => {
      logger.log(style.green(`SimpleAPI: >>> ${url}`), 'DELETE', params);
      axios({ method: 'delete', url, params, ...config })
        .then((response) => {
          logger.log(style.gray(`SimpleAPI: ${url}`), response.data);
          if (response && response.data && response.data.status === 'ok') {
            resolve({ ok: true, data: response.data.data || {}, info: response.data.info });
          } else {
            resolve({ ok: false });
          }
        })
        .catch((error) => {
          logger.error('SimpleAPI error', error);
          resolve({ ok: false });
        });
    });
  },
};

export { SimpleApi };
