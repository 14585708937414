<template lang="pug">
.ui-dropdown(:class="'ui-dropdown--' + justify" v-on-clickaway="close")
  slot(:api="api" :state="state")
</template>

<script>
  import { mixin as clickaway } from 'vue-clickaway';

  export default {
    name: 'ui-dropdown',
    mixins: [clickaway],

    provide() {
      return {
        state: this.state,
        api: this.api,
      };
    },

    props: {
      opened: { type: Boolean, default: false },
      justify: { type: String, default: 'left' },
    },

    data() {
      return {
        state: {
          isOpen: this.opened,
        },
      };
    },

    computed: {
      api() {
        return { toggle: this.toggle, close: this.close, open: this.open };
      },
    },

    methods: {
      toggle() {
        const open = !this.state.isOpen;
        this.state.isOpen = open;

        if (this.state.isOpen) {
          this.$emit('opened');
        }
      },

      open() {
        this.state.isOpen = true;
      },

      close() {
        this.state.isOpen = false;
      },
    },
  };
</script>

<style lang="scss">
  .ui-dropdown {
    position: relative;

    &:focus {
      outline: none;
    }
  }
</style>
