const Constants = {
  FIO_PLACEHOLDER: 'Иванов Иван Иванович',
  PHONE_PLACEHOLDER: '+7 (999) 123-45-67',
  PHONE_VMASK: '+# (###) ###-##-##',
  SMS_CODE_PLACEHOLDER: 'Код из СМС',
  SMS_CODE_VMASK: '####',
  EMAIL_PLACEHOLDER: 'mail@domain.com',
  EMAIL_PATTERN: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$',
  COUNT_PHONE_CHARACTERS: 10, //9057976669 - 10 знаков, тк можно без +7 или 8
  COUNT_SMS_CHARACTERS: 4, //1111 - для дева
  PHONE_REGEX: /^(\+7|7|\+8|8)?\(?-?\s?(\s\()?\d{3}\)?\s?-?\d{3}\s?-?\d{2}\s?-?\d{2}$/,
  EMAIL_REGEX: /^[\w.!#$%&'*+\/=?^_`{|}~-]+@[\w](?:[\w-]{0,61}[\w])?(?:\.[\w](?:[\w_-]{0,61}[\w])?)+$/,
  COUNT_PASSWORD_CHARACTERS: 6,
  CHAT_MESSAGE_MAX_LENGTH: 500,

  MONEY_FORMAT: {
    decimal: ',',
    thousands: ' ',
    prefix: '',
    suffix: ' ₽',
    precision: 0,
    masked: false,
  },

  DATE_FORMAT: {
    dayOfWeekNames: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ],
    dayOfWeekNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    monthNamesShort: [
      'янв',
      'фев',
      'мар',
      'апр',
      'май',
      'июн',
      'июл',
      'авг',
      'сен',
      'окт',
      'ноя',
      'дек',
    ],
    dateShort: 'D MMM',
    dateFull: 'DD MMM, YYYY',
    // Timezone offset, in minutes (0 - UTC, 180 - Russia, undefined - current)
    // timezone: 0
  },

  fields: {
    date: {
      maska: '##.##.####',
      placeholder: 'дд.мм.гггг',
      testRegex: /^0[1-9]|[1-2][0-9]|3[0-1]\.0[1-9]|1[1-2]\.[1-2][0-9]{3}$/,
    },

    passport: {
      maska: '## ## ######',
      placeholder: '__ __ ______',
    },

    passport_division_code: {
      maska: '###-###',
      placeholder: '___-___',
    },

    inn: {
      maska: '############',
      placeholder: '12-ти значный ИНН',
    },

    company_inn: {
      maska: '##########',
      placeholder: '10-ти значный ИНН',
    },

    snils: {
      maska: '###-###-### ##',
      placeholder: '___-___-___ __',
    },

    phone: {
      maska: '+# (###) ###-##-##',
      placeholder: '+7 (999) 123-45-67',
    },
  },

  highchartOptions: {
    lang: {
      loading: 'Загрузка...',
      months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],
      weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
      shortMonths: [
        'Янв',
        'Фев',
        'Март',
        'Апр',
        'Май',
        'Июнь',
        'Июль',
        'Авг',
        'Сент',
        'Окт',
        'Нояб',
        'Дек',
      ],
      exportButtonTitle: 'Экспорт',
      printButtonTitle: 'Печать',
      rangeSelectorFrom: 'С',
      rangeSelectorTo: 'По',
      rangeSelectorZoom: 'Период',
      downloadPNG: 'Скачать PNG',
      downloadJPEG: 'Скачать JPEG',
      downloadPDF: 'Скачать PDF',
      downloadSVG: 'Скачать SVG',
      printChart: 'Напечатать график',
    },
  },

  install: function (Vue) {
    Vue.prototype.Constants = this;
  },
};

export default Constants;
