<template lang="pug">
.category-card
  .category-card__img(:class="'category-card__img--' + kind")
  .category-card__count {{ total }}
  .category-card__title.text--2-line-truncate
    slot(name="title")
      | {{ title }}
</template>

<script>
  export default {
    props: {
      kind: { type: String, default: undefined },
      total: { type: [String, Number], default: undefined },
      title: { type: String, default: undefined },
    },
  };
</script>

<style lang="scss">
  .category-card {
    background-color: c('white', 'light');
    box-shadow: shadow('xsmall', 'light');
    position: relative;
    width: 132px;
    min-width: 132px;
    height: 128px;
    max-height: 128px;
    overflow: hidden;
    padding: 16px;
    border-radius: 12px;

    .category-card__title {
      position: absolute;

      @include text-style('caption-small', 'light');
      color: c('black', 'light');
      top: 72px;
      padding-right: 16px;
    }

    .category-card__count {
      position: absolute;

      @include text-style('caption-small', 'light');
      color: c('black70', 'light');
      width: 44px;
      left: 72px;
    }
    .category-card__img {
      position: absolute;
      height: 48px;
      width: 48px;
    }

    .category-card__img--all_categories {
      @include svg-bg-colored('categories/grid-view', 'assets');
    }

    .category-card__img--retail_realty {
      @include svg-bg-colored('categories/shop', 'assets');
    }

    .category-card__img--office_area {
      @include svg-bg-colored('categories/office', 'assets');
    }

    .category-card__img--warehouse_complex {
      @include svg-bg-colored('categories/storage', 'assets');
    }

    .category-card__img--section {
      @include svg-bg-colored('categories/land', 'assets');
    }

    .category-card__img--living_realty {
      @include svg-bg-colored('categories/house', 'assets');
    }

    .category-card__img--factory {
      @include svg-bg-colored('categories/factory', 'assets');
    }

    .category-card__img--truck {
      @include svg-bg-colored('categories/transport', 'assets');
    }

    .category-card__img--equipment {
      @include svg-bg-colored('categories/equipment', 'assets');
    }
  }
</style>
