<template lang="pug">
.ui-input-slider
  .ui-input-slider__value.d-flex(@click="editValue")
    template(v-if="!isEditing")
      slot(:value="valueModel" :isEditing="isEditing")
        span {{ valueModel }}
    input.flex-1(
      v-else
      ref="input"
      v-model="newValue"
      @blur="setNewValue"
      @change="setNewValue"
    )
  .ui-input-slider__slider
    v-slider.mt-0(
      :value="valueModel"
      :max="max"
      :min="min"
      @focus="setNewValue"
      @input="setValue"
    )
</template>

<script>
  export default {
    props: {
      value: { type: Number, default: undefined },
      min: { type: Number, default: undefined },
      max: { type: Number, default: undefined },
    },

    data() {
      return {
        valueModel: this.value,
        newValue: this.value,
        isEditing: false,
      };
    },

    computed: {
      classes() {
        return [{ 'c-red': this.isEditing }];
      },
    },

    watch: {
      value(newValue) {
        this.valueModel = newValue;
      },
    },

    methods: {
      setValue(val) {
        if (!val) return;
        this.$emit('input', val);
      },

      editValue() {
        if (this.isEditing) return;
        this.isEditing = true;
        this.$nextTick(() => {
          this.newValue = this.valueModel;
          this.$refs['input']?.focus();
        });
      },

      stopEditing() {
        this.isEditing = false;
      },

      setNewValue() {
        this.stopEditing();
        this.valueModel = Number(this.newValue);
        this.setValue(Number(this.newValue));
      },
    },
  };
</script>
