export default {
  name: 'ui-countdown-timer',

  model: {
    prop: 'time',
    event: 'tick',
  },

  props: {
    duration: { type: Number, default: 0 },
    paused: { type: Boolean, default: false },
  },

  data() {
    return {
      timeLeft: this.duration || this.time || 0, // сек
      isPaused: this.pause,
      timer: null,
    };
  },

  watch: {
    pause(newVal) {
      this.isPaused = newVal;
    },

    time(newTime) {
      this.timeLeft = newTime;
    },
  },

  mounted() {
    this.startTimer();
  },

  beforeDestroy() {
    this.stopTimer();
  },

  methods: {
    resetTimer() {
      this.timeLeft = this.duration;
    },

    togglePause() {
      this.isPaused = !this.isPaused;
    },

    pauseTimer() {
      this.isPaused = true;
    },

    unpauseTimer() {
      this.isPaused = false;
    },

    startTimer() {
      this.timer = setInterval(() => {
        if (!this.isPaused && this.timeLeft > 0) {
          --this.timeLeft;
          this.$emit('tick', this.timeLeft);

          if (this.timeLeft === 0) {
            this.$emit('timeout');
          }
        }
      }, 1000);
    },

    stopTimer() {
      clearTimeout(this.timer);
    },
  },

  render() {
    return this.$scopedSlots.default({
      paused: this.isPaused,
      reset: this.resetTimer,
      timeLeft: this.timeLeft,
      toggle: this.togglePause,
    });
  },
};
