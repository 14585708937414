export const themeable = {
  inject: {
    theme: {
      default: {
        isDark: false,
      },
    },
  },

  props: {
    light: { type: Boolean, default: undefined },
    dark: { type: Boolean, default: undefined },
  },

  computed: {
    themeClasses() {
      const className = `theme--${this.themeName}`;
      return { [className]: true };
    },

    themeName() {
      return this.isDark ? 'dark' : 'light';
    },

    isDark() {
      if (this.dark === true) {
        // explicitly dark
        return true;
      } else if (this.light === true) {
        // explicitly light
        return false;
      } else {
        // inherit from parent, or default false if there is none
        return this.theme.isDark || false;
      }
    },
  },
};
