<script>
  export default {
    name: 'search-bar',
    functional: true,

    render(h, ctx) {
      const classes = ['search-bar', ctx.data.staticClass, ctx.data.class];
      return h('div', { ...ctx.data, class: classes }, ctx.children);
    },
  };
</script>

<style lang="scss">
  .search-bar {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 48px;
    background-color: c('white', 'light');
    box-shadow: shadow('xsmall', 'light');

    .search-bar-item {
      display: flex;
      align-items: center;
      height: 48px;
      min-width: 0;
      @include text-style('caption-normal', 'light');
    }

    .search-bar-item--grow {
      flex-grow: 1;
    }

    .search-bar-item--shrink {
      flex-shrink: 1;
    }
  }
</style>
