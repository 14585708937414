<template lang="pug">
app-layout
  .page-home
    search-bar.mb-6
      search-bar-item.mx-4(grow width=200 @click="openSearchDialog")
        //- :disabled="!initialized"
        ui-icon.c-gray70(name="search" left)
        .t-button-middle.c-gray80.text--truncate(v-if="queryModel") {{ queryModel }}
        .t-button-middle.c-gray60(v-else) Поиск по каталогу

      search-bar-item.mx-4(shrink width=90 @click="openFiltersDialog")
        //- :disabled="!initialized"
        ui-icon.c-gray70(name="sliders" left)
        .t-button-middle.c-gray80 Фильтры

    // популярные категории
    section.mb-8
      .popular-categories-slider.ml-4.pb-4.pr-4(
        v-if="!fetchingCategories && popularCategories && popularCategories.length"
      )
        .popular-categories-slider__item(@click="openCatalogDialog")
          category-card(kind="all_categories" :total="totalAssetCount")
            template(#title)
              span Все<br/>категории

        router-link.popular-categories-slider__item(
          v-for="(category, i) in popularCategories"
          :key="'cat-link' + i"
          :to="$path.catalog(category.kind)"
        )
          category-card(
            :key="'cat-' + i"
            :kind="category.kind"
            :title="category.title"
            :total="category.total"
          )
      // заглушки карточек категорий
      .popular-categories-slider.ml-4.pb-4.pr-4(v-else)
        ui-skeleton.popular-categories-slider__item(
          v-for="n in 8"
          :key="`cat-skeleton-${n}`"
          type="card"
          width=128
          height=128
        )

    // активы
    section.px-4.mb-8
      .t-h1-mobile Активы
      v-tabs.mb-4(v-model="featuredTab")
        v-tab Для бизнеса
        v-tab Для личных целей

      v-tabs-items(v-model="featuredTab")
        // для бизнеса
        v-tab-item
          .items-grid(v-if="!fetchingAssets && featuredBusinessAssets")
            asset-card-mini.items-grid__item(
              v-for="asset in featuredBusinessAssets"
              :key="asset.short_id"
              new-window
              :asset="asset"
            )
          .items-grid(v-else)
            ui-skeleton.items-grid__item(
              v-for="n in 8"
              :key="n"
              type="asset-card"
              height=278
            )
        // для личных
        v-tab-item
          v-fade-transition(leave-absolute)
            .items-grid(v-if="!fetchingAssets && featuredPersonalAssets")
              asset-card-mini.items-grid__item(
                v-for="asset in featuredPersonalAssets"
                :key="asset.short_id"
                new-window
                :asset="asset"
              )
            .items-grid(v-else)
              ui-skeleton.items-grid__item(
                v-for="n in 8"
                :key="n"
                type="asset-card"
                height=278
              )

      // перейти в каталог
      ui-btn(
        large
        preset="outlined_mobile"
        :to="$path.catalog('', 'buy')"
        width="100%"
      ) Перейти в каталог

    // рекомендуемые активы
    template(v-if="currentUser && recommendedAssets && recommendedAssets.length")
      section.px-4.mb-4
        .t-h2-mobile.mr-4.mb-5 Рекомендуемые активы
        .items-grid
          //- template
          asset-card-mini.items-grid__item(
            v-for="asset in recommendedAssets"
            :key="asset.short_id"
            new-window
            :asset="asset"
          )

          //- template(v-else)
          //-   .asset-card-skeleton
          //-     ui-loader(preloader text="Загрузка")

    // витрина сервисов
    section.px-4(v-if="sortedServices && sortedServices.length")
      .t-h1-mobile.mb-5 Сервисы
      .services-grid.mb-4
        router-link.services-grid__item(
          v-for="(service, index) in sortedServices"
          :key="`router-link-service-${index}`"
          :to="$path.service(service.name)"
        )
          service-card(
            :key="service.name"
            small
            :service="service"
            height="100%"
          )

      // перейти на страницу сервисов
      ui-btn(
        large
        preset="outlined_mobile"
        :to="$path.services()"
        width="100%"
      ) Все сервисы

    filters-dialog(ref="filtersDialog" :categories-tree-object="categoriesTree")
    fulltext-search-dialog(
      ref="searchDialog"
      :categories-tree-object="categoriesTree"
      :purpose="purpose"
    )

    catalog-dialog(
      ref="catalogDialog"
      key="app-drawer-catalog-dialog"
      :home-purpose.sync="purpose"
    )
</template>

<script>
  import { UiBtn, UiIcon, UiSkeleton } from '@/uikit';
  import AppLayout from '@/layouts/AppLayout';
  import AssetCardMini from '@/components/asset/AssetCardMini.vue';
  import CategoryCard from '@/components/CategoryCard.vue';
  import SearchBar from '@/components/search/SearchBar.vue';
  import SearchBarItem from '@/components/search/SearchBarItem.vue';
  import ServiceCard from '@/components/ServiceCard.vue';
  import CatalogDialog from '@/components/CatalogDialog.vue';
  const FiltersDialog = () => import('@/components/search/FiltersDialog.vue');
  const FulltextSearchDialog = () => import('@/components/search/FulltextSearchDialog.vue');

  import { mapGetters } from 'vuex';
  import { AssetQuery } from '@/services/assets/asset-query';
  import { CategoriesTree } from '@/services/assets/categories-tree';
  import { service as AssetsAPI } from '@/services/assets';
  import { customSortServices, service as ServicesAPI } from '@/services/services';
  import { handleError } from '@/libs/handle-error';

  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageHome', 'orange');

  const POPULAR_IDS = [
    'retail_realty',
    'office_area',
    'warehouse_complex',
    'section',
    'factory',
    'living_realty',
    'truck',
    'equipment',
  ];

  export default {
    name: 'page-home',

    components: {
      AppLayout,
      AssetCardMini,
      CategoryCard,
      FiltersDialog,
      FulltextSearchDialog,
      SearchBar,
      SearchBarItem,
      ServiceCard,
      UiBtn,
      UiIcon,
      UiSkeleton,
      CatalogDialog,
    },

    data() {
      return {
        initialized: false,
        featuredTab: 0,
        fetchingAssets: false,
        fetchingCategories: false,
        categoriesTree: undefined,
        featuredBusinessAssets: undefined,
        featuredPersonalAssets: undefined,
        recommendedAssets: undefined,
        showGetAppBanner: true,
        totalAssetCount: undefined,
        services: undefined,
      };
    },

    computed: {
      queryModel: {
        set(value) {
          this.$store.dispatch('catalog/set_query', value);
        },
        get() {
          return this.$store.state.catalog.query;
        },
      },

      sortedServices() {
        return this.services ? customSortServices(this.services).splice(0, 4) : undefined;
      },

      kind: {
        set(kind) {
          this.$store.dispatch('catalog/set_kind', kind);
        },
        get() {
          return this.$store.state.catalog?.kind;
        },
      },

      kindTitle() {
        const kind = this.kind ? this.$store.getters['categories/find'](this.kind) : null;
        return kind ? kind.title : undefined;
      },

      purpose: {
        set(value) {
          this.$store.dispatch('catalog/initialize', { placementPurpose: value });
        },
        get() {
          return this.$store.state.catalog.placementPurpose;
        },
      },

      popularCategories() {
        if (this.categoriesTree) {
          return POPULAR_IDS.map((kind) => this.categoriesTree.find(kind));
        } else return [];
      },

      searchParams() {
        return this.$store.getters['catalog/search_params'];
      },

      filtersList() {
        return this.$store.getters['catalog/all_filters_list'];
      },

      ...mapGetters(['currentUser']),
    },

    mounted() {
      logger.log('Монтируем компонент главной страницы');
      this.initialize();
      this.purpose = this.$store.state['catalog'].placementPurpose || 'buy';
    },

    methods: {
      async initialize() {
        try {
          this.initialized = false;
          this.$store.dispatch('catalog/initialize', { placementPurpose: 'buy' });
          this.fetchCategories('buy');
          this.fetchAssets();
          this.getTotalAssetCount();
          this.fetchServices();
          this.initialized = true;
        } catch (error) {
          handleError(error, logger);
        }
      },

      async getTotalAssetCount() {
        try {
          this.totalAssetCount = await AssetsAPI.getAssetsTotalCount('buy');
        } catch (error) {
          handleError(error);
        }
      },

      async openCatalogDialog() {
        try {
          const kind = await this.$refs.catalogDialog.open(this.kind, this.kindTitle);
          if (kind) {
            this.kind = kind;
            this.$store.dispatch('catalog/reset_filters_values');
            this.$router.push(this.$path.catalog(kind, this.purpose));
          }
        } catch (error) {
          logger.error(error);
        }
      },

      async fetchCategories(purpose) {
        try {
          logger.log(`Тянем категории ${purpose}...`);
          this.fetchingCategories = true;
          const categories = await AssetsAPI.getCategories(purpose);
          this.categoriesTree = new CategoriesTree(categories);
          this.$store.dispatch('categories/initialize', categories);
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.fetchingCategories = false;
        }
      },

      async fetchAssets() {
        try {
          this.fetchingAssets = true;
          const { business, personal, recommended } = await AssetsAPI.getFeaturedAssets();
          this.featuredBusinessAssets = business ? business.splice(0, 8) : undefined;
          this.featuredPersonalAssets = personal ? personal.splice(0, 8) : undefined;
          this.recommendedAssets = recommended;
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.fetchingAssets = false;
        }
      },

      async fetchServices() {
        try {
          this.fetchingServices = true;
          this.services = await ServicesAPI.getServices();
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.fetchingServices = false;
        }
      },

      async initializeCatalog(searchData) {
        this.$store.dispatch('catalog/set_addresses', []);
        this.$store.dispatch('catalog/reset_filters_values');

        const assetQuery = new AssetQuery(searchData);
        const filtersData = await AssetsAPI.getFilters(assetQuery.kind, assetQuery.purpose);
        assetQuery.buildFilters(filtersData);
        assetQuery.assignFromCriterias(searchData.filters);
        logger.log('Сформированные параметры:', searchData, 'assetQuery:', assetQuery.toJSON());

        await this.$store.dispatch('catalog/initialize', {
          placementPurpose: assetQuery.purpose,
          query: assetQuery.query,
          kind: assetQuery.kind,
          page: assetQuery.page,
          addresses: assetQuery.addresses,
          filters: filtersData,
          filtersValues: assetQuery.getCriterias(),
        });
        await this.$store.dispatch('catalog/setPreinitialized', true);
      },

      async openFiltersDialog() {
        // если не готово хранилище, пока ничего не делаем
        if (!this.initialized) return;

        const assetQuery = await this.$refs.filtersDialog.open(this.searchParams, this.filtersList);
        if (assetQuery) {
          const params = assetQuery.toJSON();

          // преинициализировать хранилище
          await this.initializeCatalog(params);

          this.$router.push({
            name: 'pageCatalog',
            params: { assetKind: params.kind, assetPurpose: params.purpose },
          });
        }
      },

      async openSearchDialog() {
        // если не готово хранилище, пока ничего не делаем
        if (!this.initialized) return;

        const query = await this.$refs.searchDialog.open(this.queryModel);
        if (query !== false) {
          const assetQuery = new AssetQuery({ query });
          const params = assetQuery.toJSON();

          // преинициализировать хранилище
          this.initializeCatalog(params);

          this.$router.push({
            name: 'pageCatalog',
            params: { assetKind: this.kind, assetPurpose: this.purpose },
          });
        }
      },
    },
  };
</script>

<style lang="scss">
  .page-home {
    background-color: c('gray5', 'light') !important;
    padding-bottom: 58px;
    border-bottom: 1px solid rgba(232, 233, 237, 1);

    .popular-categories-slider {
      display: flex;
      overflow-x: scroll;
    }

    .popular-categories-slider__item {
      margin-right: 8px;
      flex: 0 0 128px;

      &:last-child {
        margin-right: 0;
      }
    }

    .services-grid {
      display: grid;
      overflow: hidden;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      grid-column-gap: 8px;
      grid-row-gap: 16px;

      .services-grid__item {
        text-decoration: none !important;
      }

      @media screen and (min-width: 720px) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    .items-grid {
      display: flex;
      flex-flow: wrap;

      .items-grid__item {
        margin-right: 8px;
        margin-bottom: 16px;
        width: calc(50% - (8px / 2));
        text-decoration: none !important;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      @media screen and (min-width: 720px) {
        .items-grid__item {
          width: calc(25% - 8px);

          &:nth-child(2n) {
            margin-right: 8px;
          }

          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
  }
</style>
