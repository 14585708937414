/**
 * Convert any date to date with MSK timezone.
 * @param {Date} source date
 * @returns {Date} converted date
 */
const convertDateToMsk = (date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours() + 3,
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

export { convertDateToMsk };
