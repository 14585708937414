<template lang="pug">
.ui-scroll-here(:id="genId")
</template>

<script>
  export default {
    name: 'ui-scroll-here',

    props: {
      autoscroll: { type: Boolean, default: false },
    },

    computed: {
      genId() {
        return Math.random().toString(36).substr(2, 9);
      },
    },

    mounted() {
      if (this.autoscroll) this.scrollTo();
    },

    methods: {
      scrollTo() {
        this.$el.scrollIntoView({ behavior: 'smooth' });
      },
    },
  };
</script>
