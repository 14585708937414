export async function initMarkers(ymaps) {
  const clusterBuild = {
    build: function () {
      this.constructor.superclass.build.call(this);
      const totalObjects = this.getData().properties.geoObjects.reduce(
        (count, o) => count + o.properties.count,
        0
      );
      const el = this.getParentElement().querySelector('#cluster-' + this.getData().id);
      if (totalObjects > 99) el.classList.add('cluster--large');
      el.innerHTML = totalObjects.toString();
    },
  };

  ymaps.layout.storage.add(
    'pda#dot',
    ymaps.templateLayoutFactory.createClass(
      `
      <div class="marker" id="marker-{{ id }}">{% if properties.count > 1 %}{{ properties.count }}{% endif %}</div>
      `,
      {
        build: function () {
          this.constructor.superclass.build.call(this);
        },
      }
    )
  );

  ymaps.layout.storage.add(
    'pda#redDot',
    ymaps.templateLayoutFactory.createClass(
      `
      <div class="marker marker--red" id="marker-{{ id }}">{% if properties.count > 1 %}{{ properties.count }}{% endif %}</div>
      `
    )
  );

  ymaps.layout.storage.add(
    'pda#blueCluster',
    ymaps.templateLayoutFactory.createClass(
      '<div class="cluster" id="cluster-{{id}}">{{number}}</div>',
      clusterBuild
    )
  );

  ymaps.layout.storage.add(
    'pda#redCluster',
    ymaps.templateLayoutFactory.createClass(
      '<div class="cluster marker--red" id="cluster-{{id}}">{{number}}</div>',
      clusterBuild
    )
  );

  ymaps.option.presetStorage.add('pda#blueDot', {
    iconLayout: 'pda#dot',
    iconShape: {
      type: 'Circle',
      coordinates: [0, 0],
      radius: 8,
    },
    colorClass: 'blue',
  });

  ymaps.option.presetStorage.add('pda#redDot', {
    iconLayout: 'pda#redDot',
    iconRenderMode: 'dom',
    iconShape: {
      type: 'Circle',
      coordinates: [0, 0],
      radius: 8,
    },
    colorClass: 'red',
  });

  ymaps.option.presetStorage.add('pda#blueCluster', {
    iconLayout: 'pda#blueCluster',
    iconRenderMode: 'dom',
    iconShape: {
      type: 'Circle',
      coordinates: [0, 0],
      radius: 14,
    },
    colorClass: 'red',
  });

  ymaps.option.presetStorage.add('pda#redCluster', {
    iconLayout: 'pda#redCluster',
    iconRenderMode: 'dom',
    iconShape: {
      type: 'Circle',
      coordinates: [0, 0],
      radius: 14,
    },
    colorClass: 'red',
  });

  ymaps.option.presetStorage.add('pda#blueClusterDots', {
    clusterDisableClickZoom: false,
    clusterIconLayout: 'pda#blueCluster',
    clusterIconShape: {
      type: 'Circle',
      coordinates: [0, 0],
      radius: 14,
    },
  });
}
