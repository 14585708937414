<template lang="pug">
.asset-card-mini
  .asset-card-mini__content-wrapper
    .asset-card-mini__favorite(v-if="canToggleFavorite")
      asset-favorite-button.px-1(
        v-model="asset.favorite"
        no-text
        overlay
        preset="overlay"
        :asset-id="asset.short_id"
        :authorized-to-toggle="asset.authorized_to_toggle_favorite"
        width=40
      )

    .asset-card-mini__photos
      component(v-bind="tagAttrs" :is="tag")
        img.asset-card-mini__photo(:src="asset.photos[0]")

    .asset-card-mini__content
      component.asset-card-mini__title.c-gray100.t-caption-normal.link.text--2-line-truncate(
        v-bind="tagAttrs"
        :is="tag"
      )
        | {{ asset.title }}

      asset-address.asset-card-mini__asset-address.t-caption-mini(v-if="asset.address_subject") {{ asset.address_subject }}

      .asset-card-mini__info
        .asset-card-mini__extension
          slot(name="extension")
            .d-flex.align-center.justify-space-between.mt-3
              asset-deal-type-badge.asset-card-mini__deal-type-badge(
                v-if="asset.sale_type"
                :type="asset.sale_type"
              )

        .asset-price-card.mt-2
          .asset-price-card__price {{ forRent ? asset.rent_price : asset.price }}

  .asset-card-mini__footer(v-if="$slots.footer")
    slot(name="footer")
</template>

<script>
  import { UiIcon } from '@/uikit';
  import AssetFavoriteButton from '@/components/asset/AssetFavoriteButton.vue';
  import AssetPhoto from '@/components/asset/AssetPhoto.vue';
  import AssetId from '@/components/asset/AssetId.vue';
  import AssetAddress from '@/components/asset/AssetAddress.vue';
  import AssetBadge from '@/components/asset/AssetBadge.vue';
  import AssetDealTypeBadge from '@/components/asset/AssetDealTypeBadge.vue';
  // import assetDefaultImage from '@/assets/default-asset-img.png';

  import { sizeable } from '@/uikit/mixins/sizeable';
  import { measurable } from '@/uikit/mixins/measurable';

  export default {
    name: 'asset-card-mini',
    components: {
      UiIcon,
      AssetFavoriteButton,
      AssetPhoto,
      AssetId,
      AssetBadge,
      AssetAddress,
      AssetDealTypeBadge,
    },
    mixins: [sizeable, measurable],
    props: {
      asset: { type: Object, required: true },
      newWindow: { type: Boolean, default: false },
    },

    computed: {
      forRent() {
        return this.asset.sale_type === 'rent';
      },

      canToggleFavorite() {
        return this.asset.can_toggle_favorite;
      },

      styles() {
        return {
          ...this.measurableStyles,
        };
      },

      mainBadges() {
        return this.asset.badges
          ? this.asset.badges.filter((badge) => ['my'].includes(badge.kind))
          : undefined;
      },

      otherBadges() {
        return this.asset.badges
          ? this.asset.badges.filter((badge) => ['new', 'business'].includes(badge.kind))
          : undefined;
      },

      assetUrl() {
        return this.$path.asset(this.asset.short_id);
      },

      tag() {
        return this.assetUrl ? 'router-link' : 'div';
      },

      tagAttrs() {
        return this.assetUrl
          ? { to: this.assetUrl, target: this.newWindow ? '_blank' : false }
          : undefined;
      },

      currentUser() {
        return this.$store.getters['currentUser'];
      },
    },
  };
</script>

<style lang="scss">
  .asset-card-mini {
    background-color: c('white', 'light');
    box-shadow: shadow('xsmall', 'light');
    border-radius: 12px;
    overflow: hidden;

    .asset-card-mini__content-wrapper {
      position: relative;
      height: 278px;
      display: flex;
      flex-direction: column;
    }

    .asset-card-mini__photos {
      width: 100%;
      border-radius: 4px;
      height: 126px;
      overflow: hidden;
      background-color: c('light', 'light');
    }

    .asset-card-mini__photo {
      object-fit: cover;
      box-sizing: border-box;
      height: 100%;
      width: 100%;

      img {
        height: auto;
      }
    }

    .asset-card-mini__info {
      margin-top: auto;
    }

    .asset-card-mini__asset-address {
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 4px;

      &::before {
        display: none;
      }
    }

    .asset-card-mini__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 16px 12px 14px;
    }

    .asset-card-mini__title {
      text-decoration: none;
      max-width: 375px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      max-height: 3em;
    }

    .asset-card-mini__extension {
      .asset-price-card {
        display: flex;
      }
    }

    .asset-card-mini__deal-type-badge {
      font-size: 11px !important;
    }

    .asset-price-card__price {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    .asset-card-mini__favorite {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 2;

      .asset-favorite-button.btn-overlay.v-btn.v-btn--has-bg.theme--light {
        border-radius: 50%;
        height: 40px !important;
        background-color: rgba(37, 40, 43, 0.24) !important;
        & .icon {
          background-color: white !important;
        }

        &.asset-favorite-button--active {
          background-color: c('primary60', 'light') !important;

          & .icon {
            background-color: white !important;
          }
        }
      }
    }
  }
</style>
