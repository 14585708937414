<template lang="pug">
v-bottom-sheet(v-model="isActive" content-class="open-go-wizard-dialog")
  .open-go-wizard-dialog__content
    ui-loader.catalog-dialog__loader(v-if="loading" big)
    template(v-else)
      .t-h2-mobile.mb-6 Вы уже начали размещать актив в категории {{ kind }}
      ui-btn.mb-2(
        large
        primary
        width="100%"
        @click="reset(false)"
      ) Продолжить заполнение
      ui-btn(
        large
        secondary
        width="100%"
        @click="reset(true)"
      ) Удалить и начать заново
</template>

<script>
  import { UiBtn, UiLoader } from '@/uikit/';
  import { service as API } from '@/services/wizard';
  import { service as authAPI } from '@/services/auth';

  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('OpenGoWizardDialog', 'blue');

  const DESKTOP_URL = new URL(process.env.VUE_APP_API_HOST.replace('api.', ''));

  export default {
    components: { UiBtn, UiLoader },

    data() {
      return {
        isActive: false,
        resolve: null,
        reject: null,
        loading: false,
        kind: '',
      };
    },

    methods: {
      open() {
        this.initialize();
      },

      async initialize() {
        try {
          this.isActive = true;
          this.loading = true;
          const data = await API.isDraftExist();
          if (data && data.short_id) {
            this.kind = data.kind_title;
          } else {
            this.reset(true);
          }
        } catch (error) {
          logger.error(error);
        }
      },

      async reset(value) {
        try {
          this.loading = true;
          const data = await API.resetStatus(value);
          const magicHash = await authAPI.magicPass();

          const path = `${process.env.VUE_APP_API_HOST}/objects/${data.short_id}/steps/step01?magicpass=${magicHash}`
          window.location.href = path;
        } catch (error) {
          console.log('error_code', error);
          this.$notify.error('Вы вышли из системы');
          logger.error(error);
        } finally {
          this.loading = false;
          this.isActive = false;
        }
      },

      cancel() {
        this.isActive = false;
      },
    },
  };
</script>

<style lang="scss">
  .open-go-wizard-dialog {
    background-color: c('white', 'light');

    &__content {
      text-align: center;
      padding: 32px 24px;
    }

    &__footer {
    }

    &__footer-actions {
      display: flex;
      align-items: center;
      padding: 0 24px 32px 24px;
    }

    &__btn-cancel {
      flex-basis: 50%;
      margin-right: 16px;
    }

    &__btn-confirm {
      flex-basis: 50%;
    }
  }
</style>
