<script>
  import './UiSidePanel.scss';

  export default {
    name: 'ui-side-panel-content',
    functional: true,

    props: {
      padless: { type: Boolean, default: false },
    },

    render(h, { props, data = {}, children }) {
      const classes = {
        'ui-side-panel__content--paddless': props.padless,
        'ui-side-panel__content': true,
      };

      return h('div', { ...data, class: classes }, children);
    },
  };
</script>
