<template lang="pug">
ui-dialog(v-model="isActive" content-class="catalog-dialog")
  template(#header)
    ui-dialog-header(
      back-btn
      close-btn
      :title="history[history.length - 1]"
      @click:back="prevLevel"
      @click:close="cancel"
    )

  .catalog-dialog__content.px-4
    v-window(v-model="currentLevel")
      v-window-item
        v-tabs.catalog-dialog__tabs(v-if="!hidePurpose" v-model="purpose")
          v-tab(href="#buy" key="tab-buy") Купить
          v-tab(href="#rent" key="tab-rent") Снять

        ui-loader.catalog-dialog__loader(v-if="loading" big)
        v-list-item.categories-list__item.pl-0(
          v-for="(category, i) in firstLevel.items"
          :key="category.class_name"
          v-if="category.total > 0 && !loading"
          active-class="active-item"
          @click="nextLevel('secondLevel', category)"
        )
          v-list-item-content
            .d-flex.align-center.justify-space-between
              .t-caption-normal.c-text.mr-1 {{ category.title }}
              .t-caption-xsmall.c-black60.ml-auto(:class="{ 'mr-7': category.leaf }") {{ category.total }}
              ui-icon.c-text.ml-1(v-if="!category.leaf" name="arrow-outline-right")

      v-window-item(v-if="secondLevel.items && secondLevel.items.length")
        v-list.categories-list(v-if="currentLevel === 1")
          v-list-item.categories-list__item(active-class="active-item" @click="confirm")
            v-list-item-content
              .d-flex.align-center.justify-space-between
                .t-caption-normal.c-text Смотреть все активы в категории
                .t-caption-xsmall.c-black60.mr-7 {{ categoryTotal }}
          v-list-item.categories-list__item(
            v-for="(category, i) in secondLevel.items"
            :key="category.class_name"
            v-if="category.total > 0"
            active-class="active-item"
            @click="nextLevel('thirdLevel', category)"
          )
            v-list-item-content
              .d-flex.align-center.justify-space-between
                .t-caption-normal.c-text.mr-1 {{ category.title }}
                .t-caption-xsmall.c-black60.ml-auto(:class="{ 'mr-7': category.leaf }") {{ category.total }}
                ui-icon.c-text.ml-1(v-if="!category.leaf" name="arrow-outline-right")

      v-window-item(v-if="thirdLevel.items && thirdLevel.items.length")
        v-list.categories-list(v-if="currentLevel === 2")
          v-list-item.categories-list__item(active-class="active-item" @click="confirm")
            v-list-item-content
              .d-flex.align-center.justify-space-between
                .t-caption-normal.c-text Смотреть все активы в категории
                .t-caption-xsmall.c-black60 {{ categoryTotal }}
          v-list-item.categories-list__item(
            v-for="(category, i) in thirdLevel.items"
            :key="category.class_name"
            v-if="category.total > 0"
            active-class="active-item"
          )
            v-list-item-content(@click="select(category)")
              .d-flex.align-center.justify-space-between
                .t-caption-normal.c-text {{ category.title }}
                .t-caption-xsmall.c-black60 {{ category.total }}
</template>

<script>
  import { UiIcon, UiBtn, UiLoader, UiDialog, UiDialogHeader } from '@/uikit';
  import AppLayout from '@/layouts/AppLayout';
  import { service as API } from '@/services/assets';

  import { handleError } from '@/libs/handle-error';

  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('CategoryDialog', 'orange');

  export default {
    name: 'catalog-dialog',
    components: { AppLayout, UiIcon, UiBtn, UiLoader, UiDialog, UiDialogHeader },

    props: {
      homePurpose: { type: String, default: 'buy' },
      hidePurpose: { type: Boolean, default: false },
      buttonText: { type: String, default: 'Готово' },
    },

    data() {
      return {
        isActive: false,
        resolve: null,
        reject: null,
        options: {},
        currentLevel: 0,
        history: ['Каталог'],
        firstLevel: { title: 'Каталог', items: [], all: '' },
        secondLevel: { title: '', items: [], all: '' },
        thirdLevel: { title: '', items: [], all: '' },
        loading: true,
        value: undefined,
        valueTitle: undefined,
        categoryTotal: undefined,
        purpose: this.homePurpose,
      };
    },

    watch: {
      purpose: {
        handler(value) {
          this.fetchCatalogNav();
          this.$emit('update:purpose', value);
        },
        immediate: true,
      },

      currentLevel(curLevel, prevLevel) {
        if (curLevel === 1 && prevLevel === 2) {
          this.thirdLevel.items = [];
          this.history.pop();
        }
        if (curLevel === 0 && prevLevel === 1) {
          this.secondLevel.items = [];
          this.history.pop();
        }
      },
    },

    methods: {
      open(value, title, options) {
        options = options || {};
        this.options = { ...this.options, ...options };

        this.value = value;
        this.valueTitle = title;

        this.fetchCatalogNav();
        this.isActive = true;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      confirm() {
        this.resolve(this.value);
        this.isActive = false;
      },

      cancel() {
        this.resolve(undefined);
        this.isActive = false;
      },

      select(category) {
        this.value = category.class_name;
        this.valueTitle = category.title;
        this.confirm();
      },

      async fetchCatalogNav() {
        try {
          this.loading = true;
          this.firstLevel.items = await API.getCategories(this.purpose);
        } catch (error) {
          handleError(error);
          logger.log(error);
        } finally {
          this.loading = false;
        }
      },

      prevLevel() {
        if (this.currentLevel === 0) {
          this.cancel();
        } else {
          this.currentLevel -= 1;
        }
      },

      nextLevel(level, category) {
        if (category.leaf) {
          this.select(category);
        }

        // для выбора "Вся категория" след уровня
        this.value = category.class_name;
        this.categoryTotal = category.total;

        this[level].category = category;
        this[level].items = category.subkinds;
        this[level].title = category.title;
        this.history.push(category.title);

        this.$nextTick(() => {
          this.currentLevel += 1;
        });
      },
    },
  };
</script>

<style lang="scss">
  .catalog-dialog {
    background: c('white', 'light');

    &__loader {
      display: flex;
      align-self: center;
      justify-self: center;
    }

    &__tabs {
      color: c('primary', 'light');
    }

    .catalog-dialog__toolbar {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 48px;
      padding-left: 12px;
      padding-right: 12px;
      background-color: c('white', 'light') !important;
      z-index: 10;
      box-shadow: shadow('xsmall', 'light') !important;
    }

    .catalog-dialog__content {
      padding: 48px 0 126px 0;
    }

    .catalog-dialog__footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 12px 16px;
      background-color: c('white', 'light');
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.06);
    }

    .catalog-dialog__selection {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .categories-list {
      padding: 0;

      .active-item {
        color: transparent;
      }
    }

    .categories-list__item {
      border-bottom: 1px solid c('black10', 'light');
      padding-right: 0;

      .v-list-item__action {
        margin-left: 4px;
      }

      &:first-child {
        padding-left: 0;
      }

      .content__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: c('black80', 'light');

        .title__text {
          max-width: 80%;
          white-space: break-spaces;
        }

        .title__total {
          color: c('black60', 'light');
        }
      }
    }
  }
</style>
